<ion-grid class="wrapper">
  <!-- Close button -->
  <ion-icon name="close" class="close-icon" (click)="onCancel()"></ion-icon>

  <!-- Progress bar with description -->
  <ion-row class="title">{{
    translateService.instant("ADMIN_SETTINGS.OPENING_TIMES")
  }}</ion-row>

  <ion-row class="ion-justify-content-start ion-nowrap row" *ngFor="let day of days">
    <ion-col>{{translateService.instant("DAYS." + day.toUpperCase())}}:</ion-col>
    <ion-col><ion-toggle class="openclosetoggle" [checked]="openinghours.get(day).isOpen()" (ionChange)="openCloseChange(day)" color="secondary"></ion-toggle></ion-col>
    <ion-col class="visible">{{openinghours.get(day).isOpen() ? translateService.instant("MENU.OPEN") : translateService.instant("MENU.CLOSED")}}</ion-col>
    <ion-col [class]="(openinghours.get(day).isOpen() ? 'visible' : 'invisible')"><ion-label>{{translateService.instant("GENERAL.FROM")}}</ion-label></ion-col>
    <ion-col [class]="(openinghours.get(day).isOpen() ? 'visible' : 'invisible')">
      <ion-input class="timefield" type="time" step="900" pattern="[0-9]{2}:[0-9]{2}"
                 [value]="openinghours.get(day).times[0]?.from?.toString()"
                 (ionChange)="fromChange($any($event), day, 0)"></ion-input>
    </ion-col>
    <ion-col [class]="(openinghours.get(day).isOpen() ? 'visible' : 'invisible')"><ion-label>{{translateService.instant("GENERAL.TILL")}}</ion-label></ion-col>
    <ion-col [class]="(openinghours.get(day).isOpen() ? 'visible' : 'invisible')">
      <ion-input class="timefield" type="time" step="900" pattern="[0-9]{2}:[0-9]{2}"
                 [value]="openinghours.get(day).times[0]?.to?.toString()"
                 (ionChange)="toChange($any($event), day, 0)"></ion-input>
    </ion-col>
    <ion-col [class]="((openinghours.get(day).isOpen() && openinghours.get(day).times.length >= 1) ? 'visible' : 'invisible')"><ion-label>{{translateService.instant("GENERAL.AND")}} {{translateService.instant("GENERAL.FROM")}}</ion-label></ion-col>
    <ion-col [class]="((openinghours.get(day).isOpen() && openinghours.get(day).times.length >= 1) ? 'visible' : 'invisible')">
      <ion-input class="timefield" type="time" step="900" pattern="[0-9]{2}:[0-9]{2}"
                 [value]="openinghours.get(day).times[1]?.from?.toString()"
                 (ionChange)="fromChange($any($event), day, 1)"></ion-input>
    </ion-col>
    <ion-col [class]="((openinghours.get(day).isOpen() && openinghours.get(day).times.length >= 1) ? 'visible' : 'invisible')"><ion-label>{{translateService.instant("GENERAL.TILL")}}</ion-label></ion-col>
    <ion-col [class]="((openinghours.get(day).isOpen() && openinghours.get(day).times.length >= 1) ? 'visible' : 'invisible')">
      <ion-input class="timefield" type="time" step="900" pattern="[0-9]{2}:[0-9]{2}"
                 [value]="openinghours.get(day).times[1]?.to?.toString()"
                 (ionChange)="toChange($any($event), day, 1)"></ion-input>
    </ion-col>
  </ion-row>
  <div>
    <!-- Cancel -->
    <ion-button
      color="secondary"
      fill="outline"
      class="ion-float-right save-button"
      (click)="onCancel()"
      >{{translateService.instant("GENERAL.CANCEL")}}
    </ion-button>
    <!-- Save -->
    <ion-button
      *ngIf="formVenue.isDirty()"
      color="secondary"
      fill="solid"
      class="ion-float-right save-button"
      (click)="onSave()"
      >{{translateService.instant("EDIT_BEER.SAVE")}}
    </ion-button>
  </div>
</ion-grid>