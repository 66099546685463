<!-- tag summary -->
<ion-row [class]="'summary-' + screenType" *ngIf="!tagForm">
  <div [class]="'summary-tags-' + screenType">
    {{ getTagSummary() }}
  </div>
</ion-row>

<!-- searchbar -->
<ion-row class="input-wrapper" *ngIf="!tagForm">
  <ion-icon
    src="assets/svgs/general/icon_search.svg"
    class="search-icon"
  ></ion-icon>
  <ion-input
    (ionChange)="onSearchInput($any($event))"
    class="search-input ion-no-padding"
    [clearInput]="true"
  ></ion-input>
</ion-row>

<!-- list of tags -->
<div [ngClass]="'tags-wrapper-' + tagSize" *ngIf="!tagForm">
  <!-- add button tag style -->
  <span
    [ngClass]="'tag pointer addtag' + (' ' + tagSize)"
    *ngIf="showAddTag"
    (click)="onShowTagForm()"
  >
    <ion-icon name="add" class="addicon"></ion-icon>
  </span>

  <!-- the actual tags -->
  <span *ngFor="let tag of searchedTags" (click)="selectTag(tag)">
    <span
      [ngClass]="
        'tag ' +
        (tag.tag.getType() + 'tag') +
        (tag.selected && !editMode ? '-selected' : '') +
        (' ' + tagSize) +
        (editMode ? '' : ' ' + tag.tag.getType() + 'tag-selectable')
      "
    >
      <!-- in edit mode all tags are shown, though language feedback is given if the tag is not in the browserlang -->
      {{
        tag.tag.getAnyLabel(currentLang)
      }}
      <ion-icon
        name="pencil"
        class="tag-icon margin-left"
        (click)="onShowTagForm(tag.tag)"
        *ngIf="editMode"
      ></ion-icon>
      <ion-icon
        name="trash"
        class="tag-icon"
        (click)="onRemoveTag(tag.tag)"
        *ngIf="editMode"
      ></ion-icon>
    </span>
  </span>
</div>

<!-- add tag form -->
<div *ngIf="tagForm" class="add-form-wrapper">
  <!-- title -->
  <ion-row class="add-tag-title">{{
    translateService.instant(formTranslationKey)
  }}</ion-row>

  <!-- tag type dropdown -->
  <ion-row class="add-tag-type-wrapper">
    <span class="type-title"
      >{{ translateService.instant("EDIT_BEER.TYPE") }}:</span
    >
    <span class="add-tag-selector">
      <app-popover-select
        [options]="tagTypeOptions"
        [selected]="selectedOption"
        (selectionChange)="onTagTypeSelect($event)"
      ></app-popover-select>
    </span>
  </ion-row>

  <ion-row>
    <app-language-text
      type="tagName"
      [maxCharacters]="30"
      class="add-tag-name-input"
      [textMap]="formTag!.getLabelMap()"
    ></app-language-text>
  </ion-row>

  <!-- save and cancel buttons -->
  <ion-row class="save-row">
    <!-- Cancel -->
    <ion-button
      color="secondary"
      fill="outline"
      class="ion-float-right"
      (click)="onCancel()"
      >{{ translateService.instant("GENERAL.CANCEL") }}</ion-button
    >

    <!-- Save -->
    <ion-button
      [disabled]="formTag!.getLabelMap().size === 0"
      color="secondary"
      class="ion-float-right"
      (click)="onSave()"
      >{{ translateService.instant("EDIT_BEER.SAVE") }}</ion-button
    >
  </ion-row>
</div>
