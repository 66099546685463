import { ModelForm } from './../../models/persistency/model-form';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { Validators, FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../services/logger/logger.service';
import { RefdataService } from '../../services/refdata/refdata.service';
import { ContactType } from '../../models/contact-type';
import internationalization from '../../models/defaults/internationalization.json';
import { TextFieldTypes } from '@ionic/core';

interface InputField {
  key: string;
  maxCharacters: number;
  setter: (value: any) => void;
  getter: () => any;
  icon: string;
  prefix?: string;
  type?: TextFieldTypes | 'dropdown';
  formControlName: string;
  validator: ValidatorFn;
  required: boolean;
}

@Component({
  selector: 'app-admin-settings-general',
  templateUrl: './admin-settings-general.component.html',
  styleUrls: ['./admin-settings-general.component.scss'],
})
export class AdminSettingsGeneralComponent implements OnInit {

  @Input()
  public venue!: Venue;

  @Output()
  public close = new EventEmitter<void>();

  private venueForm!: ModelForm<Venue>;
  public formVenue!: Venue;

  public generalInputFields: InputField[] = [
    {
      key: 'name',
      maxCharacters: 30,
      setter: (value) => this.formVenue.setName(value),
      getter: () => this.formVenue.getName(),
      icon: 'pricetag',
      formControlName: 'name',
      validator: Validators.compose([ Validators.required, Validators.minLength(2), Validators.maxLength(40)])!,
      required: true
    },
    {
      key: 'website',
      maxCharacters: 100,
      setter: (value) => this.formVenue.setContact(ContactType.Website, value),
      getter: () => this.formVenue.getContact(ContactType.Website),
      icon: ContactType.Website.getIcon(),
      formControlName: 'website',
      validator: Validators.compose([ Validators.maxLength(100) ])!,
      required: false
    },
    {
      key: 'urlName',
      maxCharacters: 30,
      setter: (value) => this.formVenue.setUrlPath(value),
      getter: () => this.formVenue.getUrlPath(),
      icon: 'link',
      prefix: (environment.env === 'prod' ? '' : environment.env + '-') + 'advisor.beerhive.com/',
      formControlName: 'urlName',
      validator: Validators.compose([ Validators.required, Validators.minLength(2), Validators.maxLength(40)])!,
      required: true
    },
    {
      key: 'street',
      maxCharacters: 100,
      setter: (value) => this.formVenue.getAddress().setStreet(value),
      getter: () => this.formVenue.getAddress().getStreet(),
      icon: 'pin',
      formControlName: 'street',
      validator: Validators.compose([ Validators.required, Validators.minLength(2), Validators.maxLength(100)])!,
      required: true
    },
    {
      key: 'city',
      maxCharacters: 100,
      setter: (value) => this.formVenue.getAddress().setCity(value),
      getter: () => this.formVenue.getAddress().getCity(),
      icon: 'pin',
      formControlName: 'city',
      validator: Validators.compose([ Validators.required, Validators.minLength(2), Validators.maxLength(80)])!,
      required: true
    },
    {
      key: 'country',
      maxCharacters: 100,
      setter: (value) => this.formVenue.getAddress().setCountry(value),
      getter: () => this.formVenue.getAddress().getCountry(),
      icon: 'flag',
      type: 'dropdown',
      formControlName: 'country',
      validator: Validators.compose([ Validators.maxLength(100) ])!,
      required: false
    },
    {
      key: 'telephone',
      maxCharacters: 15,
      setter: (value) => this.formVenue.setContact(ContactType.Telephone, value),
      getter: () => this.formVenue.getContact(ContactType.Telephone),
      icon: ContactType.Telephone.getIcon(),
      type: 'tel',
      formControlName: 'telephone',
      validator: Validators.compose([ Validators.maxLength(15)])!,
      required: false
    },
    {
      key: 'mail',
      maxCharacters: 100,
      setter: (value) => this.formVenue.setContact(ContactType.Mail, value),
      getter: () => this.formVenue.getContact(ContactType.Mail),
      icon: ContactType.Mail.getIcon(),
      type: 'email',
      formControlName: 'mail',
      validator: Validators.compose([ Validators.maxLength(100), Validators.email])!,
      required: false
    },
    {
      key: 'facebook',
      maxCharacters: 100,
      setter: (value) => this.formVenue.setContact(ContactType.Facebook, value),
      getter: () => this.formVenue.getContact(ContactType.Facebook),
      icon: ContactType.Facebook.getIcon(),
      type: 'url',
      formControlName: 'facebook',
      validator: Validators.compose([ Validators.maxLength(100)])!,
      required: false
    },
    {
      key: 'twitter',
      maxCharacters: 100,
      setter: (value) => this.formVenue.setContact(ContactType.Twitter, value),
      getter: () => this.formVenue.getContact(ContactType.Twitter),
      icon: ContactType.Twitter.getIcon(),
      type: 'url',
      formControlName: 'twitter',
      validator: Validators.compose([ Validators.maxLength(100)])!,
      required: false
    },
    {
      key: 'instagram',
      maxCharacters: 100,
      setter: (value) => this.formVenue.setContact(ContactType.Instagram, value),
      getter: () => this.formVenue.getContact(ContactType.Instagram),
      icon: ContactType.Instagram.getIcon(),
      type: 'url',
      formControlName: 'instagram',
      validator: Validators.compose([ Validators.maxLength(100)])!,
      required: false
    }
  ];
  public generalSettingsDataFormGroup: FormGroup;

  public countryCodeOptions: {selectable: true, translationKey: string, object: string}[];
  public initialCountryCodeOption: string | null = null;

  public constructor(
    public translateService: TranslateService,
    private logger: LoggerService,
    private alertController: AlertController
  ) {
    this.logger.info('admin-settings-general.component', 'constructor called');
    // init formgroup and controls with validators
    this.generalSettingsDataFormGroup = new FormGroup({});

    for (const inputField of this.generalInputFields) {
      this.logger.info('admin-settings.page', 'adding control with name ' + inputField.formControlName);
      this.generalSettingsDataFormGroup.addControl(inputField.formControlName, new FormControl('', inputField.validator));
    }

    this.countryCodeOptions = internationalization.countryCodes.map((code) => ({
      selectable: true,
      translationKey: 'COUNTRIES.' + code.toUpperCase(),
      object: code
    }));
    }

  public ngOnInit(): void {
    this.venueForm = this.venue.createForm();
    this.formVenue = this.venueForm.formInstance;
    this.generalInputFields.forEach((field) => this.setFormValue(field.formControlName, field.getter()));
    this.initialCountryCodeOption = this.formVenue.getAddress().getCountry();

    this.logger.info('admin-settings-general.component', 'ngOnInit called');
  }

  public handleInput(field: InputField, value: any): void {
    this.setFormValue(field.formControlName, value);
    if (this.generalSettingsDataFormGroup.get(field.formControlName)?.valid) {
      field.setter(value);
    }
  }

  public onSave(): void {
    this.logger.info('admin-settings-general.component', 'onSave called');
    this.venueForm.applyForm();
    this.venue.save()
      .then(() => {
        this.close.emit();
      })
      .catch((validationErrors: any[]) => {
        validationErrors.forEach((validationError) => {
          const field = this.generalInputFields
            .find((inputField) => inputField.formControlName === validationError.formControlName);
          if (field) {
            this.generalSettingsDataFormGroup.get(field.formControlName)?.setErrors(validationError.errors, { emitEvent: true });
          }
        });
      });
  }
  public onInput(field: InputField, event: CustomEvent<any>): void {
    const value = event.detail.value;

    // handle input by type if necessary
    this.handleInput(field, value);
  }

  // Helper function to obtain easily error keys on page
  public getErrorTranslationKeys(formControlName: string) {
    const result = [];
    const control = this.generalSettingsDataFormGroup.get(formControlName);
    if (control && !control.valid) {
      const errors = control.errors;
      for (const key of Object.keys(errors || {})) {
        const translationKey = 'ADMIN_SETTINGS_ERRORS.' + formControlName + '.' + key;
        result.push(translationKey);
      }
    }
    return result;
  }

  public onCancel(): void {
    if (this.formVenue.isDirty()) {
      this.presentAlertConfirm();
    } else {
      this.closeComponent();
    }
  }
  public closeComponent(): void {
    this.close.emit();
  }

  private setFormValue(key: string, value: any): void {
    this.generalSettingsDataFormGroup.get(key)?.setValue(value);
  }

  private async presentAlertConfirm(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translateService.instant('PENDING_CHANGES_POPUP.TITLE'),
      message: this.translateService.instant('PENDING_CHANGES_POPUP.MESSAGE'),
      buttons: [
        {
          text: this.translateService.instant('PENDING_CHANGES_POPUP.RETURN'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this.translateService.instant('PENDING_CHANGES_POPUP.CLOSE_ANYWAY'),
          handler: () => {
            this.closeComponent();
          }
        }
      ]
    });

    await alert.present();
  }
}
