export const environment = {
  production: false,
  env: 'test',
  baseUrl: 'https://test-advisor.beerhive.com/',
  backendBaseUrl: 'https://test-secure.beerhive.com/',
  apiBaseUrl: 'https://test-secure.beerhive.com/api/',
  awsBaseUrl:  'https://s3-eu-west-1.amazonaws.com/beerhive-test/venuedata/',
  awsBucket: 'beerhive-test',
  logoBaseUrl: 'http://s3-eu-west-1.amazonaws.com/beerhive-test/beerhiveapp/',
  beerhiveSupportMail: 'koen@kvl.be',
  baseLoginUri: 'https://test-secure.beerhive.com/login?channel=web&response_type=code&client_id=3n5tipsbtgbf86gluhjn017osn&redirect_uri=%redirect_uri%',
  lambdaApiBaseUrl: 'https://m0ez73anri.execute-api.eu-west-1.amazonaws.com/test/api/app/',
  userCookieName: 'bhud-test',
  defaultLanguageCookieName: 'bhdl-test'
};
