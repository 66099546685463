import { LocalPersisterService } from './../../services/local-persister/local-persister.service';
import { Injectable } from '@angular/core';
import { BeersAndBreweriesFacadeService } from '../beers-and-breweries-facade/beers-and-breweries-facade.service';
import { BreweryTable } from '../../models/persistency/persistent-models/brewery-table';

/**
 * A {@link ModelTable} of {@link Brewery | breweries}.
 */
@Injectable({
  providedIn: 'root'
})
export class BreweryService extends BreweryTable {
  public constructor(private facade: BeersAndBreweriesFacadeService,
    private localPersister: LocalPersisterService) {
    super();
  }

  protected async getRawData(): Promise<any[]> {
    let lastLoadedDate = await this.localPersister.get('brewery-data-last-loaded');
    return this.localPersister.get('brewery-data').then((data) => {
      if (data && lastLoadedDate && (Date.now() - lastLoadedDate < 3600000)) {
        console.log('Using brewery data from local storage');
        return data;
      }
      return this.facade.getRawBreweryData().then((newData) => {
        this.localPersister.set('brewery-data', newData);
        this.localPersister.set('brewery-data-last-loaded', Date.now());
        return newData;
      });
    });
  }
}
