<!-- spider + aromas -->
<ion-row [ngClass]="'analysis-content-' + screenType">
  <img [src]="'assets/beerglasses/' + venueBeer.getColor() + '-beerglass.jpg'" [class]="'analysis-glass-' + screenType">
  <app-binaries-hive
    [id]="'analysis-aroma-' + screenType"
    [venueBeer]="venueBeer"
    [aromas]="aromas!"
    [name]="'analysis'"
    *ngIf="analysis"
  ></app-binaries-hive>
  <app-hive
    [id]="'analysis-hive-' + screenType"
    [color]="analysis!.color"
    [hive]="analysis!.getBaseAromas()"
    *ngIf="analysis"
  ></app-hive>
  <ion-row class="no-analysis" *ngIf="!analysis">
    {{ translateService.instant("CARD.NO_ANALYSIS_AVAILABLE") }}
  </ion-row>
</ion-row>
