<ion-grid class="wrapper">
  <!-- Close button -->
  <ion-icon name="close" class="close-icon" (click)="onCancel()"></ion-icon>

  <!-- Progress bar with description -->
  <ion-row class="title">{{
    translateService.instant("ADMIN_SETTINGS.ABOUT_US")
  }}</ion-row>

<div>
    <!-- About us section -->
    <ion-row>
      <app-language-text
        *ngIf="formVenue"
        class="full-width"
        type="aboutUs"
        [maxCharacters]="300"
        [textMap]="formVenue.getAbouts()"
        [nrOfRows]="5"
        [nrOfCols]="65"
      ></app-language-text>
    </ion-row>
</div>
<div>
  <!-- Cancel -->
  <ion-button
    color="secondary"
    fill="outline"
    class="ion-float-right save-button"
    (click)="onCancel()"
    >{{translateService.instant("GENERAL.CANCEL")}}
  </ion-button>
  <!-- Save -->
  <ion-button
    *ngIf="formVenue.isDirty()"
    color="secondary"
    fill="solid"
    class="ion-float-right save-button"
    (click)="onSave()"
    >{{translateService.instant("EDIT_BEER.SAVE")}}
  </ion-button>
</div>

</ion-grid>