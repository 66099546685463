import { Router } from '@angular/router';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { Injectable } from '@angular/core';
import 'autotrack';
import 'autotrack/lib/plugins/url-change-tracker';
import { Selector } from '../../models/selector';

declare let ga: any;

/**
 * A service responsible for tracking custom events with Google Analytics.
 */
@Injectable({
  providedIn: 'root'
})
export class AnalyticsTrackerService {
  private static viewId = 'UA-143461633-1';
  public currentSearchQuery = '';
  private tracker: any;
  public constructor(private router: Router) {
    ga = (window as any)['ga'] ||
        function() {
          (ga.q = ga.q || []).push(arguments);
        };
    ga.l = +new Date();
    ga('create', AnalyticsTrackerService.viewId, 'auto');
    ga((tracker: any) => { this.tracker = tracker; });
  }

  /**
   * Start tracking page views of the given venue.
   */
  public startTrack(venue: Venue): void {
    const that = this;
    ga('require', 'urlChangeTracker', {
      // This function is called every time the url changes.
      shouldTrackUrlChange: function(newPath: string, oldPath: string): boolean {
        // We track search queries when the query length is maximal. When the user navigates, the query won't get longer,
        // so the length is maximal.
        if (that.currentSearchQuery) {
          that.tracker.send('event', 'search-input', 'search', that.currentSearchQuery);
          that.currentSearchQuery = '';
        }
        that.tracker.set('dimension1', venue.getId());
        that.tracker.set('dimension2', that.getBeerId());
        return !!(newPath && oldPath);
      }
    });
    ga('set', 'dimension1', venue.getId());
    ga('set', 'dimension2', this.getBeerId());
    ga('send', 'pageview');
  }

  private getBeerId(): number | null {
    const split = this.router.url.split('/detail/');
    if (split.length === 2) {
      const id = parseInt(split[1], 10);
      if (!isNaN(id)) {
        return id;
      }
    }
    return null;
  }

  /**
   * Track the current search query.
   */
  public trackSearch(): void {
    ga('send', 'event', 'search-input', 'search', this.currentSearchQuery);
  }

  /**
   * Track a characteristics query.
   */
  public trackCharacteristicSearch(aroma: Selector[], color: Selector[], other: Selector[]): void {
    // sort the filters such that every combination of filters is represented in a unique way as a string.
    const filterString = [aroma, color, other]
      .map((selectors) => selectors.map((selector) => selector.getLabelKey()).sort().join('+'))
      .join('|');
    ga('send', 'event', 'search-characteristic', 'search', filterString);
  }

  /**
   * Set the current search query.
   */
  public setCurrentQuery(query: string): void {
    this.currentSearchQuery = query;
  }
}
