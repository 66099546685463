import { Subscription } from 'rxjs';
import { ModelForm } from './model-form';
import { PersistentModel } from './persistent-model';
import { SubscribableSubject } from '../subscribable';

interface TableIndexInfo<Model, Target> {
    get: (instance: Model) => Target | null;
    last: Map<Model, Target | null>;
    map: Map<Target, Model>;
}


/**
 * A class representing a table for a specific persistent model `T` which is responsible for the following.
 * 1. Serializing and deserializing model instances. (e.g. converting from and to JSON)
 * 2. Communicating with the back-end for saving, loading and deleting model instances.
 * 3. Allowing to look up a model instance by id (`get`) or by property (`find` or `findAll`). A set of all
 * instances is available with `getInstances()` and watching for changes in this set is possible by subscribing to the table.
 * 4. Creating {@link ModelForm}s from model instances.
 *
 * @Suggestion Decouple the communication with the back-end by handling this responsibility in another class.
 *
 * @typeParam T  The type of persistent models that this table belongs to.
 */
export abstract class ModelTable<T extends PersistentModel> extends SubscribableSubject<void> {
    private instances: Map<string, T>;
    private instanceSubscriptions: Map<T, Subscription>;

    private isLoaded = false;
    private rawData: any[] | null = null;

    private indices: TableIndexInfo<PersistentModel, PersistentModel>[] = [];

    /**
     * Create a new table with given dependencies on other tables.
     *
     * @param dependencies  The tables this table depends on. Table A depends on table B if A
     * can only load instances after B is loaded. For example, when instances of table A have a {@link Relation} with
     * instances from table B, then table B must first be loaded in order to create the relation when loading
     * table A.
     */
    public constructor(private dependencies: ModelTable<any>[] = []) {
        super();
        this.instances = new Map<string, T>();
        this.instanceSubscriptions = new Map();
    }

    /**
     * Load the given tables by first loading their dependencies. Raw data is fetched simultaneously.
     * Tables that were already loaded are ignored.
     *
     * @param tables  The tables that should load. All (recursive) dependencies will also be loaded.
     * @returns A promise that resolves after all given tables are loaded.
     */
    public static async loadTables(...tables: ModelTable<any>[]): Promise<void> {
        const allTables = this.findAllDependencies(new Set<ModelTable<any>>(tables));

        // Fetch all raw data.
        const fetchingStatusMap = new Map<ModelTable<any>, Promise<void>>();
        allTables.forEach((table) => {
            if (!table.isLoaded) {
                fetchingStatusMap.set(table, table.fetchRawData());
            } else {
                fetchingStatusMap.set(table, Promise.resolve());
            }
        });
        const loadingStatusMap = new Map<ModelTable<any>, Promise<void>>();

        // Load the given tables + the tables they depend on.
        await Promise.all(
            Array.from(allTables)
                .filter((table_1) => !table_1.isLoaded)
                .map((table_2) => ModelTable.loadTable(table_2, fetchingStatusMap, loadingStatusMap)));
    }
    private static async loadTable(
        table: ModelTable<any>,
        fetchingStatusMap: Map<ModelTable<any>, Promise<void>>,
        loadingStatusMap: Map<ModelTable<any>, Promise<void>>
    ): Promise<void> {
        let loadPromise = loadingStatusMap.get(table);
        if (!loadPromise) {
            // First load dependencies, then load itself.
            loadPromise =
                fetchingStatusMap.get(table)!.then(() =>
                    Promise.all(table.dependencies
                                     .map((dependency) => ModelTable.loadTable(dependency, fetchingStatusMap, loadingStatusMap)))
                           .then(() => table.loadRawData()));
            loadingStatusMap.set(table, loadPromise);
        }
        await loadPromise;
    }
    private static findAllDependencies(tables: Set<ModelTable<any>>): Set<ModelTable<any>> {
        const addDependencies = (table: ModelTable<any>) => table.dependencies.forEach((dependency) => {
            if (!tables.has(dependency)) {
                tables.add(dependency);
                addDependencies(dependency);
            }
        });
        tables.forEach(addDependencies);
        return tables;
    }

    /**
     * Add a dependency to this table. This is useful if not all dependencies are known at constructor time.
     *
     * @param table  The table this table depends on. Table A depends on table B if A
     * can only load instances after B is loaded. For example, when instances of table A have a {@link Relation} with
     * instances from table B, then table B must first be loaded in order to create the relation when loading
     * table A.
     */
    protected addDependency(table: ModelTable<any>): void {
        this.dependencies.push(table);
    }

    /**
     * Create an efficient way of looking up an instance in this table that corresponds to a one-to-one relation.
     *
     * @param get  A function that maps a given instance to its corresponding target model.
     * @typeParam Target  The type of the target model.
     * @returns A function that efficiently looks up the corresponding instance in this table.
     */
    protected createIndex<Target extends PersistentModel>(get: (instance: T) => Target | null): (target: Target) => T | null {
        const index: TableIndexInfo<T, Target> = {
            get: get,
            last: new Map<T, Target | null>(),
            map: new Map<Target, T>(),
        };
        this.indices.push(index as unknown as TableIndexInfo<PersistentModel, Target>);
        return (target) => {
            return index.map.get(target) || null;
        };
    }

    /**
     * Get the raw data from the back-end.
     *
     * @returns A promise containing a list of the raw instance data from the back-end.
     */
    protected abstract getRawData(): Promise<any[]>;
    /**
     * Get the raw data and store it to be used by {@link loadRawData}.
     *
     * @returns A promise that resolves after the data is fetched.
     */
    protected async fetchRawData(): Promise<void> {
        this.rawData = await this.getRawData();
    }
    /**
     * Load the fetched raw data. If the data is already loaded, it will do nothing.
     *
     * @throws Must first fetch raw data before loading.
     */
    protected loadRawData(): void {
        if (!this.isLoaded) {
            if (this.rawData === null) {
                throw new Error('Must first fetch raw data before loading');
            }

            console.log('Loading table for ' + this.getModelName());
            console.log(this.rawData);
            let errorsWhileParsing = 0;
            this.rawData.map((instanceData) => {
                try {
                    return this.readInstance(instanceData);
                } catch (ex) {
                    errorsWhileParsing++;
                    if (errorsWhileParsing === 1) {
                        console.warn(`Got error while reading raw data of ${this.getModelName()} instance. Ignoring.`);
                        console.warn(instanceData);
                        console.error(ex);
                    } else if (errorsWhileParsing === 2) {
                        console.warn('Got more errors...');
                    }
                    return null;
                }
            }).forEach(instance => {
                if (instance) {
                    const id = instance.getId();
                    if (id === null) {
                        console.warn(`Got ${this.getModelName()} instance with null id. Ignoring.`, instance);
                    } else if (!this.has(id)) {
                        this._add(instance);
                    } else {
                        console.warn(`Got ${this.getModelName()} instance with existing id. Ignoring.`, instance);
                    }
                }
            });
            if (errorsWhileParsing >= 2) {
                console.warn('Got ' + errorsWhileParsing + ' errors.');
            }
            this.next();

            this.isLoaded = true;
            console.log(this);
        }
    }
    /**
     * Reset this table. The set of instances is cleared and the table is ready to be loaded again.
     */
    public reset(): void {
        this._reset();
        this.next();
    }
    private _reset(): void {
        this.instances.clear();
        this.isLoaded = false;
        this.indices.forEach((index) => index.map.clear());
        this.instanceSubscriptions.clear();
    }
    /**
     * Add an instance to this table.
     *
     * @param instance  The instance to add.
     * @throws Cannot add an instance with a null id.
     * @throws Cannot add an instance with an id that is already present in this table.
     */
    public add(instance: T) {
        this._add(instance);
        this.next();
    }
    private _add(instance: T) {
        const id = instance.getId();
        if (id === null) {
            throw new Error(`Cannot add an ${this.getModelName()} instance with a null id.`);
        }
        if (this.instances.has(id)) {
            throw new Error(`${this.getModelName()} instance with same id already present.`);
        }
        this.instances.set(id, instance);

        this.indices.forEach((index) => {
            const target = index.get(instance);
            index.last.set(instance, target);
            if (target !== null) {
                if (index.map.has(target)) {
                    throw new Error('Table indexing error: an index can only be used for one-to-one relations.');
                }
                index.map.set(target, instance);
            }
        });
        this.instanceSubscriptions.set(instance, instance.subscribe(() => {
            this.indices.forEach((index) => {
                const target = index.get(instance);
                const last = index.last.get(instance);
                if (last) {
                    index.map.delete(last);
                }
                if (target !== null) {
                    if (index.map.has(target)) {
                        throw new Error('Table indexing error: an index can only be used for one-to-one relations.');
                    }
                    index.map.set(target, instance);
                }
            });
        }));
    }
    /**
     * Remove an instance from this table.
     *
     * @param instance  The instance to remove.
     * @throws Cannot remove an instance with a null id.
     * @throws No instance with the given id present.
     */
    public remove(instance: T) {
        this._remove(instance);
        this.next();
    }
    private _remove(instance: T) {
        const id = instance.getId();
        if (id === null) {
            throw new Error(`Cannot remove an ${this.getModelName()} instance with a null id.`);
        }
        if (!this.instances.has(id)) {
            throw new Error(`No ${this.getModelName()} instance with given id ('${id}') present.`);
        }
        this.instances.delete(id);

        this.indices.forEach((index) => {
            const target = index.get(instance);
            if (target !== null) {
                index.map.delete(target);
            }
        });
        this.instanceSubscriptions.get(instance)!.unsubscribe();
        this.instanceSubscriptions.delete(instance);
    }

    /**
     * Clone a given instance.
     *
     * @param instance  The instance to clone.
     * @returns A clone of the given instance.
     */
    public clone(instance: T): T {
        return this.readInstance(instance.toJSON());
    }
    /**
     * Create a form from a given instance.
     *
     * @param instance  The instance to create a form from.
     * @returns A {@link ModelForm} of the given instance.
     *
     * Example usage:
     *
     * ```typescript
     * // Assume we have a venue beer we want to edit.
     * declare let venueBeer: VenueBeer;
     * declare let table: VenueBeerTable;
     *
     * const venueBeerForm = table.createForm(venueBeer);
     * // Now we can freely edit `venueBeerForm.formInstance` without modifying the original instance (`venueBeer`).
     * const formInstance = venueBeerForm.formInstance;
     * formInstance.setBottleImageUrl('blabla');
     * formInstance.setDescription('nl', 'Dit is een bier.');
     *
     * // If you want to apply all changes, you can apply the form. If you want to ignore the changes, do nothing.
     * // Apply the changes:
     * venueBeerForm.applyForm();
     * console.log(venueBeer.getBottleImageUrl()); // prints 'blabla'
     * console.log(venueBeer.getDescription('nl')); // prints 'Dit is een bier'
     *
     * // Typically you want to save the original instance after applying a form.
     * venueBeer.save().then(() => {
     *  console.log('The venue beer was saved successfully!');
     * });
     * ```
     */
    public createForm(instance: T): ModelForm<T> {
        const formInstance = instance.clone();
        formInstance['_isDirty'] = false;
        const that = this;
        return {
            formInstance: formInstance,
            applyForm() {
                that.applyRawData(formInstance.toJSON(), instance);
            }
        };
    }
    /**
     * Create a new instance with default fields.
     *
     * @returns A new instance with default fields.
     */
    public abstract createInstance(): T;
    /**
     * Apply the raw JSON data to a given instance.
     *
     * @param data  The JSON data representing an instance.
     * @param instance  The instance that will contain given data.
     */
    public abstract applyRawData(data: any, instance: T): void;
    /**
     * Serialize an instance.
     *
     * @param instance  The instance to serialize.
     * @returns The JSON data representing the given instance.
     */
    public abstract serializeInstance(instance: T): any;
    /**
     * Read an instance from the given raw JSON data. This method first creates a new `instance` with {@link createInstance}
     * and will then parse the raw data with {@link applyRawData | `applyRawData`}`(data, instance)`.
     *
     * @param data  The raw JSON data.
     * @returns A new instance with the given data.
     */
    public readInstance(data: any): T {
        const instance = this.createInstance();
        this.applyRawData(data, instance);

        instance['_isDirty'] = false;
        return instance;
    }
    /**
     * Save an instance to the back-end and, if not present yet, adds the instance to this table.
     * Each table should implement their save-specific communication with the back-end in this method.
     *
     * This method should only be called from {@link PersistentModel.save}.
     *
     * @param instance  The instance to save.
     * @returns A promise that resolves when the instance is saved.
     * @throws Saved instance has a null id.
     */
    public saveInstance(instance: T): Promise<void> {
        const id = instance.getId();
        if (!id) {
            throw new Error(`Saved ${this.getModelName()} instance has a null id.`);
        }
        if (!this.has(id)) {
            this.add(instance);
        }
        return Promise.resolve();
    }
    /**
     * Delete an instance from the back-end and, if present, removes the instance from this table.
     * Each table should implement their delete-specific communication with the back-end in this method.
     *
     * This method should only be called from {@link PersistentModel.delete}.
     *
     * @param instance  The instance to delete.
     * @returns A promise that resolves when the instance is deleted.
     */
    public deleteInstance(instance: T): Promise<void> {
        const id = instance.getId();
        if (id !== null && this.has(id)) {
            this.remove(instance);
        }
        return Promise.resolve();
    }

    private getModelName(): string {
        return this.createInstance().constructor.name;
    }
    /**
     * Get the instance with the given id.
     *
     * @param id  The id to lookup an instance.
     * @returns The instance with the given id.
     * @throws This table does not contain an instance with the given id.
     */
    public get(id: string): T {
        const instance = this.instances.get(id);
        if (!instance) {
            let msg = `Table does not contain a ${this.getModelName()} with id ${id}.`;
            if (this.instances.size === 0) {
                msg += ' (table contains no instances!)';
            }
            throw new Error(msg);
        }
        return instance;
    }
    /**
     * Find an instance matching the given predicate.
     *
     * @param predicate  The predicate that the instance should match.
     * @returns A matching instance, or `null` if no instance matched.
     */
    public find(predicate: (instance: T) => boolean): T | null {
        for (const instance of this.instances.values()) {
            if (predicate(instance)) {
                return instance;
            }
        }
        return null;
    }
    /**
     * Find all instances matching the given predicate.
     *
     * @param predicate  The predicate that the instances should match.
     * @returns A set containing all matching instances.
     */
    public findAll(predicate: (instance: T) => boolean): Set<T> {
        const found = new Set<T>();
        for (const instance of this.instances.values()) {
            if (predicate(instance)) {
                found.add(instance);
            }
        }
        return found;
    }
    /**
     * Check whether this table contains an instance with the given id.
     *
     * @param id  The id to look for.
     * @returns `true` if the given id is present in this table, `false` otherwise.
     */
    public has(id: string): boolean {
        return this.instances.has(id);
    }
    /**
     * Get the set of all instances present in this table.
     *
     * @returns A set containing all instances currently present in this table.
     */
    public getInstances(): ReadonlySet<T> {
        return new Set(this.instances.values());
    }
    /**
     * Get this number of instances present in this table.
     *
     * @returns The number of instances present in this table.
     */
    public getCount(): number {
        return this.instances.size;
    }
}
