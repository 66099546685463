<ion-grid class="wrapper">
  <!-- Close button -->
  <ion-icon name="close" class="close-icon" (click)="onCancel()"></ion-icon>

  <!-- Beer name -->
  <ion-row class="beer-name">{{ name }}</ion-row>

  <!-- Progress bar with description -->
  <ion-row class="title">{{
    translateService.instant("EDIT_BEER." + pages[pageIndex].toUpperCase())
  }}</ion-row>
  <ion-row>
    <div
      *ngFor="let page of pages; let i = index"
      [ngClass]="'progress-el' + (i <= pageIndex ? ' progress-fill' : '')"
      (click)="setPageIndex(i)"
    ></div>
  </ion-row>

  <!-- Page content -->
  <div class="page-content">
    <!-- Standard -->
    <div *ngIf="pages[pageIndex] === 'standard'">
      <ion-row>
          <ion-col class="ion-no-padding">
          <app-checkbox-icon
            *ngFor="let checkBox of checkBoxes"
            [type]="checkBox.type"
            [checked]="checkBox.getter(formVenueBeer.formInstance)"
            (checkChange)="setCheckboxData(checkBox.type, $event)"
            class="checkbox"
          ></app-checkbox-icon>
        </ion-col>
      </ion-row>
    </div>


    <div *ngIf="pages[pageIndex] === 'description'">
      <ion-row class="section-title ion-no-padding">{{
      translateService.instant("EDIT_BEER.EXTRAINFO")
      }}</ion-row>
      <ion-row class="full-width">
        <app-language-text
          class="full-width"
          type="extraBeerInfo"
          [maxCharacters]="512"
          [textMap]="
            formVenueBeer.formInstance.getTriviaMap()
          "
          [nrOfRows]="2"
          [nrOfCols]="50"
        ></app-language-text>
      </ion-row>

      <!-- Beer description section -->
      <ion-row class="section-seperator"></ion-row>
      <ion-row class="section-title ion-no-padding"
        >{{ translateService.instant("EDIT_BEER.BEERDESCRIPTION")
        }}<span
          class="clickable small"
          (click)="onPersonalBeerDescription(false)"
          *ngIf="showBeerDescriptionInput"
          >{{ translateService.instant("EDIT_BEER.AUTOGENERATE") }}</span
        ></ion-row
      >
      <ion-row class="full-width table" *ngIf="!showBeerDescriptionInput">
        <p class="fluid-text"
          >{{
            translateService.instant("EDIT_BEER.AUTOGENERATE_EXPLENATION")
          }}:</p
        >
        <p class="italic fluid-text">{{ formVenueBeer.formInstance.getDescription(translateService.currentLang) }}.</p>
        <span class="fluid-text">{{
          translateService.instant("EDIT_BEER.PERSONALISED_EXPLANATION")
        }}</span>
        <span
          class="clickable fluid-text"
          (click)="onPersonalBeerDescription(true)"
          >{{ translateService.instant("EDIT_BEER.HERE") }}</span
        >.
      </ion-row>
      <ion-row class="full-width" *ngIf="showBeerDescriptionInput">
        <app-language-text
          class="full-width"
          style="background-color: white;;"
          type="beerDescription"
          [maxCharacters]="100"
          [textMap]="
            formVenueBeer.formInstance.getDescriptionMap()
          "
          [nrOfRows]="2"
          [nrOfCols]="50"
          (change)="makeFormDirty()"
          ></app-language-text>
      </ion-row>
    </div>

    <div *ngIf="pages[pageIndex] === 'image'">
      <!-- Image + flag section -->
      <ion-row class="section-title ion-no-padding">{{
        translateService.instant("EDIT_BEER.IMAGE_SECTION_TITLE")
      }}</ion-row>
      <ion-row>
        <ion-col size="2" class="ion-no-padding">
          <div class="file-upload-box">
            <app-file-input
            [imageUrl]="formVenueBeer.formInstance.getBottleImageUrl()"
            [uploadName]="'bottleimg-' + formVenueBeer.formInstance.getId()!"
            (fileChange)="onBottleImageUrlChange($event)"
          ></app-file-input>
          </div>
        </ion-col>
      </ion-row>
    </div>

    <!-- Tags -->
    <div *ngIf="pages[pageIndex] === 'tags'" class="padding full-width center">
      <app-tag-filter
        [tagSize]="'normal'"
        [showAddTag]="true"
        [selectedTags]="initiallySelectedTags"
        [showOtherLangLabel]="true"
        [venue]="venue"
        (selectionChange)="onBeerTagSelect($event)"
      ></app-tag-filter>
    </div>

    <!-- Location -->
    <div *ngIf="pages[pageIndex] === 'location'" class="location-wrapper">
      <app-store-locator
        [initialLocation]="formVenueBeer.formInstance.getLocation()"
        [canEdit]="true"
        (locationChange)="onChangeLocation($event)"
      ></app-store-locator>
    </div>
  </div>

  <!-- navigation -->
  <div class="navigation-row">
    <!-- Previous -->
    <ion-button
      [disabled]="pageIndex < 1"
      color="secondary"
      (click)="onNavigate(-1)"
      >{{ translateService.instant("EDIT_BEER.PREVIOUS") }}</ion-button
    >

    <!-- Correction options -->
    <div class="incorrection">
      {{ translateService.instant("EDIT_BEER.INCORRECTION_SPOTTED") }}
      <a class="clickable" [href]="mailto">{{
        translateService.instant("EDIT_BEER.LET_US_KNOW")
      }}</a>
    </div>

    <!-- Next -->
    <ion-button
      [disabled]="pageIndex >= pages.length - 1"
      color="secondary"
      slots="end"
      class="ion-float-right"
      (click)="onNavigate(1)"
      >{{ translateService.instant("EDIT_BEER.NEXT") }}</ion-button
    >

    <!-- Save -->
    <ion-button
      color="secondary"
      [fill]="pageIndex >= pages.length - 1 ? 'solid' : 'outline'"
      class="ion-float-right"
      (click)="onSave()"
      >{{
        pageIndex >= pages.length - 1
          ? translateService.instant("EDIT_BEER.COMPLETE")
          : translateService.instant("EDIT_BEER.SAVE")
      }}</ion-button
    >
  </div>
</ion-grid>
