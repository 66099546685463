import { Component, Input } from '@angular/core';
import * as Chart from 'chart.js';

interface Config {
  title: string;
  xData: string[];
  yData: number[];
}
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {
  public static chartCounter = 1;

  private _config!: Config;

  @Input()
  public set config(val: Config) {
    this._config = val;
    if (val) {
      this.drawCanvas();
    }
  }

  public get config(): Config {
    return this._config;
  }

  public id: string;

  public constructor() {
    this.id = 'canvas' + ChartComponent.chartCounter.toString();
    ChartComponent.chartCounter++;
  }

  public drawCanvas() {
    setTimeout(() => {
      const canvas = <HTMLCanvasElement> document.getElementById(this.id);
      const ctx = canvas.getContext('2d')!;

      const data = this.getData();
      const labels = this.getLabels();
      this.plotData(data, labels, ctx);
    }, 100);
  }

  private getData(): number[] {
    return this.config.yData;
  }

  private getLabels(): string[] {
    return this.config.xData;
  }

  private plotData(data: number[], labels: string[], ctx: CanvasRenderingContext2D): Chart {
    // determine stepsize
    let stepSize = 1;
    let maxValue = 5;
    if (data && data.length > 0) {
      for (let i=0; i<data.length;i++) {
        if (data[i] > maxValue) maxValue = data[i];
      }
      stepSize = Math.trunc(maxValue / 5);
    }
    return new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: undefined,
            data: data,
            backgroundColor: 'rgba(255, 204, 0, 0.4)',
            borderColor: 'rgba(0, 0, 0, 0.8)',
            borderWidth: 1,
            categoryPercentage: 1.0,
            barPercentage: Math.max(0.9, 1 - 1 / data.length)
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.config.title,
          fontSize: 20
        },
        scales: {
          xAxes: [{
            ticks: {
              min: 0
            },
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: stepSize,
              suggestedMin: 0,
              min: 0
            }
          }]
        },
        legend: {
          display: false,
        }
      }
    });
  }
}
