import { VenueService } from './../../services/venue/venue.service';
import { ScreenVersionListener } from './../../models/screen-version-listener';
import { Beer } from '../../models/persistency/persistent-models/beer';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../services/url/url.service';
import { LoggerService } from '../../services/logger/logger.service';
import { Venue } from '../../models/persistency/persistent-models/venue';

@Component({
  selector: 'app-share-icon',
  templateUrl: './share-icon.component.html',
  styleUrls: ['./share-icon.component.scss']
})
export class ShareIconComponent extends ScreenVersionListener implements OnInit {
  @Input()
  private beer!: Beer;

  public fbUrl!: string;
  public twitterUrl!: string;

  private venue: Venue;

  public constructor(
    public translateService: TranslateService,
    public urlService: UrlService,
    public logger: LoggerService,
    private venueService: VenueService
  ) {
    super();
    this.venue = this.venueService.getSelectedVenue(true);
  }

  public ngOnInit(): void {
    this.fbUrl = this.getLinkFacebook();
    this.twitterUrl = this.getLinkTwitter();
  }

  private getLinkFacebook(): string {
    const fbBaseUrl = 'https://www.facebook.com/sharer/sharer.php?';
    const shareUrl = this.urlService.getFullUrl();
    const fbTitle = this.getDescription();

    const result = fbBaseUrl + 'u=' + shareUrl + '&t=' + fbTitle;
    this.logger.debug('share-icon', 'sharing on Facebook with Url: ' + result);
    return result;
  }

  private getLinkTwitter(): string {
    const twitterUrl = 'https://twitter.com/home?status=';
    const shareUrl = this.urlService.getFullUrl();
    const tweet = this.getDescription() + '%20' + shareUrl;

    const result = twitterUrl + tweet;
    this.logger.debug('share-icon', 'sharing on Twitter with Url: ' + result);
    return result;
  }

  private getDescription(): string {
    const description =
      this.translateService.instant('SHARE.ENJOYSA') +
      '%20' +
      this.beer.getName() +
      '%20' +
      this.translateService.instant('SHARE.IN') +
      '%20' +
      this.venue.getName() +
      ':';

    return description.split(' ').join('%20');
  }
}
