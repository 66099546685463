<!-- Search bar -->
<app-searchbar (searchInput)="onSearchInput($event)" (search)="onSearch()" (focus)="onFocus($event)"></app-searchbar>

<ion-content
  *ngIf="isFocused"
  [class]="'autocomplete-wrapper-' + screenType"
  id="ion-content-searchbar"
>
  <ion-row
    *ngFor="let item of results"
    class="autocomplete-row"
    (click)="item.clickable ? onSelect(item) : ''"
  >
    <ion-col [size]="isDeskTop() ? '0.6' : '1.2'" class="col-wrapper">
      <div class="autocomplete-icon-wrapper">
        <ion-icon
          [src]="item.icon"
          class="icon"
        ></ion-icon>
      </div>
    </ion-col>
    <ion-col
      [size]="isDeskTop() ? '11' : '10.42'"
      [ngClass]="'relative' + (item.clickable ? '' : ' beername-notselectable')"
    >
      <p [class]="'beername-text-' + screenType">
        {{ item.name }}
      </p>
    </ion-col>
  </ion-row>

  <!-- no results text -->
  <ion-row class="no-results" *ngIf="currentValue && results.length === 0">
    <div>
      <ion-icon name="information-circle-outline" class="info-icon"></ion-icon>
      <span>
        {{ translateService.instant("GENERAL.NO_RESULTS") }}
      </span>
    </div>
  </ion-row>

  <ion-infinite-scroll
    *ngIf="currentValue"
    threshold="100px"
    (ionInfinite)="onInfiniteScroll($any($event))"
  >
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      [loadingText]="translateService.instant('GENERAL.LOADING')"
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
