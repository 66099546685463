import { IdleTrackerService } from './../../services/idle-tracker/idle-tracker.service';
import { AnalyticsTrackerService } from './../../services/analytics-tracker/analytics-tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { VenueService } from '../../services/venue/venue.service';
import { BeerService } from '../../services/beer/beer.service';
import { BreweryService } from '../../services/brewery/brewery.service';
import { ModelTable } from '../../models/persistency/model-table';
import { UrlService } from '../../services/url/url.service';

@Injectable({
  providedIn: 'root'
})
export class InitGuard implements CanActivate {
  public constructor(
    private venueService: VenueService,
    private beerService: BeerService,
    private breweryService: BreweryService,
    private idleTrackerService: IdleTrackerService,
    private router: Router,
    private translateService: TranslateService,
    private analyticsTracker: AnalyticsTrackerService,
    private urlService: UrlService
  ) {
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<UrlTree|boolean> | UrlTree {
    const venueUrl = next.paramMap.get('venue-url');
    if (!venueUrl) {
      return this.router.createUrlTree([]);
    }
    return Promise.all(
        [ this.initializeTables()
        , this.venueService.loadVenue(venueUrl).catch(() => null)
        ]).then(([_, loadedVenue]) => {
      if (!loadedVenue) {
        return this.router.createUrlTree([]);
      }
      this.analyticsTracker.startTrack(loadedVenue);
      if (this.urlService.isKioskMode()) this.idleTrackerService.trackIdleness();
      const lang = loadedVenue.getDefaultLanguage();
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
      return loadedVenue.loadTables().then(() => true);
    });
  }

  private initializeTables(): Promise<void> {
    return ModelTable.loadTables(this.beerService, this.breweryService);
  }

}
