import { VenueService } from './../../services/venue/venue.service';
import { ScreenVersionListener } from './../../models/screen-version-listener';
import { BreweryService } from './../../services/brewery/brewery.service';
import { SloppyString } from '../../models/sloppy-string';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { BeerService } from '../../services/beer/beer.service';
import { TrackingService } from '../../services/tracking/tracking.service';
import { Beer } from '../../models/persistency/persistent-models/beer';
import { Brewery } from '../../models/persistency/persistent-models/brewery';
import { IonInfiniteScroll } from '@ionic/angular';

export interface SearchItem {
  clickable: boolean;
  icon: string;
  name: string;
  priority: number;
  object: Beer | Brewery;
}
export interface SearchEvent {
  matches: SearchItem[];
  query: string;
}

@Component({
  selector: 'app-searchbar-with-autocomplete',
  templateUrl: './searchbar-with-autocomplete.component.html',
  styleUrls: ['./searchbar-with-autocomplete.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ transform: 'translateY(-50%) scale(1, 0)' }),
        animate(100, style({ transform: 'translateY(0%) scale(1, 1)' }))
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(50, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SearchbarWithAutocompleteComponent extends ScreenVersionListener implements OnInit {
  @ViewChild(IonInfiniteScroll)
  private infiniteScroll!: IonInfiniteScroll;

  public allResults: SearchItem[] = [];
  public results: SearchItem[] = [];
  public currentValue = '';
  public infiniteScrollAmount = 20;

  public isFocused = false;

  @Output()
  public search = new EventEmitter<SearchEvent>();

  @Output()
  public itemClick = new EventEmitter<SearchItem>();

  @Output()
  public focus = new EventEmitter<boolean>();

  @Output()
  public searchInput = new EventEmitter<string>();

  private allBeersAndBreweries: SearchItem[] = [];

  constructor(
    private beerService: BeerService,
    private breweryService: BreweryService,
    public translateService: TranslateService,
    private trackingService: TrackingService,
    private venueService: VenueService
  ) {
    super();
  }

  public ngOnInit(): void {
    const venue = this.venueService.getSelectedVenue(true);

    this.allBeersAndBreweries = Array.from(this.beerService.getInstances(),
      (beer) => {
        const isAvailable = beer.isAvailable(venue);
        const hasSimilars = beer.hasSimilarVenueBeers(venue);
        return {
          clickable: isAvailable || hasSimilars,
          icon: isAvailable || hasSimilars ? 'assets/svgs/general/icon_beer.svg' : 'assets/svgs/general/icon_beer_not_available.svg',
          name: beer.getName(),
          priority: isAvailable ? 2 : (hasSimilars ? 1 : 0.5),
          object: beer
        };
    });
    for (const brewery of this.breweryService.getInstances()) {
      this.allBeersAndBreweries.push({
        clickable: true,
        icon: 'assets/svgs/general/icon_brewery.svg',
        name: brewery.getName(),
        priority: 1,
        object: brewery});
    }
  }

  public onSearchInput(value: string): void {
    this.currentValue = value;

    if (this.currentValue) {
      this.allResults = SloppyString.filterAndSort(
        this.currentValue,
        this.allBeersAndBreweries,
        (item) => item.name,
        (item) => item.priority
      );
      this.results = this.allResults.slice(0, this.infiniteScrollAmount);
      if (this.results.length === 0) {
        this.trackingService.trackEvent('no-results', {
          text: this.currentValue
        });
      }
    } else {
      this.results = [];
    }
    this.searchInput.emit(value);
  }

  public onSearch(): void {
    if (this.currentValue) {
      this.trackingService.trackEvent('searched-text', {
        text: this.currentValue
      });
      this.search.emit({
        matches: this.allResults,
        query: this.currentValue
      });
    }
  }

  public onSelect(item: SearchItem): void {
    this.trackingService.trackEvent('select-beer', {
      id: item.object.getId(),
      text: this.currentValue
    });
    this.itemClick.emit(item);
  }

  public onFocus(value: boolean): void {
    this.isFocused = value;
    this.focus.emit(value);
  }

  public onInfiniteScroll(event: CustomEvent<void>): void {
    this.results = this.allResults.slice(0, this.results.length + this.infiniteScrollAmount);
    this.infiniteScroll.complete();
  }
}
