<!-- Search bar -->
<ion-header
  [ngClass]="
    inHeader
      ? 'search-bar-in-header'
      : searchbarFocused
      ? 'search-bar-header-focused'
      : isDeskTop()
      ? 'search-bar-header-widescreen'
      : 'search-bar-header-mobile'
  "
  no-border
>
  <div [class]="'search-bar-row-' + screenType">
    <!-- Search icon -->
    <div
      class="col-wrapper"
    >
      <div [class]="'icon-wrapper-' + screenType" (click)="searchbarFocused ? onBackButtonClick() : onSearch()">
        <ion-icon
          *ngIf="!searchbarFocused"
          src="assets/svgs/general/icon_search.svg"
          class="icon"
        ></ion-icon>
        <ion-icon
          *ngIf="searchbarFocused"
          name="arrow-back"
          class="back-icon"
        ></ion-icon>
      </div>

    <!-- Input field -->
    <div size="10.42" class="input-col">
      <ion-input #bar
        (ionFocus)="onFocusInput()"
        (ionBlur)="onBlurInput()"
        (ionChange)="onSearchInput($any($event))"
        [placeholder]="translateService.instant('GENERAL.NAME_BEER_OR_BREWERY')"
        (keyup.enter)="onSearch()"
        [debounce]="300"
        [class]="'search-input-' + screenType + ' ion-no-padding'"
        id="searchinput"
        [clearInput]="true"
      ></ion-input>
    </div>
  </div>
</div>
</ion-header>
