<div>
  <!-- Close button -->
  <ion-icon name="close" class="close-icon" (click)="onCancel()"></ion-icon>

  <!-- Title -->
  <div class="ion-justify-content-start title">
    {{translateService.instant("ADMIN_SETTINGS.LANGUAGES")}}
  </div>

  <ion-grid style="float:left;">
    <ion-row class="ion-justify-content-start">
      <ion-col class="ion-align-self-start">{{translateService.instant("ADMIN_SETTINGS.LANGUAGE")}}</ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-start" *ngFor="let language of languageOptions">
      <ion-col class="ion-align-self-start">{{translateService.instant("LANGUAGE." + language.toUpperCase())}}:</ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid style="float:left;">
    <ion-row class="ion-justify-content-center">
      <ion-col class="ion-align-self-center column">{{translateService.instant("ADMIN_SETTINGS.AVAILABLE_LANG")}}</ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center" *ngFor="let language of languageOptions">
      <ion-col class="ion-align-self-center column">
        <ion-checkbox color="secondary" class="langbox"
        [disabled]="defaultLanguage === language"
        [checked]="availableLanguages.has(language)"
        (ionChange)="languageChange(language, $any($event))"></ion-checkbox>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid>
    <ion-row class="ion-justify-content-center" style="width: 20%;">
      <ion-col class="ion-align-self-center column">{{translateService.instant("ADMIN_SETTINGS.DEFAULT_LANG")}}</ion-col>
    </ion-row>
    <ion-radio-group [value]="defaultLanguage" (ionChange)="defaultLanguageChange($any($event))">
    <ion-row class="ion-justify-content-center" style="width:20%;" *ngFor="let language of languageOptions">
      <ion-col class="ion-align-self-center column">
        <ion-radio [disabled]="!availableLanguages.has(language)" color="secondary" class="langbox" value="{{language}}"></ion-radio>
      </ion-col>
    </ion-row>
    </ion-radio-group>
  </ion-grid>
<div>
    <!-- Cancel -->
    <ion-button
      color="secondary"
      fill="outline"
      class="ion-float-right save-button"
      (click)="onCancel()"
      >{{translateService.instant("GENERAL.CANCEL")}}
    </ion-button>
    <!-- Save -->
    <ion-button
      *ngIf="formVenue.isDirty()"
      color="secondary"
      fill="solid"
      class="ion-float-right save-button"
      (click)="onSave()"
      >{{translateService.instant("EDIT_BEER.SAVE")}}
    </ion-button>
  </div>
</div>