import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapsable-title',
  templateUrl: './collapsable-title.component.html',
  styleUrls: ['./collapsable-title.component.scss']
})
export class CollapsableTitleComponent {
  @Input()
  public title!: string;

  @Input()
  public show = false;

  public constructor() { }
}
