import { TranslateService } from '@ngx-translate/core';
import { Serializable } from './serializable';
import { VenueService } from './../services/venue/venue.service';
import { Beer } from './persistency/persistent-models/beer';
import { VenueBeer } from './persistency/persistent-models/venue-beer';
import { Injectable } from '@angular/core';
import internationalization from './defaults/internationalization.json';

/**
 * An interface representing a sorting algorithm and a direction to sort in.
 */
export interface SorterState {
    sorter: Sorter;
    descending: boolean;
}
/**
 * A class representing a sorting algorithm for beers and venue beers.
 */
export abstract class Sorter implements Serializable {
    public static None = new class extends Sorter {
        public constructor() {
            super('none', 'NONE');
        }
        public compareBeers(a: Beer, b: Beer, descending: boolean): 0|1|-1 {
            return 0;
        }

        public sortBeers(beers: Beer[], descending: boolean = false): Beer[] {
            return beers;
        }
        public sortVenueBeers(venueBeers: VenueBeer[], descending: boolean = false): VenueBeer[] {
            return venueBeers;
        }
    };

    private labelKey: string;
    private translationKey: string;

    public constructor(labelKey: string, translationKey: string) {
        this.labelKey = labelKey;
        this.translationKey = translationKey;
    }

    /**
     * Sort a list of beers using a list of sorting algorithms. Algorithms with a higher index
     * have a lower priority.
     *
     * @param beers  The list of beers to sort.
     * @param sorterStates  The list of sorting algorithms to use.
     * @return The sorted list of beers.
     */
    public static sortBeers(beers: Beer[], sorterStates: SorterState[]): Beer[] {
        const compare = (a: Beer, b: Beer) => {
            let c: 0|1|-1 = 0;
            for (let i = 0; i < sorterStates.length; i++) {
                const state = sorterStates[i];
                if (state.descending) {
                    c = -state.sorter.compareBeers(a, b, state.descending) as 0|1|-1;
                } else {
                    c = state.sorter.compareBeers(a, b, state.descending);
                }
                if (c !== 0) {
                    break;
                }
            }
            return c;
        };
        return beers.sort(compare);
    }
    /**
     * Sort a list of venue beers using a list of sorting algorithms. Algorithms with a higher index
     * have a lower priority.
     *
     * @param venueBeers  The list of venue beers to sort.
     * @param sorterStates  The list of sorting algorithms to use.
     * @return The sorted list of venue beers.
     */
    public static sortVenueBeers(venueBeers: VenueBeer[], sorterStates: SorterState[]): VenueBeer[] {
        const compare = (a: VenueBeer, b: VenueBeer) => {
            let c: 0|1|-1 = 0;
            for (let i = 0; i < sorterStates.length; i++) {
                const state = sorterStates[i];
                if (state.descending) {
                    c = -state.sorter.compareVenueBeers(a, b, state.descending) as 0|1|-1;
                } else {
                    c = state.sorter.compareVenueBeers(a, b, state.descending);
                }
                if (c !== 0) {
                    break;
                }
            }
            return c;
        };
        return venueBeers.sort(compare);
    }


    /**
     * Get the unique label key of this sorter.
     *
     * @returns The unique label key of this sorter.
     */
    public getLabelKey(): string {
        return this.labelKey;
    }
    /**
     * Get the translation key representing the label of this sorter.
     *
     * @returns A translation key that can be used by the translate service to get a label of this sorter
     * is a specific language.
     */
    public getTranslationKey(): string {
        return this.translationKey;
    }
    /**
     * Create an ascending sorter state with this algorithm.
     *
     * @returns An ascending sorter state with this algorithm.
     */
    public createState(): SorterState {
        return {
            sorter: this,
            descending: false
        };
    }

    public abstract compareBeers(a: Beer, b: Beer, descending: boolean): 0|1|-1;

    public compareVenueBeers(a: VenueBeer, b: VenueBeer, descending: boolean): 0|1|-1 {
        return this.compareBeers(a.getBeer(), b.getBeer(), descending);
    }

    public sortBeers(beers: Beer[], descending: boolean): Beer[] {
        const compare = descending
            ? (a: Beer, b: Beer) => -this.compareBeers(a, b, descending)
            : (a: Beer, b: Beer) => this.compareBeers(a, b, descending);
        return beers.sort((a, b) => compare(a, b));
    }
    public sortVenueBeers(venueBeers: VenueBeer[], descending: boolean): VenueBeer[] {
        const compare = descending
            ? (a: VenueBeer, b: VenueBeer) => -this.compareVenueBeers(a, b, descending)
            : (a: VenueBeer, b: VenueBeer) => this.compareVenueBeers(a, b, descending);
        return venueBeers.sort((a, b) => compare(a, b));
    }

    public toJSON(): any {
        return this.labelKey;
    }
}
/**
 * A class representing a sorting algorithm for venue beers. Beers without a venue beer will always come after beers that have one.
 */
export abstract class VenueBeerSorter extends Sorter {
    public constructor(labelKey: string, translationKey: string, private venueService: VenueService) {
        super(labelKey, translationKey);
    }

    public abstract compareVenueBeers(a: VenueBeer, b: VenueBeer, descending: boolean): 0|1|-1;

    public compareBeers(a: Beer, b: Beer, descending: boolean): 0|1|-1 {
        const venue = this.venueService.getSelectedVenue();
        if (!venue) {
            return 0;
        }
        const vba = a.getVenueBeer(venue);
        const vbb = b.getVenueBeer(venue);
        if (!vba && !vbb) {
            return 0;
        }
        if (!vba) {
            return descending ? -1 : 1;
        }
        if (!vbb) {
            return descending ? 1 : -1;
        }
        return this.compareVenueBeers(vba, vbb, descending);
    }
}

/**
 * A class exposing common sorting algorithms for beers and venue beers.
 */
@Injectable({
    providedIn: 'root'
})
export class Sorters {
    public readonly alphabetic: Sorter;
    public readonly ABV: Sorter;
    public readonly price: Sorter;
    public readonly available: Sorter;

    private readonly sorterMap = new Map<string, Sorter>();
    protected add(...sorters: Sorter[]): void {
        sorters.forEach((sorter) => this.sorterMap.set(sorter.getLabelKey(), sorter));
    }
    public getSorterByLabelKey(labelKey: string): Sorter | undefined {
        return this.sorterMap.get(labelKey);
    }

    public constructor(venueService: VenueService, translateService: TranslateService) {
        this.alphabetic = new class extends Sorter {
            public constructor() {
                super('alphabetic', 'SORT.ALPHABETIC');
            }

            public compareBeers(a: Beer, b: Beer, descending: boolean = false): 0|1|-1 {
                return Math.sign(a.getName().localeCompare(
                    b.getName(),
                    internationalization.locale[translateService.currentLang as keyof typeof internationalization.locale],
                    { usage: 'sort', sensitivity: 'base' }
                )) as 0|1|-1;
            }
        };

        this.ABV = new class extends Sorter {
            public constructor() {
                super('alcohol', 'SORT.ALCOHOL');
            }

            public compareBeers(a: Beer, b: Beer, descending: boolean = false): 0|1|-1 {
                const abv1 = a.getABV();
                const abv2 = b.getABV();
                if (abv1 === null && abv2 === null) {
                    return 0;
                }
                if (abv1 === null) {
                    return descending ? -1 : 1;
                }
                if (abv2 === null) {
                    return descending ? 1 : -1;
                }
                return Math.sign(abv1 - abv2) as 0|1|-1;
            }
        };

        this.price = new class extends VenueBeerSorter {
            public constructor() {
                super('price', 'SORT.PRICE', venueService);
            }

            public compareVenueBeers(a: VenueBeer, b: VenueBeer, descending: boolean): 0|1|-1 {
                const pa = a.getMinimumPrice();
                const pb = b.getMinimumPrice();
                if (pa === Infinity && pb === Infinity) {
                    return 0;
                }
                if (pa === Infinity) {
                    return descending ? -1 : 1;
                }
                if (pb === Infinity) {
                    return descending ? 1 : -1;
                }
                return Math.sign(pa - pb) as 0|1|-1;
            }
        };

        this.available = new class extends VenueBeerSorter {
            public constructor() {
                super('available', 'SORT.AVAILABILITY', venueService);
            }

            public compareVenueBeers(a: VenueBeer, b: VenueBeer, descending: boolean): 0|1|-1 {
                return 0;
            }
        };

        this.add(this.alphabetic, this.ABV, this.price, this.available);
    }
}
