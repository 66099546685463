import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

/**
 * A service responsible for providing feedback to the user.
 */
@Injectable({
  providedIn: 'root'
})
export class UserFeedbackService {
  public $showLoading = new BehaviorSubject<boolean>(false);
  private loadingCount = 0;

  public constructor(
    private translateService: TranslateService,
    private toastController: ToastController,
    private alertController: AlertController
  ) {
  }

  /**
   * Increment the loading counter and start showing the loading overlay if it is greater than 0.
   */
  public startLoading(): void {
    if (this.loadingCount === 0) {
      this.$showLoading.next(true);
    }
    this.loadingCount++;
  }

  /**
   * Decrement the loading counter and stop loading the loading overlay if it equals 0.
   */
  public stopLoading(): void {
    if (this.loadingCount === 0) {
      console.error('Tried to stop loading while already not loading.');
      return;
    }
    this.loadingCount--;
    if (this.loadingCount === 0) {
      this.$showLoading.next(false);
    }
  }

  /**
   * Present a toast for the given duration.
   *
   * @param message  The message to display, or the prefix of the translation key if given.
   * @param translateKey  The translation key of the message to display.
   * @param duration  The duration in milliseconds to display the toast.
   */
  public async giveTextualFeedback(
    message: string,
    translateKey?: string,
    duration: number = 2000
  ): Promise<void> {
    if (translateKey) {
      message = this.translateService.instant(
        message.toUpperCase() + '.' + translateKey.toUpperCase()
      );
    }

    const toast = await this.toastController.create({
      message: message,
      duration: duration
    });
    await toast.present();
  }

  /**
   * Show a pop-up to ask for confirmation.
   *
   * @param headerKey  The translation key of the header.
   * @param onConfirm  The handler for when the user confirms.
   * @param onCancel  The handler for when the user cancels.
   */
  public async requestConfirmation(headerKey: string,
      onConfirm: (value: any) => boolean | void | { [key: string]: any; },
      onCancel: (value: any) => boolean | void | { [key: string]: any; }): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translateService.instant(headerKey),
      buttons: [
        {
          text: this.translateService.instant('GENERAL.NO'),
          role: 'cancel',
          handler: onCancel
        },
        {
          text: this.translateService.instant('GENERAL.YES'),
          handler: onConfirm
        }
      ]
    });

    await alert.present();
  }

  /**
   * Present a pop-up with the given message.
   *
   * @param text  The message to display to the user.
   */
  public async notifyWithInteraction(text: string): Promise<void> {
    const alert = await this.alertController.create({
      header: text,
      buttons: [
        {
          text: this.translateService.instant('GENERAL.OK'),
          role: 'cancel',
        }
      ]
    });

    await alert.present();
  }
}
