export const modelUrls = {
    beersandbreweries: {
        'base': 'apiBaseUrl',
        'HEAD': 'pub_manager/beer/beersandbreweries',
        'GET': 'pub_manager/beer/beersandbreweries'
    },
    venue: {
        'base': 'apiBaseUrl',
        'GET': 'pub_manager/pub/getbyurlname',
        'CREATE': 'pub_manager/pub/create',
        'UPDATE': 'pub_manager/pub/update',
        'DELETE': 'pub_manager/pub/delete'
    },
    adminvenue: {
        'base': 'apiBaseUrl',
        'GET': 'pub_manager/pub/list?archived=0&ownerUserId=',
        'CREATE': 'pub_manager/pub/create',
        'UPDATE': 'pub_manager/pub/update',
        'DELETE': 'pub_manager/pub/delete'
    },
    venuebeer: {
        'base': 'apiBaseUrl',
        'GET': 'pub_manager/pub/listbeers/',
        'POST': 'pub_manager/pubbeer/crud'
    },
    venuebeerprofile: {
        'base': 'lambdaApiBaseUrl',
        'GET': 'profile'
    },
    userprofile: {
        'base': 'apiBaseUrl',
        'UPDATE': 'admin/user/update'
    }
} as const;
