<ion-row *ngFor="let row of rows" class="input-row">
  <!-- Language dropdown -->
  <div>
    <ion-icon
      name="remove-circle-outline"
      class="minus-dropdown"
      (click)="onRemoveRow(row)"
    ></ion-icon>
  </div>
  <div class="language-selector">
    <!-- <ion-text color="primary-contrast">{{translateService.instant(row.selectedOption.translationKey)}}</ion-text> -->
    <app-popover-select
      [options]="options"
      [selected]="row.selectedOption"
      (selectionChange)="onSelectLang(row, $event)"
    ></app-popover-select>
  </div>

  <!-- text field  -->
  <div class="text-area">
    <ion-textarea
      [maxlength]="maxCharacters"
      [placeholder]="
        translateService.instant('LANG_TEXT.' + type.toUpperCase())
      "
      [debounce]="200"
      (ionChange)="onTextChange($any($event), row)"
      class="text-area"
      [value]="row.value"
      [rows]="nrOfRows"
      [cols]="nrOfCols"
    ></ion-textarea>
  </div>
</ion-row>

<!-- add row button -->
<ion-row class="add-row" *ngIf="rows.length < options.length">
  <ion-icon name="add-circle" (click)="onAddRow()" class="add-icon"></ion-icon>
</ion-row>
