<ion-row class="row-wrapper">
  <!-- volumes -->
  <div class="order-servemethod-icon">
    <img [src]="'assets/svgs/general/icon_' + serving.getCasing() + '.svg'" class="serve-icon" />
  </div>
  <div class="order-volume">{{ serving.getVolumeUnit().format(serving.getVolume()) }}</div>

  <!-- prices -->
  <div class="order-price">
    <span [ngClass]="serving.isInPromotion() ? ' price-on-promo' : ''">
      {{ price }}
    </span>
    <span *ngIf="serving.isInPromotion()" class="order-promo-price">
      {{ promoPrice }}
    </span>
  </div>

  <!-- add or remove from basket -->
  <div class="add-to-order-wrapper">
    <ion-icon
      name="remove-circle"
      class="order-icon"
      (click)="editBeerOrder(-1)"
    ></ion-icon>
    <div class="order-text">{{ orderCount }}</div>
    <ion-icon
      name="add-circle"
      class="order-icon"
      (click)="editBeerOrder(1)"
    ></ion-icon>
  </div>
</ion-row>
