<div class="sort-and-filter-wrapper">
  <!-- back button -->
  <div class="back-button" (click)="onBackClick()" *ngIf="showBackButton && beerCount === null">
    <ion-icon class="back-icon" src="assets/svgs/general/back.svg"></ion-icon>
  </div>

  <!-- Filter button -->
  <div
    [class]="!showBackButton && beerCount !== null ? 'filter-wrapper-shifted' : 'filter-wrapper'"
    (click)="onShowFilter()"
    *ngIf="showFilterIcon"
  >
    <!-- sort direction arrow -->
    <ion-icon
      *ngIf="hasFilters"
      src="assets/svgs/general/icon_filter.svg"
      class="filter-icon"
    ></ion-icon>
    <ion-icon
      *ngIf="!hasFilters"
      src="assets/svgs/general/icon_filter_outlined.svg"
      class="filter-icon"
    ></ion-icon>

    <span [class]="'filter-text-' + screenType">{{
      translateService.instant("SORT.FILTERS")
    }}</span>
  </div>

  <!-- beer count -->
  <div class="beer-count" *ngIf="beerCount !== null">
    {{ beerCount }}
    <ion-icon
      [src]="'assets/svgs/general/icon_beer.svg'"
      class="beer-count-icon"
    ></ion-icon>
  </div>

  <!-- search bar -->
  <div class="searchbar" *ngIf="showSearch">
    <app-searchbar [inHeader]="true" (searchInput)="onSearch($event)" ></app-searchbar>
  </div>

  <!-- sort button -->
  <div class="sort-wrapper">
    <!-- sort direction arrow -->
    <ion-icon
      [name]="
        query.sort && query.sort[0]?.descending
          ? 'arrow-up'
          : 'arrow-down'
      "
      [class]="'up-down-arrow-' + screenType"
      (click)="onChangeDirection()"
    ></ion-icon>

    <div
      [ngClass]="
        'sort-selector-wrapper' + (isDeskTop() ? '-widescreen' : '')
      "
    >
      <app-popover-select
        [options]="sortOptions"
        [selected]="selected"
        (selectionChange)="onSortChange($event)"
        class="sort-selector"
      ></app-popover-select>
    </div>
  </div>
</div>

<!-- dark background -->
<div
  class="black-page"
  *ngIf="showOverlay"
  (click)="onHideOverlay(false)"
></div>

<!-- filter popover -->
<div
  [ngClass]="'popover-selector' + (isDeskTop() ? '-widescreen' : '')"
  *ngIf="showOverlay"
  (swipeleft)="onSwipeLeft()"
  (swiperight)="onSwipeRight()"
>
  <!-- Filter page navigation -->
  <div class="filter-navigation-wrapper">
    <div
      *ngFor="let filterPage of filterPages; let i = index"
      [ngClass]="
        'ball' + (filterPageIndex === i ? ' large-ball' : ' small-ball')
      "
      (click)="setFilterPageIndex(i)"
    ></div>
  </div>

  <!-- Filter page navigation arrows -->
  <div class="filter-arrow-wrapper">
    <div
      class="filter-arrow left-filter-arrow"
      (click)="onSwipeRight()"
      [ngStyle]="{ opacity: filterPageIndex <= 0 ? 0 : 1 }"
    >
      <ion-icon name="chevron-back" class="filter-arrow-icon"></ion-icon>
    </div>
    <div
      class="filter-arrow right-filter-arrow"
      (click)="onSwipeLeft()"
      [ngStyle]="{ opacity: filterPageIndex >= filterPages.length - 1 ? 0 : 1 }"
    >
      <ion-icon name="chevron-forward" class="filter-arrow-icon"></ion-icon>
    </div>
  </div>

  <!-- Filter pages -->
  <div
    *ngFor="let filterPage of filterPages; let i = index"
    class="popup-selector-wrapper"
  >
    <div [ngSwitch]="filterPage.name"
      [ngClass]="'popup-selector' + (isDeskTop() ? '-widescreen' : '')"
      [ngStyle]="{
        'z-index': filterPage.location == 'center' ? 100 : 98
      }"
      [@slideInOut]="filterPage.location"
    >
      <app-selector *ngSwitchCase="'aroma'"
        [fillings]="aromaHexaselector"
        [selected]="initiallySelectedAroma"
        (selectionChange)="onAromaChange($event)"
      ></app-selector>
      <app-selector *ngSwitchCase="'color'"
        [fillings]="colorHexaselector"
        [selected]="initiallySelectedColor"
        (selectionChange)="onColorChange($event)"
      ></app-selector>
      <app-selector *ngSwitchCase="'other'"
        [fillings]="otherHexaselector"
        [selected]="initiallySelectedOther"
        (selectionChange)="onOtherChange($event)"
      ></app-selector>
      <app-tag-filter *ngSwitchCase="'tags'"
        [selectedTags]="query.tags || []"
        [tagSize]="((screenType == 'tablet-portrait') ? 'large' : 'small')"
        [venue]="venue"
        (selectionChange)="onTagsChange($event)"
      ></app-tag-filter>
    </div>
  </div>


  <ion-icon
    name="close"
    class="close-icon"
    (click)="onHideOverlay(false)"
  ></ion-icon>
  <!-- Show button -->
  <ion-button
    [class]="'button show-button ' + screenType"
    color="secondary"
    fill="solid"
    (click)="onHideOverlay(true)"
    >{{ translateService.instant("GENERAL.SHOW") }}</ion-button
  >
  <!-- Remove all button -->
  <ion-button
    [class]="'button remove-all-button ' + screenType"
    color="secondary"
    fill="solid"
    (click)="onRemoveAllFilters()"
    >{{ translateService.instant("GENERAL.REMOVE_ALL") }}</ion-button
  >
</div>
