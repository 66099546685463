import { VenueService } from './../../services/venue/venue.service';
import { ScreenVersionListener } from './../../models/screen-version-listener';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdleTrackerService } from '../../services/idle-tracker/idle-tracker.service';

@Component({
  selector: 'app-venue-banner',
  templateUrl: './venue-banner.component.html',
  styleUrls: ['./venue-banner.component.scss']
})
export class VenueBannerComponent extends ScreenVersionListener {
  @Input()
  public type: 'tab' | 'large' | 'small' = 'small';

  public showWelcomePage: boolean;

  public venue: Venue;

  public constructor(
    private venueService: VenueService,
    public translateService: TranslateService,
    public idleTrackerService: IdleTrackerService,
  ) {
    super();
    this.venue = this.venueService.getSelectedVenue(true);
    this.showWelcomePage = this.venue.shouldWelcomePageBeShown();
  }

  public onHome(): void {
    this.idleTrackerService.executeHome(false);
  }
}
