<div [class]="'wrapper-' + screenType">
  <div
    class="selected-wrapper"
    (click)="onShowDropdown()"
    *ngIf="!showSelector"
  >
    <div class="selected-value-text">
      <span>{{ getSelectedText() }}
      &nbsp;
      <ion-icon name="caret-down" class="icon-dropdown"></ion-icon></span>
    </div>
  </div>

  <div class="options-wrapper" *ngIf="showSelector">
    <ion-row
      *ngFor="let option of options; let i = index"
      [ngClass]="getOptionClasses(option)"
      (click)="onSelect(i)"
      >{{
        translateService.instant(option.translationKey)
          .replace("- ", "")
      }}</ion-row
    >
  </div>
</div>
