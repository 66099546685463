<div class="wrapper">
  <div class="text-div">
    <span class="searchinstructiontext" *ngIf="!hasFilters">
      <ion-icon name="information-circle-outline" class="info-icon"></ion-icon>
      {{
        translateService.instant(
          "CHARACTERISTICS_SEARCH.SEARCH_ACCORDING_TO_" + type.toUpperCase()
        )
      }}
    </span>
    <span [class]="'nrresults-' + screenType" *ngIf="hasFilters">{{ nrOfBeers }}</span>
    <span [class]="'foundtext-' + screenType" *ngIf="hasFilters && nrOfBeers !== 1">{{
      translateService.instant("GENERAL.BEERS_FOUND")
    }}</span>
    <span [class]="'foundtext-' + screenType" *ngIf="hasFilters && nrOfBeers === 1">{{
      translateService.instant("GENERAL.BEER_FOUND")
    }}</span>
  </div>

  <ion-button
    [class]="'show-button-' + screenType"
    color="secondary"
    fill="solid"
    *ngIf="hasFilters && nrOfBeers > 0"
    (click)="onShow()"
    >{{ translateService.instant("GENERAL.SHOW") }}</ion-button
  >
</div>
