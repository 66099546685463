<ion-grid class="wrapper">
  <!-- Close button -->
  <ion-icon name="close" class="close-icon" (click)="onCancel()"></ion-icon>

  <!-- Progress bar with description -->
  <ion-row class="title">{{
    translateService.instant("ADMIN_SETTINGS.GENERAL")
  }}</ion-row>

  <div *ngFor="let inputField of generalInputFields">
    <ion-row class="input-row">
    <ion-icon
      [name]="inputField.icon"
      class="label-icon"
      *ngIf="inputField.key !== 'country'"
    ></ion-icon>
    <ion-icon
      class="label-icon"
      *ngIf="inputField.key === 'country' && formVenue.getAddress().getCountry()"
      [src]="
        'assets/svgs/country/' + formVenue.getAddress().getCountry()!.toLowerCase() + '.svg'
      "
    ></ion-icon>
    <span class="prefix" *ngIf="inputField.prefix">{{
      inputField.prefix
    }}</span>
    <ion-input
      [maxlength]="inputField.maxCharacters"
      [debounce]="200"
      (ionChange)="onInput(inputField, $any($event))"
      class="ion-no-padding"
      style="padding-top:0;"
      [placeholder]="
        translateService.instant(
          'ADMIN_SETTINGS.' + inputField.key.toUpperCase()
        )
      "
      [type]="inputField.type ? inputField.type : 'text'"
      value="{{ inputField.getter() }}"
      [required]="inputField.required"
      formControlName={{inputField.formControlName}}
      *ngIf="inputField.type !== 'dropdown'"
    ></ion-input>
    <app-popover-select
      *ngIf="inputField.type === 'dropdown'"
      [options]="countryCodeOptions"
      [selected]="initialCountryCodeOption"
      [sortAccordingToTranslation]="true"
      (selectionChange)="handleInput(inputField, $event)"
    >
    </app-popover-select>
  </ion-row>
  <ion-row *ngIf="generalSettingsDataFormGroup.get(inputField.formControlName)?.valid === false" class="error-row">
      <div *ngFor="let key of getErrorTranslationKeys(inputField.formControlName)">
          {{ translateService.instant(key) }}
      </div>
  </ion-row>
</div>
<div>
  
</div>
<div>
  <!-- Cancel -->
  <ion-button
    color="secondary"
    fill="outline"
    class="ion-float-right save-button"
    (click)="onCancel()"
    >{{translateService.instant("GENERAL.CANCEL")}}
  </ion-button>
  <!-- Save -->
  <ion-button
    *ngIf="this.formVenue.isDirty() && this.generalSettingsDataFormGroup.valid"
    color="secondary"
    fill="solid"
    class="ion-float-right save-button"
    (click)="onSave()"
    >{{translateService.instant("EDIT_BEER.SAVE")}}
  </ion-button>
</div>

</ion-grid>