<div class="similarbeer-content">
  <!-- similar beer -->
  <ion-row
    class="similarbeer-row"
    *ngFor="let similarBeer of similarBeers"
    (click)="onCardClick(similarBeer)"
  >
    <ion-col size="11.2" class="ion-no-padding">
      <app-beer-info
        class="beer-info"
        [beer]="similarBeer.getBeer()"
        [showEssentialsOnly]="'description'"
      ></app-beer-info>
    </ion-col>
    <ion-col size="0.8" class="ion-no-padding">
      <ion-icon name="arrow-forward" class="show-more-icon"></ion-icon>
    </ion-col>
  </ion-row>
  <!-- text if no similar beers -->
  <ion-row
    class="no-similarbeers"
    *ngIf="similarBeers.size === 0"
  >
    {{ translateService.instant("CARD.NO_SIMILAR_BEERS_AVAILABLE_IN") + " " }}
    <b>{{ venueName }}</b>
  </ion-row>
</div>
