<div class="info-text" *ngIf="canEdit && floorPlan">
  {{ translateService.instant("EDIT_BEER.CLICK_ON_MAP") }}
</div>
<div class="info-text" *ngIf="canEdit && !hasPlan">
  {{ translateService.instant("EDIT_BEER.ADD_LOCATION_TIP") }}
</div>
<div #wrapper class="wrapper" (click)="onImageClick($event)">
  <img #plan
    [src]="floorPlan"
    [ngClass]="'floorplan' + (floorPlan ? '' : ' hidden')"
  />
  <ion-icon
    name="location"
    [ngClass]="'locator-pin' + (showPin ? '' : ' hidden')"
    [ngStyle]="{
      left: x + 'px',
      top: y + 'px'
    }"></ion-icon>
</div>
