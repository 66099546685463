import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Selector } from '../../models/selector';
import { ScreenVersionListener } from '../../models/screen-version-listener';
import { Option } from '../popover-select/popover-select.component';

@Component({
  selector: 'app-hexagon',
  templateUrl: './hexagon.component.html',
  styleUrls: ['./hexagon.component.scss']
})
export class HexagonComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public content: Selector | null = null;

  @Input()
  public editMode = false;

  @Input()
  public options: Option<Selector>[] = [];

  @Input()
  public selectable = true;

  @Input()
  public selected = false;

  @Output()
  private selectionChange = new EventEmitter<boolean>();

  @Output()
  private contentChange = new EventEmitter<Selector>();


  public icon: string | null = null;
  public color = 'white';
  public textColor = 'black';
  public label: string | null = null;
  public words: string[] = [];
  public gradientColor = 'white';
  public useGradient = false;

  public showPopover = false;

  public constructor(
    public translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.processContent();

    this.subscribe(this.translateService.onLangChange, () => {
      this.processContent();
    });
  }

  public ngAfterViewInit(): void {
    this.processContent();
  }

  public onEdit(newSelector: Selector | null): void {
    if (newSelector === null) {
      throw new Error('Cannot deselect!');
    }
    this.showPopover = false;
    this.content = newSelector;
    this.processContent();
    this.contentChange.emit(newSelector);
  }

  public onClick(): void {
    if (this.editMode) {
      this.showPopover = true;

      setTimeout(() => {
        document.onclick = () => {
          this.showPopover = false;
          document.onclick = () => {};
        };
      }, 100);
    } else if (this.selectable) {
      this.selected = !this.selected;
      this.selectionChange.emit(this.selected);
    }
  }

  public processContent(): void {
    if (this.content) {
      this.icon = this.content.getIconName();
      this.color = this.content.getColor();
      this.gradientColor = this.content.getGradientColor();
      this.textColor = this.content.getTextColor();
      this.useGradient = this.content.shouldGradientBeUsed();

      const transKey = this.content.getTranslationKey();
      if (transKey) {
        this.label = this.translateService.instant(transKey) as string;
        this.words = this.label.split(/( +)/).map((e) => e.trim()).filter((e) => e.length > 0);
      } else {
        this.label = null;
        this.words = [];
      }
    } else {
      // no content found (typical forhexagon in the middle)
      this.icon = 'choose';
      this.color = 'white';
      this.gradientColor = 'white';
      this.useGradient = false;
      this.label = null;
      this.words = [];
    }
  }
}
