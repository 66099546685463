import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checkbox-icon',
  templateUrl: './checkbox-icon.component.html',
  styleUrls: ['./checkbox-icon.component.scss']
})
export class CheckboxIconComponent {
  @Input()
  public type!: string;

  @Input()
  public checked = false;

  @Output()
  public checkChange = new EventEmitter<boolean>();

  public constructor(
    public translateService: TranslateService
  ) {}

  public onCheck(): void {
    this.checked = !this.checked;
    this.checkChange.emit(this.checked);
  }
}
