import { UploadService } from './../../services/upload/upload.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger/logger.service';


@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent {

  @Input()
  public imageUrl: string | null = null;

  @Input()
  public uploadName!: string;

  @Input()
  public labelToShow!: string;

  @Input()
  public recommendedSize!: string;

  @Output()
  public fileChange = new EventEmitter<string | null>();

  public imageData: string | null = null;

  public isLoading = false;

  public constructor(
    public translateService: TranslateService,
    private logger: LoggerService,
    private uploadService: UploadService
  ) {
  }

  public onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.logger.debug('file-input', 'file selected: input = ' + input);
    if (input.files && input.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        const prev = this.imageData;
        this.imageData = reader.result as string;
        this.isLoading = true;
        this.uploadService.uploadImage(this.uploadName, this.imageData).then((url: string) => {
          this.fileChange.emit(url);
        })
        .catch(() => {
          this.logger.warn('file-input', 'uploading failed');
          this.imageData = prev;
        })
        .finally(() => {
          this.isLoading = false;
        });
      };
      this.logger.debug('file-input', 'read data: imageData = ' + input.files[0]);
      reader.readAsDataURL(input.files[0]);
    }

    // set value of inputfield to empty to avoid missing re-upload of same file
    input.value = '';
  }

  public onDelete(): void {
    this.imageData = null;
    this.imageUrl = null;
    this.fileChange.emit(null);
  }
}
