import { ModelTable } from '../model-table';
import { Brewery } from './brewery';
import { Cleaner } from '../../cleaner';
import { Address } from '../../address';

/**
 * An abstract {@link ModelTable} of {@link Brewery | Breweries} responsible for reading instances.
 */
export abstract class BreweryTable extends ModelTable<Brewery> {
  /** @inheritDoc */
  public createInstance(): Brewery {
    return new Brewery(this);
  }
  /** @throws Serialization of breweries is not supported yet */
  public serializeInstance(instance: Brewery): any {
    throw new Error('Serialization of breweries is not supported yet');
  }
  /** @inheritDoc */
  public applyRawData(data: any, instance: Brewery): void {
    instance.setId(Cleaner.parseId(data.id));
    instance.setCreationTime(Cleaner.parseDate(data.creationTs));
    instance.setLastUpdate(Cleaner.parseDate(data.updateTs));
    Cleaner.safeApply(instance, instance.setName, data.name);
    Cleaner.safeApply(instance, instance.setLogoUrl, data.logo);

    const address = instance.getAddress();
    Cleaner.safeApply(address, address.setCity, data.city);
    Cleaner.safeApply(address, address.setCountry, data.country);
  }
}
