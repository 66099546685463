import { BaseAromas, BaseAromaKey } from './../../models/persistency/persistent-models/analysis';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RefdataService } from '../../services/refdata/refdata.service';
import { UserFeedbackService } from '../../services/user-feedback/user-feedback.service';

@Component({
  selector: 'app-hive',
  templateUrl: './hive.component.html',
  styleUrls: ['./hive.component.scss']
})
export class HiveComponent implements OnInit {
  @Input()
  public hive!: BaseAromas;

  @Input()
  public color: number | null = null;

  public colorHex!: string;

  public widths = [100, 255, 410, 565, 720];
  public heights = [86, 220.5, 355, 489.5, 624];
  public mid = { x: 550, y: 420 };
  public labels: {
    name: BaseAromaKey,
    offsetX: number,
    offsetY: number,
    tOffsetX: number,
    tOffsetY: number,
    left: number,
    top: number,
    anchor: string
  }[] = [
    {
      name: 'malty',
      offsetX: -300,
      offsetY: -420,
      tOffsetX: -235,
      tOffsetY: -270,
      left: 22,
      top: -2,
      anchor: 'end'
    },
    {
      name: 'sweet',
      offsetX: 190,
      offsetY: -420,
      tOffsetX: 230,
      tOffsetY: -270,
      left: 67,
      top: -2,
      anchor: 'begin'
    },
    {
      name: 'sour',
      offsetX: 330,
      offsetY: -150,
      tOffsetX: 380,
      tOffsetY: 0,
      left: 81,
      top: 32,
      anchor: 'begin'
    },
    {
      name: 'bitter',
      offsetX: 190,
      offsetY: 275,
      tOffsetX: 240,
      tOffsetY: 250,
      left: 68,
      top: 84,
      anchor: 'begin'
    },
    {
      name: 'hoppy',
      offsetX: -320,
      offsetY: 270,
      tOffsetX: -240,
      tOffsetY: 250,
      left: 18,
      top: 84,
      anchor: 'end'
    },
    {
      name: 'fruity',
      offsetX: -480,
      offsetY: -150,
      tOffsetX: -380,
      tOffsetY: 0,
      left: 6,
      top: 32,
      anchor: 'end'
    }
  ];

  constructor(
    public translateService: TranslateService,
    public refdataService: RefdataService,
    public userFeedbackService: UserFeedbackService
  ) {}

  public ngOnInit(): void {
    this.colorHex =
      this.color !== null
        ? this.refdataService.getColorById(this.color)
        : '#ffffff';
  }

  public getPolygon(ring: number): string {
    return (
      '' +
      (this.mid.x + this.widths[ring] / 2) +
      ',' +
      this.mid.y +
      ' ' +
      (this.mid.x + this.widths[ring] / 4) +
      ',' +
      (this.mid.y + this.heights[ring] / 2) +
      ' ' +
      (this.mid.x - this.widths[ring] / 4) +
      ',' +
      (this.mid.y + this.heights[ring] / 2) +
      ' ' +
      (this.mid.x - this.widths[ring] / 2) +
      ',' +
      this.mid.y +
      ' ' +
      (this.mid.x - this.widths[ring] / 4) +
      ',' +
      (this.mid.y - this.heights[ring] / 2) +
      ' ' +
      (this.mid.x + this.widths[ring] / 4) +
      ',' +
      (this.mid.y - this.heights[ring] / 2) +
      ' '
    );
  }

  public getValuePolygon(): string {
    const sour = this.hive.sour ? Math.round(this.hive.sour) : 0;
    const bitter = this.hive.bitter ? Math.round(this.hive.bitter) : 0;
    const hoppy = this.hive.hoppy ? Math.round(this.hive.hoppy) : 0;
    const fruity = this.hive.fruity ? Math.round(this.hive.fruity) : 0;
    const malty = this.hive.malty ? Math.round(this.hive.malty) : 0;
    const sweet = this.hive.sweet ? Math.round(this.hive.sweet) : 0;
    return (
      '' +
      (this.mid.x + this.widths[sour] / 2) +
      ',' +
      this.mid.y +
      ' ' +
      (this.mid.x + this.widths[bitter] / 4) +
      ',' +
      (this.mid.y + this.heights[bitter] / 2) +
      ' ' +
      (this.mid.x - this.widths[hoppy] / 4) +
      ',' +
      (this.mid.y + this.heights[hoppy] / 2) +
      ' ' +
      (this.mid.x - this.widths[fruity] / 2) +
      ',' +
      this.mid.y +
      ' ' +
      (this.mid.x - this.widths[malty] / 4) +
      ',' +
      (this.mid.y - this.heights[malty] / 2) +
      ' ' +
      (this.mid.x + this.widths[sweet] / 4) +
      ',' +
      (this.mid.y - this.heights[sweet] / 2) +
      ' '
    );
  }

  public getCrossLineX(line: number): number {
    switch (line) {
      case 0:
        return this.mid.x + this.widths[4] / 2;
      case 1:
      case 5:
        return this.mid.x + this.widths[4] / 4;
      case 2:
      case 4:
        return this.mid.x - this.widths[4] / 4;
      case 3:
        return this.mid.x - this.widths[4] / 2;
      default:
        return this.mid.x;
    }
  }

  public getCrossLineY(line: number): number {
    switch (line) {
      case 0:
      case 3:
        return this.mid.y;
      case 1:
      case 2:
        return this.mid.y + this.heights[4] / 2;
      case 4:
      case 5:
        return this.mid.y - this.heights[4] / 2;
      default:
        return this.mid.y;
    }
  }

  public async presentToast(key: string): Promise<void> {
    await this.userFeedbackService.giveTextualFeedback('AROMAS', key);
  }
}
