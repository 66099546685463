import { ModelForm } from './../../models/persistency/model-form';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger/logger.service';

@Component({
  selector: 'app-admin-settings-about',
  templateUrl: './admin-settings-about.component.html',
  styleUrls: ['./admin-settings-about.component.scss'],
})

export class AdminSettingsAboutComponent implements OnInit {
  @Input()
  public venue!: Venue;

  @Output()
  public close = new EventEmitter<void>();

  private venueForm!: ModelForm<Venue>;
  public formVenue!: Venue;

  public constructor(
    public translateService: TranslateService,
    private logger: LoggerService
  ) {
    this.logger.info('admin-settings-about.component', 'constructor called');
  }

  public ngOnInit(): void {
    this.logger.info('admin-settings-about.component', 'ngOnInit called');

    this.venueForm = this.venue.createForm();
    this.formVenue = this.venueForm.formInstance;
  }

  public async onSave(): Promise<void> {
    this.logger.info('admin-settings-about.component', 'onSave called');

    this.venueForm.applyForm();
    await this.venue.save().then(() => {
      this.closeComponent();
    });
  }

  public onCancel(): void {
    this.closeComponent();
  }
  public closeComponent(): void {
    this.close.emit();
  }
}
