<!-- beer cards -->
<ion-list *ngIf="!isOrder">
  <app-beer-card
    *ngFor="let beer of loadedBeers"
    [beer]="beer"
    (cardClick)="onCardClick(beer)"
  ></app-beer-card>
</ion-list>

<!-- order cards -->
<ion-list *ngIf="isOrder">
  <app-order-card
    *ngFor="let vb of loadedVenueBeers"
    [venueBeer]="vb"
    (cardClick)="onCardClick(vb.getBeer())"
    (orderChange)="onOrderChange($event)"
  ></app-order-card>
</ion-list>

<div
  class="no-beer-cards"
  *ngIf="(!loadedBeers || loadedBeers.length === 0) && isOrder"
>
  <ion-icon name="information-circle-outline" class="yellow-icon"></ion-icon>
  {{ translateService.instant("BEER_RESULTS.NO_ORDER_PART1") + " " }}
  <ion-icon name="add-circle" class="yellow-icon"></ion-icon>
  {{ " " + translateService.instant("BEER_RESULTS.NO_ORDER_PART2") }}
</div>

<div
  class="no-beer-cards"
  *ngIf="(!loadedBeers || loadedBeers.length === 0) && !isOrder"
>
  <ion-icon name="information-circle-outline" class="yellow-icon"></ion-icon>
  {{ translateService.instant("BEER_RESULTS.NO_RESULTS") }}
</div>

<ion-infinite-scroll
  class="infinite-scroll"
  threshold="100px"
  (ionInfinite)="loadBeers($any($event))"
>
  <ion-infinite-scroll-content
    loadingSpinner="bubbles"
    [loadingText]="translateService.instant('GENERAL.LOADING')"
  >
  </ion-infinite-scroll-content>
</ion-infinite-scroll>
