import { ScreenVersionListener } from './../../models/screen-version-listener';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { InputChangeEventDetail } from '@ionic/core/dist/types/components/input/input-interface';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ transform: 'translateY(-50%) scale(1, 0)' }),
        animate(100, style({ transform: 'translateY(0%) scale(1, 1)' }))
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(50, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SearchbarComponent extends ScreenVersionListener {
  @ViewChild('bar', { read: ElementRef })
  private searchbarElementRef!: ElementRef;

  private searchbar: HTMLInputElement | null = null;

  public searchbarFocused = false;

  @Input()
  public inHeader = false;

  @Output()
  public searchInput = new EventEmitter<string>();

  @Output()
  public search = new EventEmitter<string>();

  @Output()
  public focus = new EventEmitter<boolean>();

  public constructor(
    public translateService: TranslateService,
  ) {
    super();
  }

  ngAfterViewInit() {
//    console.log('searchbar focus check: ngAfterViewInit called');
    this.searchbar = this.searchbarElementRef.nativeElement;
  }
  private setSearchbarFocused(focused: boolean, origin: string): void {
//    console.log(origin + ' setting focus, old value = ' + this.searchbarFocused + ', new value = ' + focused);
    this.searchbarFocused = focused;
  }
  private checkFocus(old: boolean): void {
    if (old !== this.searchbarFocused) {
//      console.log('emitting focus, old = ' + old + ', this.searchbarFocused = ' + this.searchbarFocused);
      this.focus.emit(this.searchbarFocused);
    }
    else {
//      console.log('NOT emitting focus, old = ' + old + ', this.searchbarFocused = ' + this.searchbarFocused);
    }
  }
  public onFocusInput(): void {
    const old = this.searchbarFocused;
    this.setSearchbarFocused(true, 'onFocusInput');
    this.checkFocus(old);
    setTimeout(() => {
//      console.log('setting focus, searchbar = ' + this.searchbar);
      this.searchbar?.focus({ preventScroll: false });
    },150);
  }

  public onBlurInput(): void {
    const old = this.searchbarFocused;
    this.setSearchbarFocused(!!this.searchbar?.value, 'onBlurInput');
    this.checkFocus(old);
  }

  public onSearchInput(event: CustomEvent<InputChangeEventDetail>): void {
    this.searchInput.emit(event.detail.value || '');
  }

  public onBackButtonClick(): void {
    this.clearInput();

    const old = this.searchbarFocused;
    this.setSearchbarFocused(false, 'onBackButtonclick');
    this.checkFocus(old);
  }

  public onSearch(): void {
    this.search.emit(this.searchbar?.value);
  }

  private clearInput(): void {
    if (this.searchbar) this.searchbar.value = '';

    this.searchInput.emit('');
  }
}
