import { TranslateService } from '@ngx-translate/core';
import { UserFeedbackService } from './services/user-feedback/user-feedback.service';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { VenueService } from './services/venue/venue.service';
import { Component } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TrackingService } from './services/tracking/tracking.service';
import { LoggerService } from './services/logger/logger.service';
import { Venue } from './models/persistency/persistent-models/venue';
import { Listener } from './models/listener';

import localeNlBe from '@angular/common/locales/nl-BE';
import localeNlBeExtra from '@angular/common/locales/extra/nl-BE';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeFrBeExtra from '@angular/common/locales/extra/fr-BE';
import localeEnBe from '@angular/common/locales/en-BE';
import localeEnBeExtra from '@angular/common/locales/extra/en-BE';
import { registerLocaleData } from '@angular/common';
import { UrlService } from './services/url/url.service';

registerLocaleData(localeNlBe, 'nl-BE', localeNlBeExtra);
registerLocaleData(localeFrBe, 'fr-BE', localeFrBeExtra);
registerLocaleData(localeEnBe, 'en-BE', localeEnBeExtra);

export enum ScreenType {
  desktop = 'desktop',
  tablet_portrait = 'tablet-portrait',
  mobile = 'mobile'
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent extends Listener {

  public static $screenType = new BehaviorSubject<ScreenType>(ScreenType.desktop);

  public screenType: ScreenType = ScreenType.desktop;

  // define these for use in html templates (the templates don't recognize the enum type)
  public screenTypeDesktop: ScreenType = ScreenType.desktop;
  public screenTypeTabletPortrait: ScreenType = ScreenType.tablet_portrait;
  public screenTypeMobile: ScreenType = ScreenType.mobile;

  public showLoading = false;
  private minimumLoadingTime = 0.1;
  private waitTimeout: NodeJS.Timeout | null = null;

  public venue: Venue | null = null;

  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public trackingService: TrackingService,
    public logger: LoggerService,
    private router: Router,
    private venueService: VenueService,
    public menuCtrl: MenuController,
    public translateService: TranslateService,
    private userFeedback: UserFeedbackService,
    public urlService: UrlService
  ) {
    super();
    // initialize app
    this.initializeApp();
    this.subscribe(this.router.events, (event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          if (this.waitTimeout === null) {
            this.waitTimeout = setTimeout(() => {
              this.userFeedback.startLoading();
              this.waitTimeout = null;
            }, this.minimumLoadingTime * 1000);
          }
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          if (this.waitTimeout !== null) {
            clearTimeout(this.waitTimeout);
            this.waitTimeout = null;
          } else {
            this.userFeedback.stopLoading();
          }
          break;
        }
        default: {
          break;
        }
      }
    });

    this.subscribe(this.userFeedback.$showLoading, (v) =>  { this.showLoading = v; });
    this.subscribe(this.venueService.$selectedVenue, (venue) => {
      this.venue = venue;
    });
  }

  private initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.trackResize();
      this.trackingService.startTracking();
      this.setWidescreenHeight();
    });
  }

  private determineScreenType(): ScreenType {
    let platformWidth: number = this.platform.width();
    let isWideLandscape: boolean = this.platform.isLandscape() && ((this.platform.width() / this.platform.height()) > 1.35);
    let isSmallDevice: boolean = false;
    if (platformWidth < 768) {
      isSmallDevice = true;
    }
    let result: ScreenType = ScreenType.desktop;
    if (isWideLandscape) {
      result = ScreenType.desktop;
    }
    else { // we are in portrait or a bit of landscape mode
      let isMobile: boolean = this.platform.is('mobile') || this.platform.is('mobileweb');
      let isTablet: boolean = this.platform.is('tablet');
      if (isMobile && isSmallDevice) {
        // we are in portrait on mobile
        result = ScreenType.mobile
      }
      else { // we are not mobile and we are in portrait mode
        if (this.platform.is('desktop')) {
          result = ScreenType.desktop;
        }
        else { // we are not mobile and we are in portrait mode and we are not desktop
          result = ScreenType.tablet_portrait;
        }
      }
    }
    console.log('determineScreenType - orientation = ' + (this.platform.isLandscape() ? 'landscape' : 'portrait'));
    console.log('determineScreenType - platform = ' + this.platform.platforms().toString() + ', width = ' + this.platform.width());
    console.log('determineScreenType - screenType = ' + result);
    return result;
  }
  private trackResize() {
    const updateVersion = () => {
      const newScreenType = this.determineScreenType();
      if (newScreenType != this.screenType) {
        this.screenType = newScreenType;
        AppComponent.$screenType.next(newScreenType);
      }

      this.setWidescreenHeight();
    };

    window.addEventListener('resize', updateVersion, false);
    if (window.screen && window.screen.orientation) { // on desktop
      window.screen.orientation.addEventListener('onchange', updateVersion, false);
    } else { // on mobile
      window.addEventListener('orientationchange', updateVersion, false);
    }

    updateVersion();

    // (optional) Android doesn't always fire orientationChange on 180 degree turns
    // setInterval(checkOrientation, 2000);
  }

  private setWidescreenHeight() {
    // Searchbar about 80px, soft buttons as well, so if number larger than 150, soft button most likely present
    let wideScreenHeight: number;

    switch(this.screenType) {
      case ScreenType.mobile:
      case ScreenType.tablet_portrait:
          const nonAvailableScreen = screen.height - this.platform.height();
          wideScreenHeight = this.platform.width() - nonAvailableScreen;
          break;
      case ScreenType.desktop:
      default:
        wideScreenHeight = this.platform.height();
        break;
      }

    const body: any = document.querySelectorAll('body');
    body.forEach((e: any) => {
      e.classList.add('widescreen-height');
      e.style = '--ion-widescreen-height:' + wideScreenHeight + 'px';
    });
  }

  closeMenu() {
    this.menuCtrl.close();
  }
}
