import { VenueService } from './../../services/venue/venue.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScreenVersionListener } from '../../models/screen-version-listener';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { TextareaChangeEventDetail } from '@ionic/core/dist/types/components/textarea/textarea-interface';
import { Option } from '../popover-select/popover-select.component';

interface Row {selectedOption: string; value: string; }

@Component({
  selector: 'app-language-text',
  templateUrl: './language-text.component.html',
  styleUrls: ['./language-text.component.scss']
})
export class LanguageTextComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public type!: string;

  @Input()
  public maxCharacters!: number;

  @Input()
  public textMap!: Map<string, string>;

  @Input()
  public nrOfRows = 1;

  @Input()
  public nrOfCols = 50;

  @Output()
  public change = new EventEmitter<[string, string | null]>();

  public options: Option<string>[] = [];
  public rows: Row[] = [];
  public textAreaRows = [];

  public venue: Venue;

  public constructor(
    public translateService: TranslateService,
    public venueService: VenueService
  ) {
    super();
    this.venue = this.venueService.getSelectedVenue(true);
  }

  public ngOnInit(): void {
    // initialize languages and rows
    for (const language of this.venue.getLanguages()) {
      this.options.push({ selectable: true, translationKey: 'LANGUAGE.' + language.toUpperCase(), object: language });
    }

    let addedRow = false;
    this.options.forEach((option) => {
      const language = option.object;
      if (this.textMap.has(language)) {
        this.addRow(language, this.textMap.get(language)!);
        addedRow = true;
        option.selectable = false;
      }
    });
    if (!addedRow) {
      const defaultOption = this.options.find((option) => option.object === this.venue.getDefaultLanguage()) || this.options[0];
      this.addRow(defaultOption.object, '');
    }
  }

  public onSelectLang(row: Row, selectedLanguage: string | null): void {
    if (selectedLanguage) {
      if (this.textMap.has(row.selectedOption)) {
        this.textMap.delete(row.selectedOption);
        this.change.emit([row.selectedOption, null]);
      }
      row.selectedOption = selectedLanguage;
      if (row.value) {
        this.textMap.set(selectedLanguage, row.value.trim());
        this.change.emit([selectedLanguage, row.value.trim()]);
      }
    }
  }

  public onAddRow(): void {
    const option = this.options.find((opt) => opt.selectable);
    if (option) {
      this.addRow(option.object, '');
    }
  }

  public onRemoveRow(row: Row): void {
    this.rows = this.rows.filter((r) => r !== row);
    this.textMap.delete(row.selectedOption);
    this.change.emit([row.selectedOption, null]);
  }

  public onTextChange(event: CustomEvent<TextareaChangeEventDetail>, row: Row): void {
    const input = event.detail.value || '';
    row.value = input;
    if (input !== undefined && input !== null) {
      this.textMap.set(row.selectedOption, input.trim());
      this.change.emit([row.selectedOption, input.trim()]);
    } else {
      this.textMap.delete(row.selectedOption);
      this.change.emit([row.selectedOption, null]);
    }
  }

  private addRow(lang: string, value: string) {
    this.rows.push({
      selectedOption: lang,
      value: value
    });
  }
}
