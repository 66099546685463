import { Serializable } from './serializable';
import { Brewery } from './persistency/persistent-models/brewery';
import { SorterState, Sorters, Sorter } from './sorter';
import { BeerTag } from './persistency/persistent-models/beer-tag';
import { Selector, AromaSelectors, ColorSelectors, OtherSelectors } from './selector';
import { Params } from '@angular/router';
import { Venue } from './persistency/persistent-models/venue';
import { BeerService } from '../services/beer/beer.service';
import { BreweryService } from '../services/brewery/brewery.service';
import { OrderService } from '../services/order/order.service';
import { Beer } from './persistency/persistent-models/beer';
import { VenueBeer } from './persistency/persistent-models/venue-beer';
import { SloppyString } from './sloppy-string';
import { Filter, AndFilter, OrFilter, VenueBeerFilter } from './filter';


/**
 * An interface representing the properties of a {@link BeerQuery}.
 *
 * See {@link BeerQuery} for more details.
 */
export interface IBeerQuery {
  search?: string | null;
  sort?: SorterState[];
  tags?: BeerTag[];
  color?: Selector[];
  aroma?: Selector[];
  other?: Selector[];
  brewery?: Brewery[];
  available?: boolean;
  order?: boolean;
}


type BeerQueryKey = keyof IBeerQuery;
/**
 * The default values of a {@link BeerQuery}.
 */
export const defaultQuery: Required<IBeerQuery> = {
  search: null,
  sort: [],
  tags: [],
  color: [],
  aroma: [],
  other: [],
  brewery: [],
  available: true,
  order: false
};
function isBeerQueryKey(key: string): key is BeerQueryKey {
  return key in defaultQuery;
}

/**
 * A class which can efficiently look up lists of beers conforming to specified conditions.
 */
export class BeerQuery implements IBeerQuery, Serializable {
  // Constants for sorting searched beers.
  public static readonly availableSortPriority = 1;
  public static readonly unavailableSortPriority = 0.5;
  public static readonly breweryMatchFactor = 0.5;

  /**
   * Search by beer and brewery name. The results are sorted based on how well they match.
   *
   * If this value is `null`, `undefined` or an empty string, it is ignored.
   */
  public search?: string | null;
  /**
   * A list of sorting algorithms to sort the results with. Algorithms with a higher index have a lower priority.
   * If provided, these sorting algorithms always take precedence over the sorting of a search query.
   *
   * If this value is `undefined` or an empty array, it is ignored.
   */
  public sort?: SorterState[];
  /**
   * Search by {@link BeerTag}s. The resulting beers must have all the provided tags.
   *
   * If this value is `undefined` or an empty array, it is ignored.
   */
  public tags?: BeerTag[];
  /**
   * Search by color. The resulting beers must have one of the provided colors.
   *
   * If this value is `undefined` or an empty array, it is ignored.
   */
  public color?: Selector[];
  /**
   * Search by aroma. The resulting beers must have all the provided aromas.
   *
   * If this value is `undefined` or an empty array, it is ignored.
   */
  public aroma?: Selector[];
  /**
   * Search by other properties. The resulting beers must have all the provided properties.
   *
   * If this value is `undefined` or an empty array, it is ignored.
   */
  public other?: Selector[];
  /**
   * Search for beers of specific breweries. The resulting beers must have their brewery included in this list.
   *
   * If this value is `undefined` or an empty array, it is ignored.
   */
  public brewery?: Brewery[];
  /**
   * Whether the resulting beers must be available or not. If `true`, the resulting beers must have venue beers,
   * otherwise all beers that meet the query are returned.
   *
   * If `undefined`, the default value as provided in the {@link defaultQuery} will be used.
   */
  public available?: boolean;
  /**
   * If `true`, the results will only include beers that have an order.
   *
   * If `undefined`, the default value as provided in the {@link defaultQuery} will be used.
   */
  public order?: boolean;

  /**
   * *Note:* do not use this constructor directly. Instead, use the {@link BeerQueryService} to create new queries.
   */
  public constructor(
    query: IBeerQuery,
    private sorters: Sorters,
    private aromaSelectors: AromaSelectors,
    private colorSelectors: ColorSelectors,
    private otherSelectors: OtherSelectors,
    private beerService: BeerService,
    private breweryService: BreweryService,
    private orderService: OrderService
  ) {
    for (const key of Object.keys(query)) {
      if (isBeerQueryKey(key)) {
        const value = query[key];
        if (Array.isArray(value)) {
          this[key] = [...value] as any;
        } else {
          this[key] = value as any;
        }
      }
    }
  }

  private static createFilter(q: Required<BeerQuery>): Filter | null {
    // Combine the tags, aroma, color and other queries into one filter.
    const subfilters: Filter[] = [];
    if (q.aroma.length > 0) {
      subfilters.push(q.aroma.length === 1 ? q.aroma[0] : new AndFilter(...q.aroma));
    }
    if (q.color.length > 0) {
      subfilters.push(q.color.length === 1 ? q.color[0] : new OrFilter(...q.color));
    }
    if (q.other.length > 0) {
      subfilters.push(q.other.length === 1 ? q.other[0] : new AndFilter(...q.other));
    }
    if (q.tags.length > 0) {
      const tagFilter = VenueBeerFilter.CreateVenueBeerFilter((venueBeer) => {
        return q.tags.every((t) => venueBeer.getBeerTags().has(t));
      });
      subfilters.push(tagFilter);
    }
    let filter: Filter | null = null;
    if (subfilters.length === 1) {
      filter = subfilters[0];
    } else if (subfilters.length > 1) {
      filter = new AndFilter(...subfilters);
    }

    return filter;
  }
  /**
   * Return a list of beers matching all provided conditions of this query.
   *
   * @param venue  The venue which is used for looking up venue beers.
   * @returns A list of beers matching all provided conditions of this query.
   */
  public getBeerList(venue: Venue): Beer[] {
    let q = this.normalize();
    // enforce ordering on availability
    q.sort.unshift(this.sorters.available.createState());

    // Apply all filters and sortings.
    if (q.available || q.tags.length > 0 || q.order || q.other.some((s) => s.filter instanceof VenueBeerFilter)) {
      // If one of the above conditions is met, we already know that the resulting beers must be venue beers, so for efficiency
      // we can start with only those as possible candidates.
      let vList = this.filterVenueBeers(q, venue, true);
      if (q.sort.length > 0) {
        vList = Sorter.sortVenueBeers(vList, q.sort);
      }
      return vList.map((venueBeer) => venueBeer.getBeer());
    } else {
      let bList = this.filterBeers(q, venue, true);
      if (q.sort.length > 0) {
        bList = Sorter.sortBeers(bList, q.sort);
      }
      return bList;
    }
  }
  /**
   * Return the number of beers that match this query.
   *
   * This can be calculated more efficiently than calculating the whole list as done in {@link getBeerList}.
   *
   * @param venue  The venue which is used for looking up venue beers.
   * @returns The number of beers that match this query.
   */
  public getMatchingCount(venue: Venue): number {
    const q = this.normalize();

    if (q.available || q.tags.length > 0 || q.order) {
      return this.filterVenueBeers(q, venue, false).length;
    } else {
      return this.filterBeers(q, venue, false).length;
    }
  }
  private filterVenueBeers(q: Required<BeerQuery>, venue: Venue, searchSort: boolean): VenueBeer[] {
    const filter = BeerQuery.createFilter(q);

    let vList: VenueBeer[];
    if (q.order) {
      vList = Array.from(this.orderService.getVenueBeersWithOrders());
    } else {
      vList = Array.from(venue.getVenueBeers());
    }
    if (q.brewery.length > 0) {
      vList = vList.filter((vb) => {
        const br = vb.getBeer().getBrewery();
        return br !== null && q.brewery.includes(br);
      });
    }
    if (filter) {
      vList = filter.filterVenueBeers(vList);
    }
    if (q.search) {
      if (searchSort) {
        const priority = [BeerQuery.availableSortPriority, BeerQuery.availableSortPriority * BeerQuery.breweryMatchFactor];
        vList = SloppyString.filterAndSortWithMultilabels(
          q.search,
          vList,
          (vBeer) => {
            const b = vBeer.getBeer();
            const br = b.getBrewery();
            return br ? [b.getName(), br.getName()] : [b.getName()];
          },
          (match, index, labels) => priority[index]);
      } else {
        vList = SloppyString.filterWithMultilabels(
          q.search,
          vList,
          (vBeer) => {
            const b = vBeer.getBeer();
            const br = b.getBrewery();
            return br ? [b.getName(), br.getName()] : [b.getName()];
          });
      }
    }
    return vList;
  }
  private filterBeers(q: Required<BeerQuery>, venue: Venue, searchSort: boolean): Beer[] {
    const filter = BeerQuery.createFilter(q);

    let bList: Beer[];
    if (q.brewery.length > 0) {
      bList = Array.from(this.beerService.findAll((beer) => {
        const br = beer.getBrewery();
        return br !== null && q.brewery.includes(br);
      }));
    } else {
      bList = Array.from(this.beerService.getInstances());
    }
    if (filter) {
      bList = filter.filterBeers(bList, venue);
    }
    if (q.search) {
      if (searchSort) {
        const availablePriority = [BeerQuery.availableSortPriority, BeerQuery.availableSortPriority * BeerQuery.breweryMatchFactor];
        const unavailablePriority = [BeerQuery.unavailableSortPriority, BeerQuery.unavailableSortPriority * BeerQuery.breweryMatchFactor];
        bList = SloppyString.filterAndSortWithMultilabels(
          q.search,
          bList,
          (beer) => {
            const br = beer.getBrewery();
            return br ? [beer.getName(), br.getName()] : [beer.getName()];
          },
          (match, index, labels) => match.isAvailable(venue) ? availablePriority[index] : unavailablePriority[index]);
      } else {
        bList = SloppyString.filterWithMultilabels(
          q.search,
          bList,
          (beer) => {
            const br = beer.getBrewery();
            return br ? [beer.getName(), br.getName()] : [beer.getName()];
          });
      }
    }
    return bList;
  }

  /**
   * Convert this query to query parameters.
   *
   * @param minimize  Whether this query should first be minimized, leaving out unnecessary properties.
   * @returns The query parameters representing this query.
   */
  public toQueryParams(minimize: boolean = false): Params {
    let query: BeerQuery = this;
    if (minimize) {
      query = this.minimize();
    }
    const params: Params = {};
    if (query.search) {
      params.search = query.search;
    }
    if (query.sort && query.sort.length > 0) {
      params.sort = query.sort.map((state) => state.descending ? `-${state.sorter.getLabelKey()}` : state.sorter.getLabelKey()).join(',');
    }
    if (query.tags && query.tags.length > 0) {
      params.tags = query.tags.map((tag) => tag.getId()).join(',');
    }
    if (query.aroma && query.aroma.length > 0) {
      params.aroma = query.aroma.map((s) => s.getLabelKey()).join(',');
    }
    if (query.color && query.color.length > 0) {
      params.color = query.color.map((s) => s.getLabelKey()).join(',');
    }
    if (query.other && query.other.length > 0) {
      params.other = query.other.map((s) => s.getLabelKey()).join(',');
    }
    if (query.brewery && query.brewery.length > 0) {
      params.brewery = query.brewery.map((b) => b.getId()).join(',');
    }
    if (query.available !== undefined) {
      params.available = query.available ? '1' : '0';
    }
    if (query.order !== undefined) {
      params.order = query.order ? '1' : '0';
    }
    return params;
  }

  /**
   * Return a beer query conforming to this one, but which has no undefined properties.
   *
   * @returns A beer query with no undefined properties.
   */
  public normalize(): Required<BeerQuery> {
    const q: Required<IBeerQuery> = {
      search: this.search || defaultQuery.search,
      sort: this.sort || defaultQuery.sort,
      tags: this.tags || defaultQuery.tags,
      aroma: this.aroma || defaultQuery.aroma,
      color: this.color || defaultQuery.color,
      other: this.other || defaultQuery.other,
      brewery: this.brewery || defaultQuery.brewery,
      available: typeof this.available === 'boolean' ? this.available : defaultQuery.available,
      order: typeof this.order === 'boolean' ? this.order : defaultQuery.order
    };
    return new BeerQuery(
      q,
      this.sorters, this.aromaSelectors, this.colorSelectors, this.otherSelectors,
      this.beerService, this.breweryService, this.orderService
    ) as Required<BeerQuery>;
  }
  /**
   * Return a beer query conforming to this one, but which has a minimal amount of defined properties.
   *
   * @returns A beer query with minimal amount of defined properties.
   */
  public minimize(): BeerQuery {
    const q: IBeerQuery = {};
    if (this.search !== undefined && this.search !== defaultQuery.search) {
      q.search = this.search;
    }
    // Array equals
    const eq = (a: any[], b: any[]) => a.length === b.length && a.every((v, i) => v === b[i]);
    // Set equals (e.g. equality without order)
    const seq = (a: any[], b: any[]) => a.length === b.length && a.every((v) => b.includes(v));
    if (this.sort && !eq(this.sort, defaultQuery.sort)) {
      q.sort = this.sort;
    }
    if (this.tags && !seq(this.tags, defaultQuery.tags)) {
      q.tags = this.tags;
    }
    if (this.aroma && !seq(this.aroma, defaultQuery.aroma)) {
      q.aroma = this.aroma;
    }
    if (this.color && !seq(this.color, defaultQuery.color)) {
      q.color = this.color;
    }
    if (this.other && !seq(this.other, defaultQuery.other)) {
      q.other = this.other;
    }
    if (this.brewery && !seq(this.brewery, defaultQuery.brewery)) {
      q.brewery = this.brewery;
    }
    if (this.available !== undefined && this.available !== defaultQuery.available) {
      q.available = this.available;
    }
    if (this.order !== undefined && this.order !== defaultQuery.order) {
      q.order = this.order;
    }
    return new BeerQuery(
      q,
      this.sorters, this.aromaSelectors, this.colorSelectors, this.otherSelectors,
      this.beerService, this.breweryService, this.orderService
    );
  }

  /**
   * @inheritDoc
   */
  public toJSON(): any {
    return this.toQueryParams();
  }
}
