import { VenueService } from './../../services/venue/venue.service';
import { ScreenVersionListener } from './../../models/screen-version-listener';
import { LoggerService } from './../../services/logger/logger.service';
import { UserFeedbackService } from './../../services/user-feedback/user-feedback.service';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { BeerServing, Casing } from './../../models/beer-serving';
import { Brewery } from '../../models/persistency/persistent-models/brewery';
import { Beer } from '../../models/persistency/persistent-models/beer';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { Unit } from '../../models/unit';
import { InputChangeEventDetail  } from '@ionic/core/dist/types/components/input/input-interface';
import { PreventNegativeInputMixin } from '../../models/prevent-negative-input-mixin';

@Component({
  selector: 'app-admin-beer-card',
  templateUrl: './admin-beer-card.component.html',
  styleUrls: ['./admin-beer-card.component.scss']
})
export class AdminBeerCardComponent extends PreventNegativeInputMixin(ScreenVersionListener) implements OnInit {
  @Input()
  public beer!: Beer;

  @Output()
  public venueBeerEdit = new EventEmitter<VenueBeer>();

  public venueBeer: VenueBeer | null = null;
  public brewery: Brewery | null = null;

  public checked = false;

  public editable = false;

  public serveMethods: Casing[] = [Casing.Bottle, Casing.Tap, Casing.Can];

  public servings: ReadonlyArray<BeerServing> | null = null;
  public currency: Unit;
  public volumeUnit: Unit;

  public autoSaveTimer: NodeJS.Timeout | null = null;

  public venue: Venue;

  constructor(
    public translateService: TranslateService,
    private userFeedback: UserFeedbackService,
    private logger: LoggerService,
    private venueService: VenueService
  ) {
    super();
    this.venue = this.venueService.getSelectedVenue(true);
    this.volumeUnit = this.venue.getDefaultVolumeUnit();
    this.currency = this.venue.getCurrency();
  }

  public ngOnInit(): void {
    // initialize beer info
    this.venueBeer = this.beer.getVenueBeer(this.venue);
    this.initialize();
  }

  private initialize(): void {
    // initialize beer info
    this.brewery = this.beer.getBrewery();

    // track venue beer updates
    this.checked = this.venueBeer ? true : false;
    this.editable = this.checked;
    this.servings = this.venueBeer?.getBeerServings() || null;
  }

  public onCheckCard(): void {
    this.checked = !this.checked;
    if (this.checked) {
      this.venue.venueBeerTable.createInstanceWithProfile(this.beer).then((vb) => {
        this.venueBeer = vb;
        this.initialize();
        this.venueBeer.save().then(() => {
          this.editable = this.checked;
        });
      });
    } else {
      this.venueBeer!.delete();
      this.venueBeer = null;
      this.editable = false;
    }
  }

  public onEditBeer(): void {
    if (this.venueBeer) {
      this.venueBeerEdit.next(this.venueBeer);
    }
  }

  public onSelectServeMethod(serving: BeerServing, casing: Casing): void {
    serving.setCasing(casing);
    this.startAutoSaveTimer();
  }

  public onRemoveServing(serving: BeerServing): void {
    this.venueBeer!.removeBeerServing(serving);

    this.startAutoSaveTimer();
  }

  public onAddServing(): void {
    this.venueBeer!.addBeerServing(BeerServing.createDefault(this.volumeUnit));

    this.startAutoSaveTimer();
  }

  public onNumberInput(event: CustomEvent<InputChangeEventDetail>, serving: BeerServing, type: string) {
    const value = event.detail.value ? parseFloat(event.detail.value) : null;
    switch (type) {
      case 'volume':
        if (value !== null) {
          serving.setVolume(value);
        }
        break;
      case 'price':
        serving.setPrice(value);
        break;
      case 'promoPrice':
        serving.setPromotionPrice(value);
        serving.setWhetherIsInPromotion(!!value);
        break;
    }

    this.startAutoSaveTimer();
  }

  private startAutoSaveTimer(): void {
    if (this.autoSaveTimer) {
      clearTimeout(this.autoSaveTimer);
    }
    this.autoSaveTimer = setTimeout(() => {
      if (this.venueBeer) {
        this.venueBeer.save().then(() => {
          this.userFeedback.giveTextualFeedback('SAVE_TOAST', 'BEER_SAVE_SUCCESS');
        }).catch((reason) => {
          this.logger.error('admin.service', 'failed to save beer for venue', reason);
          const beerName = this.beer!.getName();
          const baseSentence = this.translateService.instant(
            'SAVE_TOAST.FAILED_TO_ADD_BEER'
          );
          const undoneSentence = this.translateService.instant(
            'SAVE_TOAST.UNDID_CHANGES'
          );
          this.userFeedback.notifyWithInteraction(
            baseSentence + ' ' + beerName + '. ' // + undoneSentence
          );
        });
      }
      this.autoSaveTimer = null;
    }, 3000);
  }
}
