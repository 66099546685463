import { Hexaselector } from './../../models/selector';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { Analysis } from '../../models/persistency/persistent-models/analysis';
import { ScreenVersionListener } from '../../models/screen-version-listener';
import { Selector } from '../../models/selector';
import { Filter } from '../../models/filter';


@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public venueBeer!: VenueBeer;

  public analysis: Analysis | null = null;
  public aromas: Hexaselector | null = null;

  public constructor(
    public translateService: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.analysis = this.venueBeer.getAnalysis();

    this.aromas = this.getAromas();
  }

  private getAromas(): Hexaselector | null {
    const aromas: Selector[] = [];
    if (this.analysis) {
      for (const aroma of this.analysis.getAdditionalAromas()) {
        const binaryAromaSelector = new Selector(
          aroma,
          'CARD.AROMAS.' + aroma.toUpperCase(),
          aroma,
          Filter.MatchAll,
          'white',
          this.venueBeer.getVenue().getPrimaryColor());
        aromas.push(binaryAromaSelector);
        if (aromas.length === 6) {
          break;
        }
      }
      // add empty selectors to ensure nice completion with empty hexagons
      while (aromas.length < 6) {
        aromas.push(Selector.empty);
      }
      return aromas as unknown as Hexaselector;
    }
    return null;
  }
}
