import { TranslateService } from '@ngx-translate/core';
import { ModelTable } from '../model-table';
import { Beer } from './beer';
import { Cleaner } from '../../cleaner';
import { BreweryTable } from './brewery-table';

/**
 * An abstract {@link ModelTable} of {@link Beer}s responsible for reading instances.
 */
export abstract class BeerTable extends ModelTable<Beer> {
  constructor(private translateService: TranslateService,
              private breweryTable: BreweryTable) {
    super([breweryTable]);
  }

  /** @inheritDoc */
  public createInstance(): Beer {
    return new Beer(this.translateService, this, this.breweryTable);
  }
  /** @throws Serialization of beers is not supported yet */
  public serializeInstance(instance: Beer): any {
    throw new Error('Serialization of beers is not implemented yet');
  }
  /** @inheritDoc */
  public applyRawData(data: any, instance: Beer): void {
    instance.setId(Cleaner.parseId(data.id));
    instance.setCreationTime(Cleaner.parseDate(data.creationTs));
    instance.setLastUpdate(Cleaner.parseDate(data.updateTs));
    Cleaner.safeApply(instance, instance.setName, data.name);
    Cleaner.safeApply(instance, instance.setABV, data.abv);
    Cleaner.safeApply(instance, instance.setEBC, data.ebc);
    Cleaner.safeApply(instance, instance.setIBU, data.ibu);
    Cleaner.safeApply(instance, instance.setLogoUrl, data.logo);

    const breweryId = Cleaner.parseId(data.brewery_id);
    if (breweryId === null) {
      console.warn(`Beer ${data.name} has a null brewery.`);
    } else if (!this.breweryTable.has(breweryId)) {
      console.warn(`Brewery of ${data.name} not found: no brewery with id ${breweryId}.`);
    } else {
      instance.setBrewery(this.breweryTable.get(breweryId));
    }
  }
}
