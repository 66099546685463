import { weekdays, Weekday } from './../../models/weekday';
import { TimeRange } from './../../models/opening-time';
import { ModelForm } from './../../models/persistency/model-form';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { LoggerService } from '../../services/logger/logger.service';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { OpeningTimes } from '../../models/opening-time';
import { InputChangeEventDetail  } from '@ionic/core/dist/types/components/input/input-interface';


@Component({
  selector: 'app-admin-settings-openinghours',
  templateUrl: './admin-settings-openinghours.component.html',
  styleUrls: ['./admin-settings-openinghours.component.scss'],
})
export class AdminSettingsOpeninghoursComponent implements OnInit {
  @Input()
  private venue!: Venue;

  @Output()
  private close = new EventEmitter<void>();

  private venueForm!: ModelForm<Venue>;
  public formVenue!: Venue;

  public openinghours!: OpeningTimes;

  public days: typeof weekdays;

  constructor(
    public translateService: TranslateService,
    private logger: LoggerService,
    private alertController: AlertController
  ) {
    this.days = weekdays;
  }

  public ngOnInit(): void {
    this.venueForm = this.venue.createForm();
    this.formVenue = this.venueForm.formInstance;
    this.openinghours = this.formVenue.getOpeningTimes();
  }

  public onCancel(): void {
    if (this.formVenue.isDirty()) {
      this.presentAlertConfirm();
    } else {
      this.closeComponent();
    }
  }
  public closeComponent(): void {
    this.close.emit();
  }
  public openCloseChange(dayStr: Weekday): void {
    const day = this.openinghours.get(dayStr);
    day.setWhetherIsOpen(!day.isOpen());
  }
  public onSave(): void {
    this.venueForm.applyForm();
    this.venue.save().then(() => {
      this.closeComponent();
    });
  }
  public fromChange(event: CustomEvent<InputChangeEventDetail>, dayStr: Weekday, timeIndex: number): void {
    if (!event.detail.value) {
      const day = this.openinghours.get(dayStr);
      if (timeIndex === day.times.length - 1) {
        const time = day.times[timeIndex];
        day.removeTime(time);
      }
    } else {
      const value = event.detail.value.split(':');
      if (value.length === 2) {
        const h = parseInt(value[0], 10);
        const m = parseInt(value[1], 10);
        if (!isNaN(h) && !isNaN(m)) {
          const day = this.openinghours.get(dayStr);
          if (timeIndex >= day.times.length) {
            if (h !== 0 || m !== 0) {
              day.addTime(new TimeRange(h, m, 0, 0));
            }
          } else {
            const time = day.times[timeIndex];
            time.from.h = h;
            time.from.m = m;
          }
        }
      }
    }
  }
  public toChange(event: CustomEvent<InputChangeEventDetail>, dayStr: Weekday, timeIndex: number): void {
    if (!event.detail.value) {
      const day = this.openinghours.get(dayStr);
      if (timeIndex === day.times.length - 1) {
        const time = day.times[timeIndex];
        day.removeTime(time);
      }
    } else {
      const value = event.detail.value.split(':');
      if (value.length === 2) {
        const h = parseInt(value[0], 10);
        const m = parseInt(value[1], 10);
        if (!isNaN(h) && !isNaN(m)) {
          const day = this.openinghours.get(dayStr);
          if (timeIndex >= day.times.length) {
            if (h !== 0 || m !== 0) {
              day.addTime(new TimeRange(0, 0, h, m));
            }
          } else {
            const time = day.times[timeIndex];
            time.to.h = h;
            time.to.m = m;
            if (timeIndex === day.times.length - 1 && time.from.h === 0 && time.from.m === 0 && time.to.h === 0 && time.to.m === 0) {
              day.removeTime(time);
            }
          }
        }
      }
    }
  }

  public async presentAlertConfirm(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Pending changes',
      message: 'There are pending changes, close anyway?',
      buttons: [
        {
          text: 'Return',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Close anyway',
          handler: () => {
            this.closeComponent();
          }
        }
      ]
    });

    await alert.present();
  }
}
