import { VenueService } from './../../services/venue/venue.service';
import { BeerServing } from './../../models/beer-serving';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { Unit } from '../../models/unit';
import { Order } from '../../models/order';
import { ScreenVersionListener } from '../../models/screen-version-listener';

@Component({
  selector: 'app-order-row',
  templateUrl: './order-row.component.html',
  styleUrls: ['./order-row.component.scss']
})
export class OrderRowComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public order!: Order;

  @Output()
  public orderChange = new EventEmitter<Order>();

  public venueBeer!: VenueBeer;
  public serving!: BeerServing;

  public orderCount!: number;

  public currency: Unit;

  public price = '';
  public promoPrice = '';

  public constructor(
    private venueService: VenueService,
  ) {
    super();
    this.currency = this.venueService.getSelectedVenue(true).getCurrency();
  }

  public ngOnInit(): void {
    this.venueBeer = this.order.getVenueBeer();
    this.serving = this.order.getBeerServing();
    this.orderCount = this.order.getAmount();
    const p = this.serving.getPrice();
    if (p !== null) {
      this.price = this.currency.format(p);
    }
    const pp = this.serving.getPromotionPrice();
    if (pp !== null) {
      this.promoPrice = this.currency.format(pp);
    }
  }

  public editBeerOrder(value: number): void {
    if (this.orderCount > 0 || value > 0) {
      this.orderCount = Math.max(0, this.orderCount + value);
      this.order = this.order.addAmount(value);
      this.orderChange.emit(this.order);
    }
  }
}
