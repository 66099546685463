import { Brewery } from '../../models/persistency/persistent-models/brewery';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { Beer } from '../../models/persistency/persistent-models/beer';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserFeedbackService } from '../../services/user-feedback/user-feedback.service';
import { ScreenVersionListener } from '../../models/screen-version-listener';
import { BeerTagType } from '../../models/persistency/persistent-models/beer-tag';
import { VenueService } from '../../services/venue/venue.service';

@Component({
  selector: 'app-beer-info',
  templateUrl: './beer-info.component.html',
  styleUrls: ['./beer-info.component.scss'],
})
export class BeerInfoComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public beer!: Beer;

  public venueBeer: VenueBeer | null = null;

  @Input()
  public showEssentialsOnly?: string;

  public brewery: Brewery | null = null;
  public hasSimilarBeers = false;
  public foodTags: string[] = [];
  public otherTags: string[] = [];

  public name!: string;
  public logo!: string;
  public ABV: number | null = null;
  public ABVStr = '';
  public breweryName: string | null = null;
  public breweryCity: string | null = null;
  public breweryCountry: string | null = null;
  public breweryCountryLogo: string | null = null;
  public description!: string;
  public tagOffset!: string;
  public isLocal = false;
  public isTemporary = false;
  public isNew = false;
  public isPromo = false;

  public constructor(
    public translateService: TranslateService,
    public userFeedbackService: UserFeedbackService,
    private venueService: VenueService
  ) {
    super();
  }

  public ngOnInit(): void {
    const venue = this.venueService.getSelectedVenue(true);

    this.venueBeer = this.beer.getVenueBeer(venue);
    this.brewery = this.beer.getBrewery();

    this.name = this.beer.getName();
    this.logo = this.beer.getLogoUrlWithFallback();
    this.ABV = this.beer.getABV();
    this.ABVStr = this.ABV !== null ? this.getRoundedNumber(this.ABV) : '';
    if (this.brewery) {
      this.breweryName = this.brewery.getName();
      this.breweryCity = this.brewery.getAddress().getCity();
      this.breweryCountry = this.brewery.getAddress().getCountry();
      this.breweryCountryLogo = this.breweryCountry ? 'assets/svgs/country/' + this.breweryCountry.toLowerCase() + '.svg' : null;
    }
    this.description = this.beer.getDescription(venue, this.translateService.currentLang);

    if (this.venueBeer) {
      this.isLocal = this.venueBeer.isLocal();
      this.isTemporary = this.venueBeer.isTemporary();
      this.isNew = this.venueBeer.isNew();
      this.isPromo = this.venueBeer.isPromo();
    }

    this.tagOffset = 'calc(100% - ' +
      [this.isLocal, this.isTemporary, this.isNew, this.isPromo]
      .reduce((acc, b) => b ? acc + 1 : acc, 0) + ' * (var(--font-size-xxsmall) + 17px))';

    this.hasSimilarBeers = this.beer.hasSimilarVenueBeers(venue);

    this.processTags();
    this.subscribe(this.translateService.onLangChange, () => {
      this.processTags();
      this.description = this.beer.getDescription(venue, this.translateService.currentLang);
    });
  }

  private getRoundedNumber(n: number): string {
    return (Math.round(n * 10) / 10).toFixed(1);
  }

  public async presentToast(text: string, translate = false): Promise<void> {
    if (!this.showEssentialsOnly) {
      if (translate) {
        await this.userFeedbackService.giveTextualFeedback('OTHER_CHARACTERISTICS', text);
      } else {
        await this.userFeedbackService.giveTextualFeedback(text);
      }
    }
  }

  private processTags(): void {
    const lang = this.translateService.currentLang;
    this.foodTags = !this.venueBeer ? [] : Array.from(this.venueBeer.getBeerTagsForLanguage(lang))
      .filter((tag) => tag.getType() === BeerTagType.Food)
      .map((tag) => tag.getLabel(lang)!);
    this.otherTags = !this.venueBeer ? [] : Array.from(this.venueBeer.getBeerTagsForLanguage(lang))
      .filter((tag) => tag.getType() !== BeerTagType.Food)
      .map((tag) => tag.getLabel(lang)!);
  }
}
