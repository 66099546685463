import { Venue } from './../../models/persistency/persistent-models/venue';
import { BreweryService } from './../brewery/brewery.service';
import { OrderService } from './../order/order.service';
import { BeerService } from './../beer/beer.service';
import { AromaSelectors, ColorSelectors, OtherSelectors, Selector, SelectorType } from './../../models/selector';
import { BeerTag } from './../../models/persistency/persistent-models/beer-tag';
import { Sorters, SorterState } from './../../models/sorter';
import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { BeerQuery, IBeerQuery } from '../../models/beer-query';
import { Brewery } from '../../models/persistency/persistent-models/brewery';

/**
 * A factory service responsible for constructing {@link BeerQuery | beer queries}.
 */
@Injectable({
  providedIn: 'root'
})
export class BeerQueryService {

  public constructor(
    private sorters: Sorters,
    private aromaSelectors: AromaSelectors,
    private colorSelectors: ColorSelectors,
    private otherSelectors: OtherSelectors,
    private beerService: BeerService,
    private breweryService: BreweryService,
    private orderService: OrderService
  ) {
  }

  /**
   * Create a query with the given parameters.
   *
   * @param q  The parameters of the query.
   * @returns A beer query with the given parameters.
   */
  public create(q?: IBeerQuery): BeerQuery;
  /**
   * Create a query with the given parameters.
   *
   * @param q  The parameters of the query.
   * @returns A beer query with the given parameters.
   */
  public create(q: Required<IBeerQuery>): Required<BeerQuery>;
  public create(q: IBeerQuery = {}): BeerQuery | Required<BeerQuery> {
    return new BeerQuery(q,
      this.sorters, this.aromaSelectors, this.colorSelectors, this.otherSelectors,
      this.beerService, this.breweryService, this.orderService
    );
  }
  /**
   * Create a query from url parameters.
   *
   * @param params  The parameter map containing the url parameters.
   * @param venue  Beer tags will be loaded from this venue.
   * @returns A beer query with parameters taken from the given parameter map.
   */
  public fromParamMap(params: ParamMap, venue: Venue): BeerQuery {
    // Read the search query.
    const search = params.get('search');

    // Read the sort query.
    let sort = this.readSort(params.get('sort'));

    // Read the tags query.
    const tags = this.readTags(params.get('tags'), venue);

    // Read selectors.
    const aroma = this.readSelectors(params.get('aroma'), this.aromaSelectors);
    const color = this.readSelectors(params.get('color'), this.colorSelectors);
    const other = this.readSelectors(params.get('other'), this.otherSelectors);

    // Read breweries query.
    const brewery = this.readBreweries(params.get('brewery'));

    // Read availability query.
    const rawAvailable = params.get('available');
    const available = (rawAvailable === '0' || rawAvailable === '1') ? rawAvailable === '1' : undefined;

    // Read order query.
    const rawOrder = params.get('order');
    const order = (rawOrder === '0' || rawOrder === '1') ? rawOrder === '1' : undefined;

    // enforce a sort if none is given
    if (!sort || sort === null) {
      sort = this.readSort('alphabetic');
    } 

    return this.create({
      search: search,
      sort: sort,
      tags: tags,
      aroma: aroma,
      color: color,
      other: other,
      brewery: brewery,
      available: available,
      order: order
    });
  }

  private readSort(raw: string | null): SorterState[] | undefined {
    if (raw !== null) {
      const sorters: SorterState[] = [];
      raw.split(',').forEach((sortLabel) => {
        if (!sortLabel) {
          return;
        }
        const descending = sortLabel[0] === '-';
        if (descending) {
          sortLabel = sortLabel.slice(1);
        }
        const sorter = this.sorters.getSorterByLabelKey(sortLabel);
        if (!sorter) {
          return;
        }
        sorters.push({sorter: sorter, descending: descending});
      });
      return sorters;
    }
    return undefined;
  }
  private readTags(raw: string | null, venue: Venue): BeerTag[] | undefined {
    if (raw !== null) {
      const tags: BeerTag[] = [];
      raw.split(',').forEach((tagId) => {
        if (venue.beerTagTable.has(tagId)) {
          const tag = venue.beerTagTable.get(tagId);
          tags.push(tag);
        }
      });
      return tags;
    }
    return undefined;
  }
  private readSelectors(raw: string | null, type: SelectorType): Selector[] | undefined {
    if (raw !== null) {
      const selectors: Selector[] = [];
      raw.split(',').forEach((labelKey) => {
        const selector = type.getSelectorByLabelKey(labelKey);
        if (selector) {
          selectors.push(selector);
        }
      });
      return selectors;
    }
    return undefined;
  }
  private readBreweries(raw: string | null): Brewery[] | undefined {
    if (raw !== null) {
      const breweries: Brewery[] = [];
      raw.split(',').forEach((breweryId) => {
        if (this.breweryService.has(breweryId)) {
          const brewery = this.breweryService.get(breweryId);
          breweries.push(brewery);
        }
      });
      return breweries;
    }
    return undefined;
  }
}
