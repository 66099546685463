import { LoggerService } from './../logger/logger.service';
import { VenueService } from './../venue/venue.service';
import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { Buffer } from 'buffer';
import { environment } from '../../../environments/environment';

/**
 * A service responsible for uploading images.
 */
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public constructor(
    private venueService: VenueService,
    private logger: LoggerService
  ) {
  }

  /**
   * Upload an image to AWS.
   *
   * @param name  The name for the image.
   * @param data  The image data.
   * @returns A promise that resolves with an url pointing to the uploaded image when the upload is done.
   * @throws Cannot upload an image when no venue is selected.
   * @throws The image data has an invalid format (must start with 'data:').
   */
  public uploadImage(name: string, data: string): Promise<string> {
    const venueId = this.venueService.getSelectedVenue()?.getId();
    if (venueId === null || venueId === undefined) {
      throw new Error('Cannot upload an image when no venue is selected.');
    }
    if (!data.startsWith('data:')) {
      throw new Error('The image data has an invalid format (must start with \'data:\')');
    }
    const type = data.split(';')[0].split(':')[1];

    let extension = '.jpeg';
    if (type.includes('png')) {
      extension = '.png';
    }

    const buf = Buffer.from(
      data.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );
    return this.uploadFile(
      buf,
      name + extension,
      'venuedata/' + venueId + '/',
      type,
      true
    );
  }

  private uploadFile(data: Buffer, fileName: string, path: string, type: string, base64 = false): Promise<string> {
    if (path.length > 0 && !path.endsWith('/')) {
      path += '/';
    }
    // actually upload
    const bucket = new S3({
      accessKeyId: 'AKIA5DURJ6OS76T7TUGM',
      secretAccessKey: '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',
      region: 'eu-west-1'
    });

    const params: S3.PutObjectRequest = {
      Bucket: environment.awsBucket,
      Key: path + fileName,
      Body: data,
      ContentType: type,
    };

    if (base64) {
      params['ContentEncoding'] = 'base64';
    }

    return new Promise((resolve, reject) => {
      bucket.upload(params, (err: Error, response: S3.ManagedUpload.SendData) => {
        if (!err) {
          this.logger.debug(
            'upload.service.ts',
            'S3 upload to ' + path + fileName + ' succesfull'
          );

          resolve(response.Location);
        } else {
          this.logger.error(
            'upload.service.ts',
            'failed to upload data to ' + path + fileName,
            err
          );
          reject('upload failed!');
        }
      });
    });
  }
}
