<div class="wrapper">
  <ion-fab class="fab-wrapper">
    <ion-fab-button class="fab-share">
      <ion-icon src="assets/svgs/general/icon_share.svg" class="icon"></ion-icon>
    </ion-fab-button>

    <ion-fab-list side="start" class="fab-share-list">
      <div class="fab-share-button">
        <a [href]="fbUrl">
          <ion-icon
            name="logo-facebook"
            [ngClass]="
              'fab-share-icon' + (isDeskTop() ? '-widescreen' : '')
            "
          ></ion-icon>
        </a>
      </div>
      <div class="fab-share-button">
        <a [href]="twitterUrl">
          <ion-icon
            name="logo-twitter"
            [ngClass]="
              'fab-share-icon' + (isDeskTop() ? '-widescreen' : '')
            "
          ></ion-icon>
        </a>
      </div>
    </ion-fab-list>
  </ion-fab>
</div>
