import { VenueService } from './../../services/venue/venue.service';
import { UserFeedbackService } from './../../services/user-feedback/user-feedback.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { LoggerService } from '../../services/logger/logger.service';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { environment } from '../../../environments/environment';
import { Location } from '../../models/persistency/persistent-models/venue-beer';
import { ModelForm } from '../../models/persistency/model-form';
import { ScreenVersionListener } from '../../models/screen-version-listener';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { BeerTag } from '../../models/persistency/persistent-models/beer-tag';


@Component({
  selector: 'app-edit-beer',
  templateUrl: './edit-beer.component.html',
  styleUrls: ['./edit-beer.component.scss']
})
export class EditBeerComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public venueBeer!: VenueBeer;

  @Output()
  public closeEdit = new EventEmitter<void>();

  public name!: string;

  public pages = ['standard', 'description', 'image', 'tags', 'location'];
  public pageIndex = 0;

  public formVenueBeer!: ModelForm<VenueBeer>;
  public checkBoxes: {
    type: string,
    getter: (vb: VenueBeer) => boolean
  }[] = [
    {
      type: 'local',
      getter: (vb) => vb.isLocal()
    },
    {
      type: 'temporary',
      getter: (vb) => vb.isTemporary()
    },
    {
      type: 'new',
      getter: (vb) => vb.isNew()
    },
    {
      type: 'promo',
      getter: (vb) => vb.isPromo()
    }
  ];

  public mailto!: string;
  public showBeerDescriptionInput = true;

  public initiallySelectedTags: BeerTag[] = [];

  public venue: Venue;

  public constructor(
    public translateService: TranslateService,
    private logger: LoggerService,
    private alertController: AlertController,
    private userFeedbackService: UserFeedbackService,
    private venueService: VenueService
  ) {
    super();
    this.venue = this.venueService.getSelectedVenue(true);
  }

  public ngOnInit(): void {
    this.formVenueBeer = this.venueBeer.createForm();
    this.name = this.venueBeer.getBeer().getName();
    this.initiallySelectedTags = Array.from(this.venueBeer.getBeerTags());

    // mailto link, spaces replaced, leading and trailing spaces removed
    const subject = this.name.trim() + ' correctie';
    this.mailto =
      'mailto:' +
      environment.beerhiveSupportMail +
      '?subject=' +
      encodeURIComponent(subject);

    this.showBeerDescriptionInput = !this.venueBeer.autoGenerateDescription();
  }

  public makeFormDirty(): void {
    this.formVenueBeer.formInstance.makeDirty();
  }

  public onNavigate(value: number): void {
    let newIndex = this.pageIndex + value;
    if (newIndex < 0) {
      newIndex = 0;
    }
    if (newIndex > this.pages.length - 1) {
      newIndex = this.pages.length - 1;
    }
    this.pageIndex = newIndex;
  }

  public setPageIndex(index: number): void {
    this.onNavigate(index - this.pageIndex);
  }

  public async onCancel(): Promise<void> {
    if (this.formVenueBeer.formInstance.isDirty()) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('EDIT_BEER.SURE_CLOSE'),
        buttons: [
          {
            text: this.translateService.instant('GENERAL.NO'),
            role: 'cancel',
            handler: () => {}
          },
          {
            text: this.translateService.instant('GENERAL.YES'),
            handler: () => this.executeCancel()
          }
        ]
      });

      await alert.present();
    } else {
      this.executeCancel();
    }
  }

  public onPersonalBeerDescription(value: boolean): void {
    this.showBeerDescriptionInput = value;
    this.formVenueBeer.formInstance.setWhetherDescriptionShouldBeAutoGenerated(!value);
  }

  public onBottleImageUrlChange(url: string | null): void {
    this.formVenueBeer.formInstance.setBottleImageUrl(url);
  }

  public onBeerTagSelect(tags: BeerTag[]): void {
    this.formVenueBeer.formInstance.getBeerTags().forEach((tag) => {
      if (!tags.includes(tag)) {
        this.formVenueBeer.formInstance.removeBeerTag(tag);
      }
    });
    tags.forEach((tag) => {
      if (!this.formVenueBeer.formInstance.getBeerTags().has(tag)) {
        this.formVenueBeer.formInstance.addBeerTag(tag);
      }
    });
  }

  public onChangeLocation(location: Location): void {
    this.formVenueBeer.formInstance.setLocation(location);
  }

  public onSave(): void {
    this.formVenueBeer.applyForm();
    this.venueBeer.save().then(() => {
      this.closeEdit.emit();
    }).catch((reason) => {
      this.logger.error('admin.service', 'failed to save beer for venue', reason);
      const beerName = this.venueBeer.getBeer().getName();
      const baseSentence = this.translateService.instant(
        'SAVE_TOAST.FAILED_TO_ADD_BEER'
      );
      const undoneSentence = this.translateService.instant(
        'SAVE_TOAST.UNDID_CHANGES'
      );
      this.userFeedbackService.notifyWithInteraction(
        baseSentence + ' ' + beerName + '. ' // + undoneSentence
      );
    });
  }

  private executeCancel(): void {
    this.closeEdit.emit();
  }

  public setCheckboxData(key: string, value: boolean): void {
    switch (key) {
      case 'local':
        this.formVenueBeer.formInstance.setWhetherIsLocal(value);
        break;
      case 'temporary':
        this.formVenueBeer.formInstance.setWhetherIsTemporary(value);
        break;
      case 'new':
        this.formVenueBeer.formInstance.setWhetherIsNew(value);
        break;
      case 'promo':
        this.formVenueBeer.formInstance.setWhetherIsPromo(value);
        break;
      default:
        this.logger.warn('edit-beer', 'Unknown form key: ' + key);
        break;
    }
  }
}
