import { ModelForm } from './../../models/persistency/model-form';
import { LoggerService } from './../../services/logger/logger.service';
import { SloppyString } from '../../models/sloppy-string';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { BeerTag, BeerTagType } from '../../models/persistency/persistent-models/beer-tag';
import { InputChangeEventDetail } from '@ionic/core/dist/types/components/input/input-interface';
import { ScreenVersionListener } from '../../models/screen-version-listener';

@Component({
  selector: 'app-tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.scss']
})
export class TagFilterComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public tagSize: 'small' | 'normal' | 'large' = 'normal';

  @Input()
  public hideNonFood = false;

  @Input()
  public showAddTag = false;

  @Input()
  public selectedTags: BeerTag[] = [];

  @Input()
  public editMode = false;

  @Input()
  public showOtherLangLabel = false;

  @Input()
  public venue!: Venue;

  @Output()
  public selectionChange = new EventEmitter<BeerTag[]>();

  public tags: {selected: boolean, tag: BeerTag}[] = [];
  public searchFilter = '';
  public searchedTags: {selected: boolean, tag: BeerTag}[] = [];
  public currentLang!: string;

  public formTranslationKey = '';
  private originalTag: BeerTag | null = null;
  public tagForm: ModelForm<BeerTag> | null = null;
  public formTag: BeerTag | null = null;

  public tagTypeOptions = [
    {selectable: true, translationKey: 'EDIT_BEER.OTHER', object: BeerTagType.Other},
    {selectable: true, translationKey: 'EDIT_BEER.FOOD', object: BeerTagType.Food}
  ];
  public selectedOption = BeerTagType.Other;

  public constructor(
    public translateService: TranslateService,
    private logger: LoggerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.selectedTags = this.selectedTags || [];

    // keep an eye on the language and sort accordingly
    this.currentLang = this.translateService.currentLang;
    this.subscribe(this.translateService.onLangChange, () => {
      this.currentLang = this.translateService.currentLang;
      this.sortTags();
    });

    this.initTags();
  }

  public onTagTypeSelect(type: BeerTagType | null): void {
    if (type) {
      this.formTag?.setType(type);
    }
  }

  public onSearchInput(event: CustomEvent<InputChangeEventDetail>): void {
    this.searchFilter = event.detail.value || '';
    this.filterTags();
  }

  public selectTag(tag: {selected: boolean, tag: BeerTag}): void {
    // no selection possible in edit mode
    if (!this.editMode) {
      tag.selected = !tag.selected;
      if (tag.selected) {
        this.selectedTags.push(tag.tag);
      } else {
        this.selectedTags.splice(this.selectedTags.indexOf(tag.tag), 1);
      }
      this.selectionChange.emit(this.selectedTags);
    }
  }

  public onSave(): void {
    this.tagForm?.applyForm();
    this.originalTag?.save().then(() => {
      this.initTags();
    }).catch((err) => {
      this.logger.error('tag-filter', 'Failed to save beer tag', err);
    });
    this.tagForm = null;
  }

  public getTagSummary(): string {
    let result = '';
    for (const tag of this.searchedTags) {
      if (tag.selected) {
        result += tag.tag.getAnyLabel(this.currentLang) + ', ';
      }
    }
    if (result) {
      result =
        this.translateService.instant('GENERAL.SELECTED') +
        ': ' +
        result.substr(0, result.length - 2);
    } else {
      result = '\xa0';
    }
    return result;
  }

  public onShowTagForm(tag?: BeerTag): void {
    this.tagTypeOptions.forEach((o) => o.selectable = true);
    if (tag) {
      // edit a tag
      this.originalTag = tag;
      this.formTranslationKey = 'EDIT_BEER.EDITTAG';
    } else {
      // add a new tag
      this.originalTag = this.venue.beerTagTable.createInstance();
      this.originalTag.setType(BeerTagType.Other);
      this.formTranslationKey = 'EDIT_BEER.ADDTAG';
    }
    this.tagForm = this.originalTag.createForm();
    this.formTag = this.tagForm.formInstance;
    this.selectedOption = this.originalTag.getType();

    // clear the search filter
    this.searchFilter = '';
    this.filterTags();
  }

  public onRemoveTag(tag: BeerTag): void {
    tag.delete().then(() => {
      this.initTags();
    });
  }

  public onCancel(): void {
    this.tagForm = null;
  }

  private sortTags(): void {
    this.tags.sort((a, b) => a.tag.getAnyLabel(this.currentLang)!.localeCompare(b.tag.getAnyLabel(this.currentLang)!));
    this.filterTags();
  }

  private filterTags(): void {
    if (this.searchFilter) {
      this.searchedTags = SloppyString.filter(this.searchFilter, this.tags, (t) => t.tag.getAnyLabel(this.currentLang)!);
    } else {
      this.searchedTags = this.tags;
    }
  }

  private initTags(): void {
    let tags = Array.from(this.showOtherLangLabel ?
      this.venue.getBeerTags() :
      this.venue.getBeerTagsForLanguage(this.currentLang));
    if (this.hideNonFood) {
      tags = tags.filter((t) => t.getType() === BeerTagType.Food);
    }
    this.tags = tags.map((t) => ({selected: this.selectedTags.includes(t), tag: t}));
    this.sortTags();
  }
}
