  <ion-card [ngClass]="'card' + (isDeskTop() ? '-widescreen' : '') + (venueBeer ? ' selectable' : '')" (click)="onCardClick()">
    <!-- General beer information -->
    <ion-row>
      <ion-col size="11.2" class="ion-no-padding">
        <app-beer-info
          [beer]="beer"
          [showEssentialsOnly]="'description'"
        ></app-beer-info>
      </ion-col>
      <ion-col *ngIf="venueBeer" size="0.8" class="ion-no-padding">
        <!-- <img src="assets/svgs/country/be.svg" class="country-flag"/> -->
        <ion-icon name="arrow-forward" class="show-more-icon"></ion-icon>
      </ion-col>
    </ion-row>

    <app-similar-beers
      *ngIf="showSimilarBeers"
      [beer]="beer"
    ></app-similar-beers>
  </ion-card>