import { VenueService } from './../../services/venue/venue.service';
import { Beer } from '../../models/persistency/persistent-models/beer';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { ScreenVersionListener } from '../../models/screen-version-listener';

@Component({
  selector: 'app-beer-card',
  templateUrl: './beer-card.component.html',
  styleUrls: ['./beer-card.component.scss'],
})
export class BeerCardComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public beer!: Beer;

  @Output()
  public cardClick = new EventEmitter<void>();

  public venueBeer: VenueBeer | null = null;
  public hasSimilarBeers = false;

  public showSimilarBeers = false;

  public constructor(
    private venueService: VenueService
  ) {
    super();
  }

  public ngOnInit(): void {
    const venue = this.venueService.getSelectedVenue(true);

    // get the actual beer data
    this.venueBeer = this.beer.getVenueBeer(venue);

    // see if there are similarBeers
    this.hasSimilarBeers = this.beer.hasSimilarVenueBeers(venue);

    // show similar beers when no venueBeer
    this.showSimilarBeers = !this.venueBeer && this.hasSimilarBeers;
  }

  public onCardClick(): void {
    this.cardClick.emit();
  }
}
