/**
 * The type of a concrete class that constructs `T` objects.
 * This is typically used in the [mixin pattern](https://www.typescriptlang.org/docs/handbook/mixins.html).
 */
export type Constructor<T> = new (...args: any[]) => T;

/**
 * A type representing a tuple of the given type six times repeated.
 *
 * @typeParam T  The type to repeat six times.
 */
export type Hexa<T> = [T, T, T, T, T, T];

/**
 * A type guard that checks whether the given value is defined. This is typically used when filtering
 * out undefined values from an array.
 *
 * Usage example:
 * ```typescript
 * declare let listOfStrings: (string | null)[];
 *
 * let definedListOfStrings: string[] = listOfStrings.filter(notNull);
 *
 * // Note: the following will fail.
 * let anotherDefinedListOfStrings: string[] = listOfStrings.filter((value) => value !== null && value !== undefined);
 * ```
 */
export function notNull<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
}
