/**
 * A utility class for cleaning raw data.
 */
export class Cleaner {
    public static parseId(data: any): string | null {
        if (!data || !data.toString()) {
            return null;
        }
        return data.toString();
    }
    public static parseDate(data: any): Date | null {
        if (!data || typeof data !== 'string') {
            return null;
        }
        data = data.replace(' +', '+');
        const dateObj = new Date(data);
        if (isNaN(dateObj.getTime())) {
            return null;
        }
        return dateObj;
    }
    public static safeApply(instance: any, f: (v: any) => any, data: any): void {
        if (data !== undefined && (typeof data !== 'number' || !isNaN(data))) {
            f.call(instance, data);
        }
    }
}
