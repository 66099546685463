import { VenueService } from './../../services/venue/venue.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { TrackingService } from '../../services/tracking/tracking.service';
import { RefdataService } from '../../services/refdata/refdata.service';
import { Beer } from '../../models/persistency/persistent-models/beer';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { Venue } from '../../models/persistency/persistent-models/venue';

@Component({
  selector: 'app-similar-beers',
  templateUrl: './similar-beers.component.html',
  styleUrls: ['./similar-beers.component.scss']
})
export class SimilarBeersComponent implements OnInit, AfterViewInit {
  @Input()
  public beer!: Beer;

  public similarBeers: ReadonlySet<VenueBeer> = new Set();

  public venueName: string;

  private venue: Venue;

  public constructor(
    public trackingService: TrackingService,
    public refdataService: RefdataService,
    public translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private venueService: VenueService
  ) {
    this.venue = this.venueService.getSelectedVenue(true);
    this.venueName = this.venue.getName();
  }

  public ngOnInit(): void {
    this.similarBeers = this.beer.getSimilarVenueBeers(this.venue);
  }

  public ngAfterViewInit(): void {
    // scroll back to the top on all beeroverview-data divs (for nicer back button navigation)
    setTimeout(() => {
      const els = document.getElementsByClassName('beeroverview-data');
      for (let i = 0; i < els.length; i++) {
        const item = els.item(i);
        if (item) {
          item.scrollTop = 0;
        }
      }
    }, 200);
  }

  public onCardClick(similarBeer: VenueBeer): void {
    this.trackingService.trackEvent('select-beer', {
      id: similarBeer.getId(),
      similarTo: this.beer.getId()
    });
    // avoid "page not found" when on list page instead of detail page: check on presence of /detail/
    
        let isNavigationFromDetailToDetail: boolean = (this.router.url.indexOf('/detail/') >= 0);
        if (isNavigationFromDetailToDetail) {
    //      let newUrlTree = this.router.createUrlTree(['../' + similarBeer.getBeer().getId()]);
    //      this.router.navigateByUrl(newUrlTree)
          console.log('this.router.url = ' + this.router.url);
          let newUrl = this.router.url.replace('\/detail\/' + this.beer.getId(), '/detail/' + similarBeer.getBeer().getId());
          console.log('Navigating to url ' + newUrl);
          this.router.navigateByUrl(newUrl)
    //      this.router.navigate(['../' + similarBeer.getBeer().getId()], {relativeTo: this.route, queryParamsHandling: 'preserve'})
          .then((success) => {
            console.log('Navigation from detail to detail - success = ' + success);
            }, (rejectReason: any) => {
            console.log('Navigation from detail to detail - reject reason = ' + rejectReason);
            })
          .catch((reason: any) => {
            console.log('Navigation from detail to detail - Reason for no navigation = ' + reason);
            });
        }
        else { // navigation from list to detail
          this.router.navigate(['detail', similarBeer.getBeer().getId()], {relativeTo: this.route, queryParamsHandling: 'preserve'})
          .then((success) => {
            console.log('Navigation from list to detail - success = ' + success);
            }, (rejectReason: any) => {
            console.log('Navigation from list to detail - reject reason = ' + rejectReason);
            })
          .catch((reason: any) => {
            console.log('Navigation from list to detail - Reason for no navigation = ' + reason);
            });
        }
    //    let relativeNextPage = (this.router.url.indexOf('/detail/') >= 0 ? '..' : 'detail');
    //    this.router.navigate([relativeNextPage, similarBeer.getBeer().getId()], {relativeTo: this.route, queryParamsHandling: 'preserve'});
  }
}
