import { ScreenVersionListener } from './../../models/screen-version-listener';
import { OrderService } from './../../services/order/order.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { Order } from '../../models/order';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss']
})
export class OrderCardComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public venueBeer!: VenueBeer;

  @Output()
  public cardClick = new EventEmitter<void>();

  @Output()
  public orderChange = new EventEmitter<Order>();

  public beerName!: string;
  public orders!: Order[];

  public constructor(
    private orderService: OrderService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.beerName = this.venueBeer.getBeer().getName();
    this.orders = this.orderService.getOrders(this.venueBeer);
  }

  public onCardClick(): void {
    this.cardClick.emit();
  }

  public onOrderChange(order: Order): void {
    this.orderChange.emit(order);
  }
}
