import { Hexaselector, Selector } from '../../models/selector';
import { Component, OnInit, Input } from '@angular/core';
import { Filter } from '../../models/filter';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';

@Component({
  selector: 'app-binaries-hive',
  templateUrl: './binaries-hive.component.html',
  styleUrls: ['./binaries-hive.component.scss'],
})
export class BinariesHiveComponent implements OnInit {
  @Input()
  public aromas!: Hexaselector;

  @Input()
  public venueBeer!: VenueBeer;

  public centralAromaSelector!: Selector;

  public middleHexagon = { top: 35, left: 31 };
  public hexagons = [
    { top: 0, left: 31 },
    { top: 17.5, left: 62 },
    { top: 52.5, left: 62 },
    { top: 70, left: 31 },
    { top: 52.5, left: 0 },
    { top: 17.5, left: 0 }
  ];

  public constructor(
  ) {
  }

  public ngOnInit(): void {
    // set central selector
    this.centralAromaSelector = new Selector('aroma', '', 'aroma', Filter.MatchAll, 'white', this.venueBeer.getVenue().getPrimaryColor());
  }
}
