import { weekdays, Weekday } from './../../weekday';
import { Selector } from './../../selector';
import { OtherSelectors } from '../../selector';
import { LoginGuard } from '../../../guards/login/login.guard';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ModelTable } from '../model-table';
import { Venue } from './venue';
import { RefdataService } from '../../../services/refdata/refdata.service';
import { ContactType, ContactTypeMap } from '../../contact-type';
import { Cleaner } from '../../cleaner';
import { OpeningDay } from '../../opening-time';
import { Unit } from '../../unit';
import { Beer } from './beer';
import { Hexa } from '../../utilities';

/**
 * An abstract {@link ModelTable} of {@link Venue}s responsible for reading and serializing instances.
 */
export abstract class VenueTable extends ModelTable<Venue> {
  public constructor(protected http: HttpClient,
              protected refdata: RefdataService,
              protected loginGuard: LoginGuard,
              private translateService: TranslateService,
              private beerTable: ModelTable<Beer>,
              private otherSelectors: OtherSelectors) {
    super([]);
  }

  /** @inheritDoc */
  public createInstance(): Venue {
    return new Venue(this, this.otherSelectors, this.http,
      this.refdata, this.beerTable, this.translateService, this.loginGuard);
  }
  /** @inheritDoc */
  public serializeInstance(instance: Venue): any {
    return {
      id: instance.getId(),
      ownerUserId: instance.getOwnerUserId(),
      creationTs: instance.getCreationTime(),
      updateTs: instance.getLastUpdate(),
      type: 'pub',
      info: {
        abouts: Array.from(instance.getAbouts(), ([lang, text]) => ({lang: lang, text: text})),
        city: instance.getAddress().getCity(),
        country: instance.getAddress().getCountry(),
        street: instance.getAddress().getStreet(),
        contact: ContactType.All.map((type) => ({type: type.toJSON(), link: instance.getContact(type)})),
        openinghours: Array.from(instance.getOpeningTimes(),
          ([day, openingTimes]) => ({
            openclose: openingTimes.isOpen(),
            times: openingTimes.times.map((time) => {
              return {
                from: {t: time.from.h.toString().padStart(2, '0') + ':' + time.from.m.toString().padStart(2, '0')},
                to: {t: time.to.h.toString().padStart(2, '0') + ':' + time.to.m.toString().padStart(2, '0')}
              };
            })})),
        currency: { symbol: instance.getCurrency().getSymbol(), location: instance.getCurrency().isPrefix() ? 'before' : 'after' },
        defaultLang: instance.getDefaultLanguage(),
        languages: Array.from(instance.getLanguages()),
        lowalcohol: instance.getMaxLowABV(),
        name: instance.getName(),
        urlName: instance.getUrlPath(),
        image: instance.getBannerUrl(),
        logo: instance.getLogoUrl(),
        volumeUnit: instance.getDefaultVolumeUnit().getSymbol(),
      },
      latitude: instance.getAddress().getLatitude(),
      longitude: instance.getAddress().getLongitude(),
      logo: instance.getLogoUrl(),
      personalization: {
        logo: instance.getLogoUrl(),
        welcomeImage: instance.getWelcomeImageUrl(),
        welcomeImageMobile: instance.getWelcomeImageUrlMobile(),
        promoImages: Array.from(instance.getPromoImageUrls()),
        promoRotationTime: instance.getPromoRotationTime(),
        floorPlan: instance.getFloorPlanUrl(),
        hidePrices: !instance.shouldPricesBeShown(),
        showWelcomePage: instance.shouldWelcomePageBeShown(),
        primaryColor: instance.getPrimaryColor(),
        secondaryColor: instance.getSecondaryColor(),
        tags: Array.from(instance.getBeerTags(), (tag) => tag.toJSON()),
        selectors: instance.getOtherSelectors().map((selector) => selector.getLabelKey()),
        autoHomeTime: instance.getAutoHomeTime(),
      },
    };
  }
  /** @inheritDoc */
  public clone(instance: Venue): Venue {
    const rawData = instance.toJSON();
    const clone = new Venue(this, this.otherSelectors, this.http,
      this.refdata, this.beerTable, this.translateService, this.loginGuard, undefined, undefined, undefined,
      instance.venueBeerTable, instance.beerTagTable);
    this.applyRawData(rawData, clone);
    return clone;
  }
  /** @inheritDoc */
  public applyRawData(data: any, instance: Venue): void {
    // Basic info
    instance.setId(Cleaner.parseId(data.id));
    instance.setCreationTime(Cleaner.parseDate(data.creationTs));
    instance.setLastUpdate(Cleaner.parseDate(data.updateTs));
    Cleaner.safeApply(instance, instance.setOwnerUserId, data.ownerUserId);
    Cleaner.safeApply(instance, instance.setName, data.info.name);
    Cleaner.safeApply(instance, instance.setUrlPath, data.info.urlName);

    // Abouts
    instance.getAbouts().clear();
    (data.info.abouts as {lang: string, text: string}[])?.forEach((about) => instance.setAbout(about.lang, about.text));

    // Address
    const address = instance.getAddress();
    Cleaner.safeApply(address, address.setCity, data.info.city);
    Cleaner.safeApply(address, address.setCountry, data.info.country);
    Cleaner.safeApply(address, address.setStreet, data.info.street);
    Cleaner.safeApply(address, address.setLatitude, data.info.latitude);
    Cleaner.safeApply(address, address.setLongitude, data.info.longitude);

    // Contact info
    const contactMap = ContactTypeMap.read(this.cleanContactMap(data.info.contact));
    ContactType.All.forEach((type) => instance.setContact(type, contactMap.get(type) || null));

    // Opening times
    instance.getOpeningTimes().reset();
    (this.cleanOpeningTimes(data.info.openinghours) || [])
      .forEach(([day, dayData]) => {
        if (Object.values(Weekday).includes(day as Weekday)) {
          instance.getOpeningTimes().set(day as Weekday, OpeningDay.read(dayData));
        }
      });

    // Currency and default volume unit
    Cleaner.safeApply(instance, instance.setCurrency, Unit.getUnitBySymbol(data.info.currency.symbol));
    Cleaner.safeApply(instance, instance.setDefaultVolumeUnit, Unit.getUnitBySymbol(data.info.volumeUnit));

    // Languages and default language
    const oldDefaultLang = instance.getDefaultLanguage();
    instance.getLanguages().forEach((lang) => {
      if (lang !== oldDefaultLang) {
        instance.removeLanguage(lang);
      }
    });
    (data.info.languages as string[])?.forEach((lang) => instance.addLanguage(lang));
    instance.setDefaultLanguage(data.info.defaultLang);
    if (!(data.info.languages as string[]).includes(oldDefaultLang)) {
      instance.removeLanguage(oldDefaultLang);
    }

    // Personalization
    Cleaner.safeApply(instance, instance.setLogoUrl, data.info.logo || data.personalization.logo);
    Cleaner.safeApply(instance, instance.setWelcomeImageUrl, data.personalization.welcomeImage);
    Cleaner.safeApply(instance, instance.setWelcomeImageUrlMobile, data.personalization.welcomeImageMobile);
    Cleaner.safeApply(instance, instance.setPromoImageUrls, data.personalization.promoImages);
    Cleaner.safeApply(instance, instance.setPromoRotationTime, data.personalization.promoRotationTime);
    Cleaner.safeApply(instance, instance.setFloorPlanUrl, data.personalization.floorPlan);
    Cleaner.safeApply(instance, instance.setBannerUrl, data.info.image);
    Cleaner.safeApply(instance, instance.setMaxLowABV, parseFloat(data.info.lowalcohol));
    Cleaner.safeApply(instance, instance.setWhetherPricesShouldBeShown, !data.personalization.hidePrices);
    Cleaner.safeApply(instance, instance.setWhetherWelcomePageShouldBeShown, data.personalization.showWelcomePage);
    Cleaner.safeApply(instance, instance.setPrimaryColor, data.personalization.primaryColor);
    Cleaner.safeApply(instance, instance.setSecondaryColor, data.personalization.secondaryColor);
    Cleaner.safeApply(instance, instance.setAutoHomeTime,
      data.personalization.autoHomeTime === null ? null : parseInt(data.personalization.autoHomeTime, 10));

    // Tags
    instance.beerTagTable.setRawData(data.personalization.tags || []);

    // Other selectors
    const selectors = this.cleanSelectors(data.personalization.selectors);
    if (selectors) {
      const otherSelectors: Hexa<Selector> = [...instance.getOtherSelectors()];
      selectors
        .forEach((selectorKey, index) => {
          const sel = this.otherSelectors.getSelectorByLabelKey(selectorKey);
          if (sel) {
            otherSelectors[index] = sel;
          }
        });
      instance.setOtherSelectors(otherSelectors);
    }
  }
  private cleanContactMap(data: any[]): [string, string][] {
    if (!data) {
      return [];
    }
    const map: [string, string][] = [];
    data.forEach((c) => {
      if (c.link) {
        map.push([c.type, c.link.replace(/^(tel:|mailto:)/g, '')]);
      }
    });
    return map;
  }
  private cleanSelectors(data: any[]): Hexa<string> | undefined {
    if (!data) {
      return undefined;
    }
    if (data.length !== 6 || !data.every((key) => this.otherSelectors.getSelectorByLabelKey(key) !== null)) {
      return undefined;
    }
    return data as Hexa<string>;
  }
  private cleanOpeningTimes(data: any[]): [string, any][] | undefined {
    if (!data) {
      return undefined;
    }
    const openingTimes: [string, any][] = [];
    if (data.length === 0) {
      return [];
    }
    if (data[0].days) {
      // Oldest format detected
      weekdays.forEach((day, index) => {
        const times = data.filter((openingTime) => openingTime && openingTime.days && openingTime.days.indexOf(index) >= 0)
          .map((openingTime) => openingTime.times)
          .reduce((a: any[], b: any[]) => a.concat(b), []);
        const open = times.length > 0;
        openingTimes.push([day, {
          isOpen: open,
          times: times
        }]);
      });
    } else {
      // Less old format detected
      weekdays.forEach((day, index) => {
        const times = data[index]?.times || [];
        const open = data[index]?.openclose || false;
        const parseTime = (timeStr: string) => {
          const hAndM = timeStr.split(':').map((n) => parseInt(n, 10));
          return {
            h: hAndM[0],
            m: hAndM[1]
          };
        };
        openingTimes.push([day, {
          isOpen: open,
          times: times.map((time: any) => ({
            from: parseTime(time.from.t),
            to: parseTime(time.to.t)
          }))
        }]);
      });
    }
    return openingTimes;
  }
}
