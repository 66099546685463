<ion-card [ngClass]="'card' + (isDeskTop() ? '-widescreen' : '')">
  <ion-row class="beer-name-row" (click)="onCardClick()">
    <div class="beer-name">{{ beerName }}</div>
  </ion-row>
  <app-order-row
    *ngFor="let order of orders"
    [order]="order"
    (orderChange)="onOrderChange($event)"
  ></app-order-row>
</ion-card>
