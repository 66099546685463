<ion-card [ngClass]="'card' + (isDeskTop() ? '-widescreen' : '')">
  <!-- General beer information -->
  <ion-row>
    <ion-col size="11.2" class="ion-no-padding">
      <app-beer-info
        [beer]="beer"
        [venueBeer]="venueBeer"
        [showEssentialsOnly]="'admin'"
      ></app-beer-info>
    </ion-col>
    <ion-col size="0.8" class="ion-no-padding">
      <div class="add-edit-wrapper">
        <ion-checkbox
          class="checkbox"
          color="secondary"
          [checked]="checked"
          (ionChange)="onCheckCard()"
        ></ion-checkbox>
        <br>
        <ion-icon
          name="pencil"
          class="edit-icon"
          (click)="onEditBeer()"
          *ngIf="editable"
        ></ion-icon>
        <br>
      </div>
    </ion-col>
  </ion-row>

  <!-- price row -->
  <div *ngIf="venueBeer && venue.shouldPricesBeShown()">
    <ion-row *ngFor="let serving of servings" class="price-row">
      <!-- serve icons -->
      <ion-col size="2" class="center-col">
        <ion-icon
          *ngFor="let serveMethod of serveMethods"
          [ngClass]="
            'serve-icon' +
            (serving.getCasing() === serveMethod ? ' selected' : '')
          "
          [src]="'assets/svgs/admin/' + serveMethod + '.svg'"
          (click)="onSelectServeMethod(serving, serveMethod)"
        ></ion-icon>
      </ion-col>

      <!-- volume -->
      <ion-col size="3" class="center-col">
        <span class="fleft" *ngIf="serving.getVolumeUnit().isPrefix()" >{{ serving.getVolumeUnit().getSymbol() }}</span>
        <div class="input-wrapper fleft text-right">
          <ion-input
            type="number" inputmode="numeric" min="0" (keydown)="preventNegativeInput($event)"
            [maxlength]="3"
            [debounce]="200"
            (ionChange)="onNumberInput($any($event), serving, 'volume')"
            class="ion-no-padding"
            [value]="serving.getVolume()"
          ></ion-input>
        </div>
        <span class="fleft" *ngIf="serving.getVolumeUnit().isPostfix()" >{{ serving.getVolumeUnit().getSymbol() }}</span>
      </ion-col>

      <!-- price -->
      <ion-col size="3" class="center-col">
        <span class="fleft" *ngIf="currency.isPrefix()" >{{ currency.getSymbol() }} </span>
        <div class="input-wrapper fleft text-right">
          <ion-input
            type="number" inputmode="numeric" min="0" (keydown)="preventNegativeInput($event)"
            [maxlength]="5"
            [debounce]="200"
            (ionChange)="onNumberInput($any($event), serving, 'price')"
            class="ion-no-padding"
            [value]="serving.getPrice()"
          ></ion-input>
        </div>
        <span class="fleft" *ngIf="currency.isPostfix()" >{{ currency.getSymbol() }} </span>
      </ion-col>

      <!-- promo price -->
      <ion-col size="3" class="center-col">
        <ion-icon
          class="promo-icon"
          src="assets/svgs/admin/promo.svg"
        ></ion-icon>
        <span class="fleft" *ngIf="currency.isPrefix()" >{{ currency.getSymbol() }} </span>
        <div class="input-wrapper fleft text-right">
          <ion-input
            type="number" inputmode="numeric" min="0" (keydown)="preventNegativeInput($event)"
            [maxlength]="5"
            [debounce]="200"
            (ionChange)="onNumberInput($any($event), serving, 'promoPrice')"
            class="ion-no-padding"
            [value]="serving.getPromotionPrice()"
          ></ion-input>
        </div>
        <span class="fleft" *ngIf="currency.isPostfix()" >{{ currency.getSymbol() }} </span>
      </ion-col>

      <!-- remove icon -->
      <ion-col size="1" class="center-col">
        <ion-icon
          name="remove-circle"
          (click)="onRemoveServing(serving)"
          class="remove-icon"
        ></ion-icon>
      </ion-col>
    </ion-row>

    <!-- add more rows -->
    <ion-row class="add-row">
      <ion-icon
        name="add-circle"
        (click)="onAddServing()"
        class="add-icon"
      ></ion-icon>
      
    </ion-row>
    <ion-row class="text-center">
      <ion-text color="medium" class="add-label">{{ translateService.instant("EDIT_BEER.ADD_SERVING") }}</ion-text>
    </ion-row>
  </div>
</ion-card>
