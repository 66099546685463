<div class="wrapper">
  <ion-checkbox
    color="secondary"
    [checked]="checked"
    (ionChange)="onCheck()"
    class="checkbox"
  ></ion-checkbox>
  <div class="label">
    {{ translateService.instant('EDIT_BEER.CHECKBOX_' + type.toUpperCase()) }}
  </div>
  <ion-icon
    [src]="'assets/svgs/general/icon_' + type + '.svg'"
    class="icon"
    [title]="translateService.instant('EDIT_BEER.' + type.toUpperCase())"
  ></ion-icon>
</div>
