<div class="selector-wrapper">
  <app-hexagon
    class="hexagon"
    [selectable]="false"
    [content]="centralAromaSelector"
    [ngStyle]="{ top: middleHexagon.top + '%', left: middleHexagon.left + '%' }"
  ></app-hexagon>
  <app-hexagon
    *ngFor="let hexagon of hexagons; let i = index"
    class="hexagon"
    [ngStyle]="{ top: hexagon.top + '%', left: hexagon.left + '%' }"
    [content]="aromas[i]"
    [editMode]="false"
    [selectable]="false"
  ></app-hexagon>
</div>
