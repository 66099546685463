<div
  [ngClass]="
    !selectable
      ? 'hexagon-wrapper-not-selectable'
      : selected
      ? 'hexagon-wrapper-selected'
      : 'hexagon-wrapper-not-selected'
  "
>
  <div
    *ngIf="icon"
    (click)="onClick()"
    [ngClass]="
      (!selectable
        ? 'img-wrapper-not-selectable'
        : selected
        ? 'img-wrapper-selected'
        : 'img-wrapper-not-selected') +
      (label
        ? ' img-wrapper-label'
        : ' img-wrapper-no-label')
    "
  >
    <img [src]="'assets/svgs/hexagons/icon_' + icon! + '.svg'" />
  </div>

  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="-10 -10 745 650"
  >
    <defs>
      <linearGradient id="gradient_#FFFF01" gradientTransform="rotate(90)">
        <stop offset="50%"  stop-color="white" />
        <stop offset="50%" stop-color="#FFFF01" />
      </linearGradient>
      <linearGradient id="gradient_#FE9900" gradientTransform="rotate(90)">
        <stop offset="50%"  stop-color="white" />
        <stop offset="50%" stop-color="#FE9900" />
      </linearGradient>
      <linearGradient id="gradient_#A94214" gradientTransform="rotate(90)">
        <stop offset="50%"  stop-color="white" />
        <stop offset="50%" stop-color="#A94214" />
      </linearGradient>
      <linearGradient id="gradient_#CC0001" gradientTransform="rotate(90)">
        <stop offset="50%"  stop-color="white" />
        <stop offset="50%" stop-color="#CC0001" />
      </linearGradient>
      <linearGradient id="gradient_#5B220E" gradientTransform="rotate(90)">
        <stop offset="50%"  stop-color="white" />
        <stop offset="50%" stop-color="#5B220E" />
      </linearGradient>
      <linearGradient id="gradient_#080808" gradientTransform="rotate(90)">
        <stop offset="50%"  stop-color="white" />
        <stop offset="50%" stop-color="#080808" />
      </linearGradient>
    </defs>
    <g
      [attr.class]="
        (!selectable ? ' not-selectable' : '') +
        (selected
          ? ' selected'
          : ' not-selected')
      "
      (click)="onClick()"
    >
      <polygon *ngIf="useGradient && selectable && !selected"
      class="hexagon-fill"
      points="723,314 543,625.769145 183,625.769145 3,314 723,314"
      [attr.fill]="color"
      />
      <polygon *ngIf="useGradient && selectable && !selected"
        class="hexagon-fill"
        points="723,314 3,314 183,2.230855 543,2.230855 723,314"
        fill="white"
      />
      <polygon *ngIf="useGradient && selectable && !selected"
        class="hexagon"
        points="723,314 543,625.769145 183,625.769145 3,314 183,2.230855 543,2.230855 723,314"
        fill="none"
      />
      <polygon *ngIf="!useGradient || !selectable || selected"
        class="hexagon"
        points="723,314 543,625.769145 183,625.769145 3,314 183,2.230855 543,2.230855 723,314"
        [attr.fill]="color"
      />

      <text
        *ngIf="label"
        class="svg-text"
        dominant-baseline="middle"
        text-anchor="middle"
        [attr.fill]="textColor"
        [attr.transform]="
          'translate(365 ' +
          (words && words.length == 1 ? 500 : 455) +
          ')'
        "
      >
        <tspan x="0" y="0">{{ words ? words[0] : undefined }}</tspan>
        <tspan x="0" y="100" *ngIf="words && words.length > 1">
          {{ words[1] }}
        </tspan>
      </text>

      <!-- What if no fill -->
      <g
        class="empty-color-fill"
        *ngIf="!icon && !color"
      >
        <polygon points="723,314 543,625.769145 363,314" fill="#A94124" />
        <polygon
          points="543,625.769145 183,625.769145 363,314"
          fill="#CC0001"
        />
        <polygon points="183,625.769145 3,314 363,314" fill="#5B220E" />
        <polygon points="3,314 183,2.230855 363,314" fill="#080808" />
        <polygon points="183,2.230855 543,2.230855 363,314" fill="#FFFF01" />
        <polygon points="543,2.230855 723,314 363,314" fill="#FE9900" />
      </g>
    </g>
  </svg>
</div>

<app-popover-select
  *ngIf="showPopover"
  class="popover-selector"
  [options]="options"
  [selected]="content"
  [canDeselect]="false"
  [showSelector]="true"
  [multiSelect]="false"
  (selectionChange)="onEdit($event)"
></app-popover-select>
