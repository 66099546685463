import { Constructor } from './utilities';

/**
 * A utility [mixin](https://www.typescriptlang.org/docs/handbook/mixins.html) for components that need
 * to prevent the user for giving negative input.
 * @param Base  The base class to inherit from.
 */
export function PreventNegativeInputMixin<T extends Constructor<{}>>(Base: T) {
    return class extends Base {
        public preventNegativeInput(event: KeyboardEvent): void {
            const key = event.key || 'Unidentified';
            // tslint:disable-next-line: deprecation
            const keyCode = event.keyCode || 0;
            if (key !== 'Unidentified') {
                if (isNaN(parseInt(key, 10)) && !['Backspace', '.'].includes(key) && !isControlKey(key)) {
                    event.preventDefault();
                }
            } else if (keyCode !== 0) {
                if (!((keyCode > 95 && keyCode < 106) // Check for a numpad code
                    || (keyCode > 47 && keyCode < 58) // Check for a number row key
                    || keyCode === 8) && !isControlKeyCode(keyCode)) { // Check for a backspace
                    event.preventDefault();
                }
            } else {
                console.error('Unknown keyboard event', event);
            }
        }
    };
}
/**
 * A class for components that need to prevent the user for giving negative input and that do not need
 * to inherit from any other class.
 *
 * Also see {@link PreventNegativeInputMixin}.
 */
export const PreventNegativeInput = PreventNegativeInputMixin(Object);

function isControlKey(key: string) {
    return key.length > 1;
}
function isControlKeyCode(keyCode: number) {
    return !(
        (keyCode > 47 && keyCode < 58)   || // number keys
        keyCode === 32                   || // spacebar
        (keyCode > 64 && keyCode < 91)   || // letter keys
        (keyCode > 95 && keyCode < 112)  || // numpad keys
        (keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
        (keyCode > 218 && keyCode < 223)    // [\]' (in order)
    );
}
