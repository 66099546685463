<div class="dropzone">
  <!-- delete icon -->
  <ion-icon
    name="trash"
    class="delete-icon"
    *ngIf="imageData || imageUrl"
    (click)="onDelete()"
  ></ion-icon>

  <!-- hidden input field -->
  <!-- input currently limited to png and jpeg since .svg for example is not processed right at the moment -->
  <input
    type="file"
    accept="image/png,image/jpeg,image/jpg"
    class="file-input dropframe"
    (change)="onFileSelected($event)"
  />

  <!-- icon -->
  <ion-icon
    src="assets/svgs/general/image-add.svg"
    class="add-img dropframe"
    *ngIf="!(imageData || imageUrl)"
  ></ion-icon>

  <!-- image is loaded -->
  <ion-img
    [src]="(imageData || imageUrl)!"
    *ngIf="imageData || imageUrl"
    class="dropframe"
  ></ion-img>

  <!-- spinner while uploading -->
  <ion-spinner name="bubbles" class="dropframe" *ngIf="isLoading" ></ion-spinner>

  <!-- overlay a label -->
  <div class="label-wrapper" *ngIf="labelToShow">
    <div class="label-inner-wrapper">
      <div class="label">
        {{ labelToShow }}
      </div>
      <div class="recommended-size">
        {{
          "(" +
            recommendedSize +
            " " +
            translateService.instant("GENERAL.RECOMMENDED") +
            ")"
        }}
      </div>
    </div>
  </div>
</div>
