import { Hexa } from './../../models/utilities';
import { Hexaselector, Selector } from '../../models/selector';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Option } from '../popover-select/popover-select.component';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent implements OnInit {
  @Input()
  public fillings!: Hexaselector;

  @Input()
  public editMode = false;

  @Input()
  public options: Selector[] = [];

  @Input()
  public selected: Set<Selector> = new Set<Selector>();

  @Output()
  private selectionChange = new EventEmitter<Set<Selector>>();

  @Output()
  private fillingsChange = new EventEmitter<Hexaselector>();

  public editeableOptions: Option<Selector>[] = [];

  public middleHexagon = { top: 35, left: 31 };
  public hexagons = [
    { top: 0, left: 31 },
    { top: 17.5, left: 62 },
    { top: 52.5, left: 62 },
    { top: 70, left: 31 },
    { top: 52.5, left: 0 },
    { top: 17.5, left: 0 }
  ];

  public constructor(
  ) {
  }

  public ngOnInit(): void {
    if (this.editMode) {
      this.editeableOptions = this.options.map((s) => ({
        selectable: !this.fillings.includes(s),
        translationKey: s.getTranslationKey(),
        object: s}));
    }
  }

  public onSelectionChange(selector: Selector, selected: boolean): void {
    if (selected) {
      this.selected.add(selector);
    } else {
      this.selected.delete(selector);
    }

    this.selectionChange.emit(this.selected);
  }

  public onContentsChange(index: number, newSelector: Selector): void {
    const newFillings = Array.from(this.fillings) as Hexa<Selector>;
    newFillings[index] = newSelector;
    this.fillings = newFillings as Hexaselector;
    this.fillingsChange.emit(this.fillings);
  }
}
