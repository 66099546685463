<div class="selector-wrapper">
  <app-hexagon
    class="hexagon"
    [selectable]="false"
    [ngStyle]="{ top: middleHexagon.top + '%', left: middleHexagon.left + '%' }"
  ></app-hexagon>
  <app-hexagon
    *ngFor="let hexagon of hexagons; let i = index"
    class="hexagon"
    [selected]="selected.has(fillings[i])"
    [ngStyle]="{ top: hexagon.top + '%', left: hexagon.left + '%' }"
    [content]="fillings[i]"
    [options]="editeableOptions"
    [editMode]="editMode"
    (selectionChange)="onSelectionChange(fillings[i], $event)"
    (contentChange)="onContentsChange(i, $event)"
  ></app-hexagon>
</div>
