import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScreenVersionListener } from '../../models/screen-version-listener';

@Component({
  selector: 'app-show-results-bar',
  templateUrl: './show-results-bar.component.html',
  styleUrls: ['./show-results-bar.component.scss']
})
export class ShowResultsBarComponent extends ScreenVersionListener {
  @Input()
  public hasFilters = false;

  @Input()
  public nrOfBeers = 0;

  @Input()
  public type!: string;

  @Output()
  public showResults = new EventEmitter<void>();


  public constructor(
    public translateService: TranslateService,
  ) {
    super();
  }

  public onShow(): void {
    this.showResults.emit();
  }
}
