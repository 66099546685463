<div [ngClass]="'banner-wrapper ' + type + '-wrapper'">
  <!-- home button -->
  <div
    *ngIf="showWelcomePage"
    [class]="'home-wrapper-' + screenType"
    (click)="onHome()"
  >
    <ion-icon name="home" [class]="'home-icon-' + screenType"></ion-icon>
  </div>

  <!-- background -->
  <div *ngIf="!isDeskTop() || !venue.getLogoUrl()">
    <ion-img
      [src]="venue.getBannerUrl()!"
      class="banner-image"
      *ngIf="venue.getBannerUrl()"
    ></ion-img>
    <ion-img
      src="assets/backgrounds/venue_default_top_banner_pub.png"
      class="banner-image"
      *ngIf="!venue.getBannerUrl()"
    ></ion-img>
  </div>
  <div *ngIf="isDeskTop()">
    <ion-img
      [src]="venue.getLogoUrl()!"
      class="banner-logo"
      *ngIf="venue.getLogoUrl()"
    ></ion-img>
  </div>
  <div class="black-overlay" *ngIf="!isDeskTop() || !venue.getLogoUrl()"></div>

  <!-- menu button -->
  <ion-menu-button [class]="'banner-menu-' + screenType" auto-hide="false"></ion-menu-button>

  <!-- venue text -->
  <div
    [ngClass]="'banner-text ' + type + '-text'"
    *ngIf="!isDeskTop() || !venue.getLogoUrl()"
  >
    <div [ngClass]="'venue ' + type + '-venue'">
      {{ venue.getName() }}
    </div>
    <div [ngClass]="'beeradvice ' + type + '-advice'">
      {{ translateService.instant("BANNER.DIGITAL_BEERADVICE") }}
    </div>
  </div>
</div>
