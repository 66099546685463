import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { modelUrls as urls } from '../../model-urls';
import colors from '../../models/defaults/colors.json';


@Injectable({
  providedIn: 'root'
})
export class RefdataService {
  constructor() { }

  private createUrl(base: keyof typeof environment, path: string): string {
    return environment[base] + path;
  }
  public getBeersAndBreweriesLastModifiedUrl(): string {
    return this.createUrl(urls.beersandbreweries.base, urls.beersandbreweries.HEAD);
  }
  public getBeersAndBreweriesUrl(): string {
    return this.createUrl(urls.beersandbreweries.base, urls.beersandbreweries.GET);
  }
  public getVenueUrlMappingUrl(): string {
    return this.createUrl(urls.venue.base, urls.venue.GET);
  }
  public getAdminVenueUrl(userToken: string): string {
    return this.createUrl(urls.adminvenue.base, urls.adminvenue.GET) + userToken;
  }
  public getVenueBeerUrl(venueId: string): string {
    return this.createUrl(urls.venuebeer.base, urls.venuebeer.GET) + venueId;
  }

  public getColorById(id: number): string {
    return colors.idToColor[id];
  }
}
