<div class="icon-wrapper">
  <div *ngFor="let label of labels">
    <img
      [src]="'assets/svgs/spider/icon_' + label.name + '.svg'"
      class="label-icon"
      (click)="presentToast(label.name)"
      [ngStyle]="{
        left: label.left + '%',
        top: label.top + '%'
      }"
    />
  </div>
</div>

<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 1100 820"
>
  <!-- hive lines -->
  <g>
    <polygon [attr.points]="getPolygon(4)" class="outer-hexagon" />
    <polygon
      *ngFor="let i of [3, 2, 1, 0]"
      [attr.points]="getPolygon(i)"
      class="inner-hexagon"
    />
  </g>

  <!-- spider internal lines -->
  <g>
    <line
      [attr.x1]="mid.x"
      [attr.y1]="mid.y"
      [attr.x2]="getCrossLineX(i)"
      [attr.y2]="getCrossLineY(i)"
      class="cross-line"
      *ngFor="let i of [0, 1, 2, 3, 4, 5]"
    />
  </g>

  <!-- icons -->
  <g *ngFor="let label of labels">
    <text
      class="label"
      dominant-baseline="middle"
      (click)="presentToast(label.name)"
      [attr.text-anchor]="label.anchor"
      [attr.transform]="
        'translate(' +
        (mid.x + label.tOffsetX) +
        ' ' +
        (mid.y + label.tOffsetY) +
        ') scale(1 1)'
      "
    >
      {{ translateService.instant("AROMAS." + label.name.toUpperCase()) }}
    </text>
  </g>

  <!-- value lines -->
  <g>
    <polygon
      [attr.points]="getValuePolygon()"
      class="value-hexagon"
      [attr.fill]="colorHex"
    />
  </g>
</svg>
