import { VenueService } from './../../services/venue/venue.service';
import { Location } from '../../models/persistency/persistent-models/venue-beer';
import { Component, OnInit, Input, ElementRef, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { LoggerService } from '../../services/logger/logger.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-store-locator',
  templateUrl: './store-locator.component.html',
  styleUrls: ['./store-locator.component.scss']
})
export class StoreLocatorComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: true })
  private wrapper!: ElementRef<HTMLDivElement>;

  @ViewChild('plan', { static: true })
  private plan!: ElementRef<HTMLImageElement>;

  @Input()
  public initialLocation: Location | null = null;

  @Input()
  public canEdit = false;

  @Output()
  public locationChange = new EventEmitter<Location>();

  public floorPlan: string | null = null;

  public minX = 0;
  public maxX = 0;
  public minY = 0;
  public maxY = 0;

  public showPin = false;
  public x = 0;
  public y = 0;

  public location: Location | null = null;

  public hasPlan: boolean = false;

  public constructor(
    public translateService: TranslateService,
    private logger: LoggerService,
    private venueService: VenueService,
  ) {
    const plan = this.venueService.getSelectedVenue(true).getFloorPlanUrl();
    if (!plan) {
      this.hasPlan = false;
      this.floorPlan = null;
      // throw new Error('Cannot locate beer when no floor plan is given!');
    }
    else {
      this.floorPlan = plan;
      this.hasPlan = true;
    }
  }

  public ngOnInit(): void {
    // track window size
    window.onresize = () => {
      this.setLocationOfPin();
    };

    this.location = this.initialLocation;
  }

  public ngAfterViewInit(): void {
    this.plan.nativeElement.onload = () => this.setLocationOfPin();
  }

  public onImageClick(event: MouseEvent): void {
    // ignore event if not editable
    if (this.canEdit && event) {
      const x = event.x;
      const y = event.y;

      // check if click was inside bounding box and set the location to that.
      if (
        x >= this.minX &&
        x <= this.maxX &&
        y >= this.minY &&
        y <= this.maxY
      ) {
        this.location = {
          x: (x - this.minX) / (this.maxX - this.minX),
          y: (y - this.minY) / (this.maxY - this.minY)
        };

        this.setLocationOfPin();

        this.locationChange.emit(this.location);
      }
    }
  }

  private setLocationOfPin() {
    // get widths and heights
    const imgHeight = this.plan.nativeElement.naturalHeight;
    const imgWidth = this.plan.nativeElement.naturalWidth;
    const wrapperHeight = this.wrapper.nativeElement.offsetHeight;
    const wrapperWidth = this.wrapper.nativeElement.offsetWidth;

    // calculate offsets
    let expectedWidth = wrapperWidth;
    let expectedHeight = (imgHeight / imgWidth) * wrapperWidth;
    if (expectedHeight > wrapperHeight) {
      expectedWidth = (imgWidth / imgHeight) * wrapperHeight;
      expectedHeight = wrapperHeight;
    }
    const topOffset = (wrapperHeight - expectedHeight) / 2;
    const leftOffset = (wrapperWidth - expectedWidth) / 2;

    this.showPin = this.location !== null;
    // set the location of the pin
    if (this.location) {
      this.x = leftOffset + this.location.x * expectedWidth;
      this.y = topOffset + this.location.y * expectedHeight;
    }

    // bounding box for interactivity
    const boundingBox = this.plan.nativeElement.getBoundingClientRect();
    this.minX = boundingBox.left + leftOffset;
    this.minY = boundingBox.top + topOffset;
    this.maxX = this.minX + expectedWidth;
    this.maxY = this.minY + expectedHeight;
  }
}
