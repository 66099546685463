import { LocalPersisterService } from './../../services/local-persister/local-persister.service';
import { BeersAndBreweriesFacadeService } from '../beers-and-breweries-facade/beers-and-breweries-facade.service';
import { BreweryService } from './../brewery/brewery.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BeerTable } from '../../models/persistency/persistent-models/beer-table';

/**
 * A {@link ModelTable} of {@link Beer}s.
 */
@Injectable({
  providedIn: 'root'
})
export class BeerService extends BeerTable {
  public constructor(translateService: TranslateService,
              private facade: BeersAndBreweriesFacadeService,
              breweryService: BreweryService,
              private localPersister: LocalPersisterService) {
    super(translateService, breweryService);
  }

  protected async getRawData(): Promise<any[]> {
    let lastLoadedDatePromise = this.localPersister.get('beer-data-last-loaded');
    let getBeerDataPromise = this.localPersister.get('beer-data');
    return Promise.all([lastLoadedDatePromise, getBeerDataPromise]).then((resultArray) => {
      let lastLoadedDate = resultArray[0];
      let data = resultArray[1];
      if (data && lastLoadedDate && (Date.now() - lastLoadedDate <= 3600000)) {
        console.log('Using beer data from local storage');
        return data;
      }
      return this.facade.getRawBeerData().then((newData) => {
        this.localPersister.set('beer-data', newData);
        this.localPersister.set('beer-data-last-loaded', Date.now());
        return newData;
      });
    });
/*    return this.localPersister.get('beer-data').then((data) => {
      if (data && lastLoadedDate && (Date.now() - lastLoadedDate <= 3600000)) {
        console.log('Using beer data from local storage');
        return data;
      }
      return this.facade.getRawBeerData().then((newData) => {
        this.localPersister.set('beer-data', newData);
        this.localPersister.set('beer-data-last-loaded', Date.now());
        return newData;
      });
    }); */
  }
}
