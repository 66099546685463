import { BeerTag } from '../../models/persistency/persistent-models/beer-tag';
import { BeerQueryService } from '../../services/beer-query/beer-query.service';
import { BeerQuery } from '../../models/beer-query';
import { VenueService } from '../../services/venue/venue.service';
import { ScreenVersionListener } from '../../models/screen-version-listener';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Sorter, SorterState } from '../../models/sorter';
import { Venue } from '../../models/persistency/persistent-models/venue';
import {
  style,
  state,
  animate,
  transition,
  trigger
} from '@angular/animations';
import { Hexaselector, ColorSelectors, AromaSelectors, Selector } from '../../models/selector';
import { Option } from '../popover-select/popover-select.component';
import { IdleTrackerService } from '../../services/idle-tracker/idle-tracker.service';

@Component({
  selector: 'app-sort-and-filter',
  templateUrl: './sort-and-filter.component.html',
  styleUrls: ['./sort-and-filter.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'left',
        style({
          left: 0,
          opacity: 0
        })
      ),
      state(
        'center',
        style({
          left: '8vw',
          opacity: 1
        })
      ),
      state(
        'right',
        style({
          left: '16vw',
          opacity: 0
        })
      ),
      transition(':enter', animate('0s')),
      transition('* => *', animate('.1s'))
    ])
  ]
})
export class SortAndFilterComponent extends ScreenVersionListener implements OnInit {
  @Input()
  public showSearch = false;

  @Input()
  public beerCount: number | null = null; // equal to the number of beers to show, if null, not shown

  private _sorters: Sorter[] = [];
  public get sorters(): Sorter[] {
    return this._sorters;
  }
  @Input()
  public set sorters(value: Sorter[]) {
    this._sorters = value;
    this.sortOptions = value.map((sorter) => ({
      selectable: true,
      translationKey: sorter.getTranslationKey(),
      object: sorter
    }));
  }

  @Input()
  public query: BeerQuery;

  @Input()
  public showFilterIcon = true;

  @Input()
  public showBackButton = true;

  @Output()
  public queryChange = new EventEmitter<BeerQuery>();

  @Output()
  public goBackClick = new EventEmitter<void>();

  private queryBackup: BeerQuery;

  public colorHexaselector: Hexaselector;
  public aromaHexaselector: Hexaselector;
  public otherHexaselector: Hexaselector;
  public initiallySelectedColor: Set<Selector> = new Set();
  public initiallySelectedAroma: Set<Selector> = new Set();
  public initiallySelectedOther: Set<Selector> = new Set();

  public hasFilters = false;

  public showOverlay = false;
  private tagFilterPage = {
    name: 'tags',
    location: 'right',
  };

  public filterPages = [
    {
      name: 'aroma',
      location: 'left',
    },
    {
      name: 'color',
      location: 'center',
    },
    {
      name: 'other',
      location: 'right',
    },
  ];

  public filterPageIndex = 1;

  public sortOptions: Option<Sorter>[] = [];

  private lastSelected: SorterState | null = null;
  public selected: Sorter | null = null;

  public venue: Venue;

  public constructor(
    public translateService: TranslateService,
    private venueService: VenueService,
    private beerQueryService: BeerQueryService,
    private idleTrackerService: IdleTrackerService,
    private colorSelectors: ColorSelectors,
    private aromaSelectors: AromaSelectors,
  ) {
    super();
    this.venue = this.venueService.getSelectedVenue(true);

    this.colorHexaselector = this.colorSelectors.getHexaselector();
    this.aromaHexaselector = this.aromaSelectors.getHexaselector();
    this.otherHexaselector = this.venue.getOtherSelectors();

    this.query = this.beerQueryService.create();
    this.queryBackup = this.beerQueryService.create();

    this.subscribe(IdleTrackerService.$cleanSituation, (cleanSituation) => { cleanSituation ? this.onRemoveAllFilters() : null; })
  }

  public onChangeDirection(): void {
    if (this.query.sort && this.query.sort.length > 0) {
      this.query.sort[0].descending = !this.query.sort[0].descending;
      this.queryChange.emit(this.query);
    }
  }

  public onSortChange(sorter: Sorter | null): void {
    this.selected = sorter;
    if (sorter) {
      if (this.query.sort && this.query.sort.length > 0) {
        this.query.sort[0] = sorter.createState();
      } else {
        this.query.sort = [sorter.createState()];
      }
    } else {
      this.query.sort = [];
    }
    this.queryChange.emit(this.query);
  }

  public onTagsChange(tags: BeerTag[]) {
    this.query.tags = tags;
    this.queryChange.emit(this.query);
  }
  public onColorChange(selection: Set<Selector>) {
    this.query.color = [...selection];
    this.queryChange.emit(this.query);
  }
  public onAromaChange(selection: Set<Selector>) {
    this.query.aroma = [...selection];
    this.queryChange.emit(this.query);
  }
  public onOtherChange(selection: Set<Selector>) {
    this.query.other = [...selection];
    this.queryChange.emit(this.query);
  }

  public onSearch(term: string) {
    if (term.length > 0 && !this.query.search) {
      this.lastSelected = (this.query.sort && this.query.sort[0]) || null;
      this.selected = null;
      this.query.sort = [];
    } else if (term.length === 0 && this.query.search && !this.query.sort?.length) {
      if (this.lastSelected) {
        this.query.sort = [this.lastSelected];
      } else {
        this.query.sort = [];
      }
      this.selected = this.lastSelected?.sorter || null;
    }
    this.query.search = term;
    this.queryChange.emit(this.query);
  }

  public ngOnInit(): void {
    if (this.query.sort && this.query.sort.length > 0) {
      this.selected = this.query.sort[0].sorter;
    }

    this.hasFilters = !!(this.query.aroma?.length || this.query.color?.length || this.query.other?.length || this.query.tags?.length);

    // track lang and remove tag filter if no tags available
    this.showOrHideTagFilterPage();
    this.subscribe(this.translateService.onLangChange, () => {
      this.showOrHideTagFilterPage();
    });
  }

  public onShowFilter(): void {
    this.queryBackup = this.beerQueryService.create(this.query);
    this.initiallySelectedColor = new Set<Selector>(this.query.color || []);
    this.initiallySelectedAroma = new Set<Selector>(this.query.aroma || []);
    this.initiallySelectedOther = new Set<Selector>(this.query.other || []);
    this.showOverlay = true;
  }
  public onHideOverlay(accept: boolean): void {
    if (!accept) {
      this.query = this.queryBackup;
      this.queryChange.emit(this.query);
    }
    this.showOverlay = false;
    this.hasFilters = !!(this.query.aroma?.length || this.query.color?.length || this.query.other?.length || this.query.tags?.length);
  }

  public onRemoveAllFilters(): void {
    this.query.aroma = [];
    this.query.color = [];
    this.query.other = [];
    this.query.tags = [];
    this.queryChange.emit(this.query);
    this.hasFilters = false;
    this.showOverlay = false;
  }

  public onBackClick(): void {
    this.goBackClick.emit();
  }

  public onSwipeLeft(): void {
    if (this.filterPageIndex < this.filterPages.length - 1) {
      this.filterPages[this.filterPageIndex].location = 'left';
      this.filterPages[this.filterPageIndex + 1].location = 'center';
      this.filterPageIndex++;
    }
  }

  public onSwipeRight(): void {
    if (this.filterPageIndex > 0) {
      this.filterPages[this.filterPageIndex].location = 'right';
      this.filterPages[this.filterPageIndex - 1].location = 'center';
      this.filterPageIndex--;
    }
  }

  public setFilterPageIndex(index: number): void {
    index = Math.max(Math.min(index, this.filterPages.length - 1), 0);
    for (let i = 0; i < this.filterPages.length; i++) {
      if (index > i) {
        this.filterPages[i].location = 'left';
      } else if (index === i) {
        this.filterPages[i].location = 'center';
      } else {
        this.filterPages[i].location = 'right';
      }
    }
    this.filterPageIndex = index;
  }

  private showOrHideTagFilterPage(): void {
    if (this.venue.hasBeerTagsForLanguage(this.translateService.currentLang)) {
      if (!this.filterPages.includes(this.tagFilterPage)) {
        this.filterPages.push(this.tagFilterPage);
      }
    } else {
      if (this.filterPages.includes(this.tagFilterPage)) {
        const index = this.filterPages.indexOf(this.tagFilterPage);
        if (this.filterPageIndex === index) {
          this.setFilterPageIndex(index - 1);
        }
        this.filterPages.splice(this.filterPages.indexOf(this.tagFilterPage), 1);
      }
    }
  }
}
