import { Venue } from '../../models/persistency/persistent-models/venue';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger/logger.service';
import { ModelForm } from '../../models/persistency/model-form';
import internationalization from '../../models/defaults/internationalization.json';
import { CheckboxChangeEventDetail } from '@ionic/core/dist/types/components/checkbox/checkbox-interface';
import { RadioGroupChangeEventDetail } from '@ionic/core/dist/types/components/radio-group/radio-group-interface';

@Component({
  selector: 'app-admin-settings-lang',
  templateUrl: './admin-settings-lang.component.html',
  styleUrls: ['./admin-settings-lang.component.scss'],
})

export class AdminSettingsLangComponent implements OnInit {
  @Input()
  public venue!: Venue;

  @Output()
  public close = new EventEmitter<void>();

  private venueForm!: ModelForm<Venue>;
  public formVenue!: Venue;

  public languageOptions: string[];
  public availableLanguages: ReadonlySet<string> = new Set();
  public defaultLanguage = '';

  public constructor(
    public translateService: TranslateService,
    private logger: LoggerService
  ) {
    this.logger.info('admin-settings-lang.component', 'constructor called');
    this.languageOptions = internationalization.availableLanguages;
  }

  public ngOnInit(): void {
    this.venueForm = this.venue.createForm();
    this.formVenue = this.venueForm.formInstance;
    this.logger.info('admin-settings-lang.component', 'ngOnInit called');

    this.initFormValues();
  }

  public languageChange(language: string, event: CustomEvent<CheckboxChangeEventDetail>): void {
    if (event.detail.checked) {
      this.formVenue.addLanguage(language);
    } else {
      this.formVenue.removeLanguage(language);
    }
  }

  public defaultLanguageChange(event: CustomEvent<RadioGroupChangeEventDetail>): void {
    this.formVenue.setDefaultLanguage(event.detail.value);
    this.defaultLanguage = event.detail.value;
  }

  public async onSave(): Promise<void> {
    this.logger.info('admin-settings-lang.component', 'onSave called');

    this.venueForm.applyForm();
    await this.venue.save().then(() => {
      this.closeComponent();
    });
  }

  public onCancel(): void {
    this.closeComponent();
  }
  public closeComponent(): void {
    this.close.emit();
  }

  private initFormValues() {
    this.availableLanguages = this.formVenue.getLanguages();
    this.defaultLanguage = this.formVenue.getDefaultLanguage();
  }
}
