/**
 * A utility class for comparing, filtering and sorting based on normalized strings.
 *
 * @Suggestion Convert this static class to a service.
 */
export class SloppyString {
    /**
     * Normalize a string.
     *
     * @param input  The string to normalize.
     * @returns The normalized string.
     */
    public static normalize(input: string): string {
        return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
    }

    /**
     * Check whether a string is included in another one, after normalizing both strings.
     *
     * @param searchTerm  The term to search for.
     * @param content  The string to search in.
     * @returns Whether the normalized search term is included in the normalized content.
     */
    public static includes(searchTerm: string, content: string): boolean {
        return this.normalize(content).includes(this.normalize(searchTerm));
    }
    /**
     * Check whether a string starts with another one, after normalizing both strings.
     *
     * @param searchTerm  The term to search for.
     * @param content  The string to search in.
     * @returns Whether the normalized content starts with the normalized search term.
     */
    public static startsWith(searchTerm: string, content: string): boolean {
        return this.normalize(content).startsWith(this.normalize(searchTerm));
    }
    /**
     * Check whether a string equals another one, after normalizing both strings.
     *
     * @param searchTerm  The first term.
     * @param content  The second term.
     * @returns Whether the normalized search term equals the normalized content.
     */
    public static equals(searchTerm: string, content: string): boolean {
        return this.normalize(content) === this.normalize(searchTerm);
    }
    /**
     * Filter a list of objects by excluding objects which label does not sloppy-include a given search term.
     *
     * @param searchTerm  The term to filter on.
     * @param contents  A list of objects to filter.
     * @param getLabel  A function mapping objects to their label.
     * @returns The list of objects excluding objects which label does not sloppy-include a given search term.
     */
    public static filter<T>(searchTerm: string, contents: T[], getLabel: (v: T) => string): T[] {
        return contents.filter((v) => this.includes(searchTerm, getLabel(v)));
    }
    /**
     * Filter a list of objects by excluding objects which labels does not sloppy-include a given search term.
     *
     * @param searchTerm  The term to filter on.
     * @param contents  A list of objects to filter.
     * @param getLabels  A function mapping objects to their labels.
     * @returns The list of objects excluding objects which do not have any label that sloppy-includes a given search term.
     */
    public static filterWithMultilabels<T>(searchTerm: string, contents: T[], getLabels: (v: T) => string[]): T[] {
        return contents.filter((v) => getLabels(v).some((label) => this.includes(searchTerm, label)));
    }
    /**
     * Filter and sort a list of objects by excluding objects which label does not sloppy-include a given search term
     * and sorting the remaining objects based on how well the search term matched, optionally controlled with an
     * additional priority for each object.
     *
     * @param searchTerm  The term to filter and sort on.
     * @param contents  A list of objects to filter and sort.
     * @param getLabel  A function mapping objects to their label.
     * @param getPriority  An additional sorting priority that matching objects may get. This priority is 0 by default.
     * When the priority of object A is 1 greater than the priority of object B, object A will certainly be ordered before
     * object B, independent of how well object B matched. When it is between 0 and 1, object B can still be ordered higher
     * than object A, depending on how well the search term matched both labels.
     * @returns The list of objects excluding objects which do not have any label that sloppy-includes a given search term
     * and sorted on how well they matched.
     */
    public static filterAndSort<T>(searchTerm: string, contents: T[], getLabel: (v: T) => string,
        getPriority: (match: T) => number = () => 0): T[] {
        return contents.map((v) => {
            let level = 0;
            const label = getLabel(v);
            if (searchTerm === label) {
                level = 1.0 + getPriority(v);
            } else if (this.equals(searchTerm, label)) {
                level = 0.8 + getPriority(v);
            } else if (label.startsWith(searchTerm)) {
                level = 0.6 + getPriority(v);
            } else if (this.startsWith(searchTerm, label)) {
                level = 0.4 + getPriority(v);
            } else if (label.includes(searchTerm)) {
                level = 0.2 + getPriority(v);
            } else if (this.includes(searchTerm, label)) {
                level = 0.1 + getPriority(v);
            }
            return {level: level, content: v};
        }).filter((v) => v.level !== 0)
            .sort((a, b) => b.level - a.level)
            .map((v) => v.content);
    }
    /**
     * A multilabel version of {@link filterAndSort}. The priority of an object equals the maximum of the priorities
     * determined by their labels.
     *
     * @param searchTerm  The term to filter and sort on.
     * @param contents  A list of objects to filter and sort.
     * @param getLabels  A function mapping objects to their labels.
     * @param getPriority  An additional sorting priority that matching objects may get. This priority is 0 by default.
     * When the priority of object A is 1 greater than the priority of object B, object A will certainly be ordered before
     * object B, independent of how well object B matched. When it is between 0 and 1, object B can still be ordered higher
     * than object A, depending on how well the search term matched both labels.
     * @returns The list of objects excluding objects which do not have any label that sloppy-includes a given search term.
     */
    public static filterAndSortWithMultilabels<T>(searchTerm: string, contents: T[],
        getLabels: (v: T) => string[], getPriority: (match: T, matchedIndex: number, labels: string[]) => number = () => 0): T[] {
        return contents.map((v) => {
            const levels = [];
            const labels = getLabels(v);
            let i: number;
            if ((i = labels.findIndex((label) => label === searchTerm)) > -1) {
                levels.push(1.0 + getPriority(v, i, labels));
            }
            if ((i = labels.findIndex((label) => this.equals(searchTerm, label))) > -1) {
                levels.push(0.8 + getPriority(v, i, labels));
            }
            if ((i = labels.findIndex((label) => label.startsWith(searchTerm))) > -1) {
                levels.push(0.6 + getPriority(v, i, labels));
            }
            if ((i = labels.findIndex((label) => this.startsWith(searchTerm, label))) > -1) {
                levels.push(0.4 + getPriority(v, i, labels));
            }
            if ((i = labels.findIndex((label) => label.includes(searchTerm))) > -1) {
                levels.push(0.2 + getPriority(v, i, labels));
            }
            if ((i = labels.findIndex((label) => this.includes(searchTerm, label))) > -1) {
                levels.push(0.1 + getPriority(v, i, labels));
            }
            const level = levels.reduce((l1, l2) => Math.max(l1, l2), 0);
            return {level: level, content: v};
        }).filter((v) => v.level !== 0)
            .sort((a, b) => b.level - a.level)
            .map((v) => v.content);
    }
}
