import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScreenVersionListener } from '../../models/screen-version-listener';

export interface Option<T> {
  selectable: boolean;
  translationKey: string;
  object: T;
}

@Component({
  selector: 'app-popover-select',
  templateUrl: './popover-select.component.html',
  styleUrls: ['./popover-select.component.scss']
})
export class PopoverSelectComponent<T> extends ScreenVersionListener implements OnInit, OnChanges {
  @Input()
  public options!: Option<T>[];

  @Input()
  public selected: T | T[] | null = null;

  @Input()
  public multiSelect = false;

  @Input()
  public sortAccordingToTranslation = false;

  @Input()
  public showSelector = false;

  @Output()
  public selectionChange = new EventEmitter<T | null>();

  @Output()
  public multiSelectionChange = new EventEmitter<T[]>();

  private multiSelected: Option<T>[] = [];
  private singleSelected: Option<T> | null = null;

  public cancelCloseDropdown = false;

  public constructor(
    public translateService: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    // sort according to translation if necessary
    if (this.sortAccordingToTranslation) {
      this.sortOptions();
      this.subscribe(this.translateService.onLangChange, () => {
        this.sortOptions();
      });
    }

    this.updateSelected();
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected && !changes.selected.isFirstChange()) {
      this.updateSelected();
    }
  }

  private updateSelected() {
    // if multiselect, deselect is true
    if (this.multiSelect) {
      if (this.selected) {
        if (Array.isArray(this.selected)) {
          this.multiSelected = this.options.filter((o) => (this.selected as T[]).includes(o.object));
        } else {
          this.multiSelected = this.options.filter((o) => this.selected === o.object);
        }
      } else {
        this.multiSelected = [];
      }
    } else {
      if (this.singleSelected) {
        this.singleSelected.selectable = true;
      }
      if (this.selected) {
        if (Array.isArray(this.selected)) {
          throw new Error('Cannot have multiple selected options when multiSelect is false!');
        }
        this.singleSelected = this.options.find((o) => o.object === this.selected) || null;
        if (this.singleSelected) {
          this.singleSelected.selectable = false;
        }
      } else {
        this.singleSelected = null;
      }
    }
  }

  public onShowDropdown(): void {
    this.showSelector = true;

    // timeout to make sure the onclick is not activated because of the onShowDropdown click.
    setTimeout(() => {
      document.onclick = () => {
        if (!this.cancelCloseDropdown) {
          this.showSelector = false;
          document.onclick = () => {};
        } else {
          this.cancelCloseDropdown = false;
        }
      };
    }, 100);
  }

  public onSelect(index: number): void {
    const selectedOption = this.options[index];
    // cannot deselect
    if (this.isSelected(selectedOption)) {
      return;
    }

    // don't do anything when the option is not selectable, except when the option is already
    // selected (default not selectable then), then deselect the option
    if (
      selectedOption.selectable ||
      this.isSelected(selectedOption)
    ) {
      if (!this.multiSelect) {
        if (!this.isSelected(selectedOption)) {
          // handle single select
          if (this.singleSelected && this.singleSelected.translationKey) {
            this.singleSelected.selectable = true;
          }
          this.singleSelected = selectedOption;
          this.singleSelected.selectable = false;
          this.showSelector = false;
          this.selectionChange.emit(this.singleSelected.object);
        } else {
          // deselect
          this.singleSelected = null;
          this.selectionChange.emit(null);
        }
      } else if (this.multiSelect) {
        this.cancelCloseDropdown = true;
        // handle multiSelect

        // find the index in the selected options
        const indexInSelected = this.multiSelected.indexOf(selectedOption);
        if (indexInSelected > -1) {
          this.multiSelected.splice(indexInSelected, 1); // splice removes 1 element from the array in this call
        } else {
          // add in order of options
          let added = false;
          for (let i = 0; i < this.multiSelected.length; i++) {
            if (this.options.indexOf(this.multiSelected[i]) > index) {
              this.multiSelected.splice(i, 0, selectedOption);
              added = true;
              break;
            }
          }
          if (!added) {
            this.multiSelected.push(selectedOption);
          }
        }
        this.multiSelectionChange.emit(this.multiSelected.map((s) => s.object));
      }
    }
  }

  public getOptionClasses(option: Option<T>): string {
    const result = 'option';

    // option selected
    if (this.isSelected(option)) {
      return result + ' selected-option';
    }

    // option not selectable
    if (!option.selectable) {
      return result + ' not-selectable';
    }

    return result + ' selectable';
  }

  public getSelectedText(): string {
    if (this.multiSelect) {
      if (this.multiSelected.length === 0) {
        return ' - ';
      }
      let result = '';
      for (const selection of this.multiSelected) {
        if (selection.translationKey) {
          result += this.translateService.instant(selection.translationKey) + ', ';
        }
      }
      return result.slice(0, -2);
    } else {
      if (this.singleSelected === null || !this.singleSelected.translationKey) {
        return ' - ';
      }
      return this.translateService.instant(this.singleSelected.translationKey);
    }
  }

  private isSelected(option: Option<T>): boolean {
    if (!this.multiSelect) {
      return option === this.singleSelected;
    } else {
      return this.multiSelected.includes(option);
    }
  }

  private sortOptions(): void {
    this.options.sort((a, b) => {
      const translateA = a.translationKey && this.translateService.instant(a.translationKey);
      const translateB = b.translationKey && this.translateService.instant(b.translationKey);
      if (!translateA && !translateB) {
        return 0;
      }
      if (!translateA) {
        return -1;
      }
      if (!translateB) {
        return 1;
      }
      return translateA.localeCompare(translateB);
    });
  }
}
