import { VenueService } from './../../services/venue/venue.service';
import { Component, ViewChild, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { BeerService } from '../../services/beer/beer.service';
import { TranslateService } from '@ngx-translate/core';
import { Unit } from '../../models/unit';
import { Beer } from '../../models/persistency/persistent-models/beer';
import { Order } from '../../models/order';
import { ScreenVersionListener } from '../../models/screen-version-listener';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { VenueBeer } from '../../models/persistency/persistent-models/venue-beer';
import { notNull } from '../../models/utilities';

@Component({
  selector: 'app-beer-results',
  templateUrl: './beer-results.component.html',
  styleUrls: ['./beer-results.component.scss'],
})
export class BeerResultsComponent extends ScreenVersionListener implements OnInit, OnChanges {
  @ViewChild(IonInfiniteScroll)
  private infiniteScroll?: IonInfiniteScroll;

  @Input()
  public beers: Beer[] = [];

  @Input()
  public isOrder = false;

  @Output()
  public cardClick = new EventEmitter<Beer>();

  @Output()
  public orderChange = new EventEmitter<Order>();

  public loadedBeers: Beer[] = [];
  public loadedVenueBeers: VenueBeer[] = [];
  public nrOfBeersToAdd = 10;

  public currency: Unit;

  public venue: Venue;

  public constructor(
    private venueService: VenueService,
    public translateService: TranslateService,
  ) {
    super();
    this.venue = this.venueService.getSelectedVenue(true);
    this.currency = this.venue.getCurrency();
  }

  public ngOnInit(): void {
    this.initBeers();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.beers && !changes.beers.firstChange) {
      this.initBeers();
    }
  }

  public onCardClick(beer: Beer): void {
    this.cardClick.emit(beer);
  }

  public onOrderChange(order: Order): void {
    this.orderChange.emit(order);
  }

  public loadBeers(event: CustomEvent<void>): void {
    this.loadedBeers = this.beers.slice(0, this.loadedBeers.length + this.nrOfBeersToAdd);
    if (this.isOrder) {
      this.loadedVenueBeers = this.loadedBeers.map((b) => b.getVenueBeer(this.venue)).filter(notNull);
    }

    if (this.infiniteScroll) {
      this.infiniteScroll.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.loadedBeers.length === this.beers.length) {
        this.infiniteScroll.disabled = true;
      }
    }
  }

  private initBeers() {
    this.loadedBeers = this.beers.slice(0, this.nrOfBeersToAdd);
    if (this.isOrder) {
      this.loadedVenueBeers = this.loadedBeers.map((b) => b.getVenueBeer(this.venue)).filter(notNull);
    }

    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = false;
      if (this.loadedBeers.length === this.beers.length) {
        this.infiniteScroll.disabled = true;
      }
    }
  }
}
