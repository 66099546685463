import { OtherSelectors } from './../../models/selector';
import { LoginGuard } from './../../guards/login/login.guard';
import { BeerService } from './../beer/beer.service';
import { Cleaner } from './../../models/cleaner';
import { LocalPersisterService } from './../local-persister/local-persister.service';
import { ColorService } from './../color/color.service';
import { TranslateService } from '@ngx-translate/core';
import { Venue } from '../../models/persistency/persistent-models/venue';
import { RefdataService } from './../refdata/refdata.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { modelUrls as urls } from '../../model-urls';
import { BehaviorSubject } from 'rxjs';
import { VenueTable } from '../../models/persistency/persistent-models/venue-table';

/**
 * A {@link ModelTable} for {@link Venue}s that keeps track of a selected venue.
 */
@Injectable({
  providedIn: 'root'
})
export class VenueService extends VenueTable {
  /**
   * A behavior subject which can be subscribed to to keep track of the selected venue.
   */
  public readonly $selectedVenue = new BehaviorSubject<Venue | null>(null);
  private venue: Venue | null = null;
  public constructor(
    http: HttpClient,
    refdata: RefdataService,
    loginGuard: LoginGuard,
    translateService: TranslateService,
    private colorService: ColorService,
    private localPersister: LocalPersisterService,
    beerService: BeerService,
    otherSelectors: OtherSelectors
  ) {
    super(http, refdata, loginGuard, translateService, beerService, otherSelectors);
    this.selectVenue(null);
  }

  /**
   * Get the selected venue, or `null` if none is selected.
   */
  public getSelectedVenue(assertNotNull?: false): Venue | null;
  /**
   * Get the selected venue.
   *
   * @throws The current selected venue is null!
   */
  public getSelectedVenue(assertNotNull: true): Venue;
  public getSelectedVenue(assertNotNull?: boolean): Venue | null {
    if (assertNotNull && this.venue === null) {
      throw new Error('The current selected venue is null!');
    }
    return this.venue;
  }
  /**
   * Select the given venue and initialize the favicon, the document title, and the primary and secondary colors.
   *
   * @param venue  The venue to select, or `null` to deselect the current selected venue.
   */
  public selectVenue(venue: Venue | null): void {
    this.venue = venue;
    let icon = 'assets/icon/beerhive.png';
    if (venue !== null) {
      this.colorService.setPrimaryColor(venue.getPrimaryColor());
      this.colorService.setSecondaryColor(venue.getSecondaryColor());

      document.title = venue.getName();

      icon = venue.getLogoUrl() || icon;

      this.localPersister.set('last-selected-venue-id', venue.getId());
    } else {
      this.colorService.resetColors();

      document.title = 'Beerhive';
    }

    const link: any =
          document.querySelector('link[rel*=\'icon\']') ||
          document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = icon;
    document.getElementsByTagName('head')[0].appendChild(link);

    this.$selectedVenue.next(venue);
  }
  /**
   * Save the currently selected venue.
   */
  public saveSelectedVenue(): Promise<void> {
    return this.getSelectedVenue(true).save();
  }


  /**
   * @inheritDoc
   */
  public saveInstance(venue: Venue): Promise<void> {
    const userId = this.loginGuard.getUserToken();
    if (!venue.getOwnerUserId() && userId) {
      venue.setOwnerUserId(userId);
    }
    const serialized = this.serializeInstance(venue);
    serialized.userid = userId;
    serialized.archived = 0;

    const options = { headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })};
    let storageUrl = environment[urls.venue.base];
    if (venue.getId() !== null) {
      storageUrl += urls.venue.UPDATE;
    } else {
      storageUrl += urls.venue.CREATE;
    }
    console.log('Saving venue to ' + storageUrl);
    console.log(serialized);
    return this.http.post<any>(storageUrl, serialized, options).toPromise().then((response) => {
      if (!response || (typeof response !== 'number' && typeof response !== 'string')) {
        if (!response || !('id' in response)) {
          console.log(response);
          throw new Error('Expected a response while saving (' + response + ')');
        }
        this.applyRawData(response, venue);
      } else {
        venue.setId(Cleaner.parseId(response));
      }
      return super.saveInstance(venue);
    }).catch((errorResponse) => {
      console.log(errorResponse);
      const validationErrors: any[] = [];
      if (errorResponse.status && errorResponse.status === 400) {
        if (errorResponse.error && errorResponse.error.length > 0) {
          errorResponse.error.forEach((element: any) => {
            if (typeof element.key === 'string' && Array.isArray(element.messages) && element.messages.length > 0) {
              const message = element.messages[0];
              if (typeof message === 'string') {
                const validationError: {[key: string]: any} = {};
                const lastDotIndex = element.key.lastIndexOf('.');
                const key = (lastDotIndex && lastDotIndex >= 0 ? element.key.substring(lastDotIndex + 1) : element.key);
                validationError['formControlName'] = key;
                const errors: {[key: string]: any} = {};
                errors[message] = {};
                validationError.errors = errors;
                validationErrors.push(validationError);
              }
            }
          });
        }
      } else {
        validationErrors.push({ key: 'general', message: 'An unexpected error occurred.'});
      }
      throw validationErrors;
    });
  }
  /**
   * @inheritDoc
   */
  public deleteInstance(venue: Venue): Promise<void> {
    if (this.getSelectedVenue() === venue) {
      this.selectVenue(null);
    }
    const id = venue.getId();
    if (id === null) {
      return super.deleteInstance(venue);
    }

    const removeVenueRequest = {
      id: id,
      userId: this.loginGuard.getUserToken()
    };
    const removeVenueUrl = environment[urls.venue.base] + urls.venue.DELETE;

    const options = { headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })};
    return this.http.post(removeVenueUrl, removeVenueRequest, options).toPromise()
      .then(() => super.deleteInstance(venue));
  }


  protected getRawData(): Promise<any[]> {
    const token = this.loginGuard.getUserToken();
    if (!token) {
      throw new Error('Cannot load admin venue data when not logged in!');
    }
    return this.http.post<any>(this.refdata.getAdminVenueUrl(token), {},
    {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      withCredentials: true
    }).toPromise().then((data) => data.rows);
  }
  /**
   * Load the venue with the given url.
   *
   * @param venueUrl  The {@link Venue.getUrlPath | url path} of the venue to load.
   */
  public loadVenue(venueUrl: string): Promise<Venue> {
    return this.http.post<any>(this.refdata.getVenueUrlMappingUrl(), {urlName: venueUrl},
     {
       'headers': new HttpHeaders({'Content-Type': 'application/json'})
    }).toPromise().then((data) => {
      console.log('Loading venue');
      console.log(data);
      const venue = this.readInstance(data);
      // check if already there and remove old instance if found
      const existingVenueId: any = venue.getId();
      if (existingVenueId !== null) {
        const existingInstance = this.find((venue) => existingVenueId == venue.getId());
        if (existingInstance !== null) {
          this.remove(existingInstance);
        }
      }
      this.add(venue);
      this.selectVenue(venue);
      console.log(this);
      return venue;
    });
  }
}
