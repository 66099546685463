<!-- Not available text -->
<div
  *ngIf="!venueBeer && showEssentialsOnly !== 'admin'"
  class="not-available-wrapper beerinfo"
>
  <ion-row class="beer-name-wrapper">
    <div
      [class]="'beer-name-' + screenType + ' bold show-overflow-dots'"
      (click)="presentToast(name)"
    >
      {{ name }}
    </div>
  </ion-row>
  <ion-row class="notavailable" *ngIf="hasSimilarBeers">
    <div>
      <ion-icon name="information-circle-outline" class="info-icon"></ion-icon>
      <span>
        {{ translateService.instant("CARD.NOT_AVAILABLE_BUT_SIMILAR") }}
      </span>
    </div>
  </ion-row>
  <ion-row class="notavailable lower-opacity" *ngIf="!hasSimilarBeers">
    <div>
      <ion-icon name="information-circle-outline" class="info-icon"></ion-icon>
      <span>
        {{ translateService.instant("CARD.NOT_AVAILABLE") }}
      </span>
    </div>
  </ion-row>
</div>

<ion-grid
  class="beerinfo"
  *ngIf="venueBeer || showEssentialsOnly === 'admin'"
>
  <!-- logo, brewerylogo or no-logo -->
  <ion-row>
    <ion-col size="3" class="logo-column" class="ion-no-padding">
      <ion-row class="logo-row" class="ion-no-padding">
        <img
          [src]="logo"
          alt=""
          [class]="'beerlogo-' + screenType"
        />
      </ion-row>
    </ion-col>
    <ion-col size="9" class="beerinfo-col">
      <!-- Beer name -->
      <ion-row class="beer-name-wrapper" class="ion-no-padding">
        <!-- Wishlist heart -->
        <!--app-wish-list></app-wish-list -->
        <div
          [class]="'beer-name-' + screenType + ' bold show-overflow-dots'"
          (click)="presentToast(name)"
        >
          {{ name }}
        </div>
      </ion-row>

      <!-- brewery + abv -->
      <ion-row class="abv-breweryname" class="ion-no-padding">
        <div [class]="'abv-' + screenType">
          {{ ABV !== null ? ABVStr + "%" : "" }}
        </div>
        <div *ngIf="breweryName"
          (click)="presentToast(breweryName!)"
          [class]="'brewery-name-' + screenType + ' show-overflow-dots leftmargin'"
        >
          {{ breweryName }}
        </div>
      </ion-row>

      <!-- brewery city -->
      <ion-row *ngIf="!showEssentialsOnly || showEssentialsOnly === 'admin'">
        <div class="brewery-city-wrapper">
          <div *ngIf="breweryCity"
            class="brewery-city show-overflow-dots"
            (click)="presentToast(breweryCity!)"
          >
            {{ breweryCity }}
          </div>
          <div class="country-flag-wrapper" *ngIf="breweryCountry">
            <img
              [src]="breweryCountryLogo"
              class="country-flag"
            />
          </div>
        </div>
      </ion-row>

      <!-- Bullshit only for widescreen -->
      <ion-row
        class="bullshit-row"
        *ngIf="
          !isMobile() &&
          (!showEssentialsOnly || showEssentialsOnly === 'description')
        "
      >
        <p class="bullshit-text">
          <span>
            {{ description }}
          </span>
        </p>
      </ion-row>
      <!-- <ion-row class="white-space"></ion-row> -->
    </ion-col>
  </ion-row>

  <!-- Bullshit -->
  <ion-row
    class="bullshit-row"
    *ngIf="
      isMobile() &&
      (!showEssentialsOnly || showEssentialsOnly === 'description')
    "
  >
    <p class="bullshit-text bullshit-new-line">
      <span>
        {{ description }}
      </span>
    </p>
  </ion-row>

  <!-- tags and icons -->
  <ion-row
    *ngIf="!showEssentialsOnly"
    [ngClass]="isMobile() ? 'extra-row' : ''"
  >
    <ion-col size="3" class="ion-no-padding" *ngIf="!isMobile()"> </ion-col>
    <ion-col
      [size]="!isMobile() ? '9' : '12'"
      class="ion-no-padding"
      [ngClass]="!isMobile() ? 'tag-col' : ''"
    >
      <div
        [ngStyle]="{
          width: tagOffset
        }"
      >
        <div class="tag foodtag" *ngFor="let tag of foodTags">{{ tag }}</div>
        <div class="tag othertag" *ngFor="let tag of otherTags">{{ tag }}</div>
      </div>

      <div class="in-text-icons">
        <ion-icon
          src="assets/svgs/general/icon_local.svg"
          class="in-text-icon"
          (click)="presentToast('local', true)"
          *ngIf="isLocal"
        ></ion-icon>
        <ion-icon
          src="assets/svgs/general/icon_temporary.svg"
          class="in-text-icon"
          (click)="presentToast('temporary', true)"
          *ngIf="isTemporary"
        ></ion-icon>
        <ion-icon
          src="assets/svgs/general/icon_new.svg"
          class="in-text-icon"
          (click)="presentToast('new', true)"
          *ngIf="isNew"
        ></ion-icon>
        <ion-icon
          src="assets/svgs/general/icon_promo.svg"
          class="in-text-icon"
          (click)="presentToast('promo', true)"
          *ngIf="isPromo"
        ></ion-icon>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
